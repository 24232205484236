// =============================================================================
// Components > Tutor Sidebar
// =============================================================================

import { connect } from 'react-redux'
import React, { FunctionComponent } from 'react'

import SidebarButton from '../elements/SidebarButton'
import inCallSelector from '../selectors/inCall'
import config from '../../config'
import Actions from '../actions'

type TutorSidebarProps = {
	linkedAccess?: boolean
	tutorSignOut?: () => void
	viewingPreviousSession?: boolean
	returnToCurrentSession?: () => void
	messagesUnread?: boolean
	inCall?: boolean
	location?: string
	navigateTo?: (string) => void
}

const TutorSidebar: FunctionComponent<TutorSidebarProps> = props => {
	const { linkedAccess, tutorSignOut, viewingPreviousSession, returnToCurrentSession, inCall } = props

	function _renderButton(name: string, iconName: string, onClick = null) {
		const { messagesUnread, location, navigateTo } = props
		const _onClick = onClick || (() => navigateTo(`tutor-${name}`))
		const label = config.strings[name.toUpperCase()]
		const selected = location && location.indexOf(`-${name}`) > 0
		const showBadge = (name === 'messages' && messagesUnread) || (name === 'videoconf' && inCall)

		return (
			<SidebarButton
				key={name}
				name={name}
				label={label}
				onClick={_onClick}
				iconName={iconName}
				selected={selected}
				showBadge={showBadge}
			/>
		)
	}

	const topButtons = [
		!linkedAccess && _renderButton('sessions', 'clipboard-list'),
		_renderButton('messages', 'comments-alt'),
		_renderButton('groups', 'users'),
		_renderButton('media', 'images'),
		_renderButton('passcodes', 'key'),
		!linkedAccess && _renderButton('linkedaccesspasswords', 'user-plus'),
		_renderButton('participants', 'user'),
		_renderButton('videoconf', 'video'),
		viewingPreviousSession && _renderButton('RETURN_TO_CURRENT', 'arrow-circle-left', returnToCurrentSession),
	]

	const bottomButtons = [
		!linkedAccess && _renderButton('settings', 'cog'),
		_renderButton('prevsessions', 'history'),
		!linkedAccess && _renderButton('cases', 'briefcase'),
		_renderButton('signout', 'power-off', tutorSignOut),
	]

	return (
		<div className="tutor-sidebar">
			<div className="tutor-sidebar__top"> {topButtons} </div>
			<div className="tutor-sidebar__bottom"> {bottomButtons} </div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<TutorSidebarProps> => ({
	location: state.navigatingTo,
	messagesUnread: state.messagesUnread,
	linkedAccess: state.linkedAccess && state.linkedAccess.connected,
	viewingPreviousSession: state.viewingPreviousSession,
	inCall: inCallSelector(state),
})
const actions = {
	tutorSignOut: Actions.login.signOut,
	navigateTo: Actions.misc.navigateTo,
	returnToCurrentSession: Actions.sessions.returnToCurrentSession,
}
export default connect(mapStateToProps, actions)(TutorSidebar)
