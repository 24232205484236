import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import config from '../../config'

type SubscriptionNoticeProps = {
	licence: Licence
	sessions: Session[]
}

const SubscriptionNotice: FunctionComponent<SubscriptionNoticeProps> = props => {
	const { licence, sessions } = props
	const { SUBSCRIPTION_NOTICE } = config.strings

	const remaining = licence.remaining_sessions_per_year
	const expiryDate = new Date(licence.end_date || '').toLocaleDateString()

	if (!(sessions.length && remaining >= 0)) return null

	return (
		<div className="info">
			{SUBSCRIPTION_NOTICE[0]}
			<strong>{remaining}</strong>
			{SUBSCRIPTION_NOTICE[1]}
			<strong>{expiryDate}</strong> <br />
			{SUBSCRIPTION_NOTICE[2]}
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<SubscriptionNoticeProps> => ({
	sessions: state.openCase ? state.openCase.sessions || [] : [],
	licence: state.licence || ({} as Licence),
})
const actions = {}
export default connect(mapStateToProps, actions)(SubscriptionNotice)
