/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { FunctionComponent, ReactElement, useState } from 'react'
import { connect } from 'react-redux'
import { useMount } from 'react-use'

import config from '../../config'
import Actions from '../actions'
import Button from '../elements/Button'
import Preloader from '../elements/Preloader'

const BlankOption: FunctionComponent = ({ children }) => (
	<option value="" disabled>
		{children}
	</option>
)

type ConfigureVideoModalProps = typeof actions & ReturnType<typeof mapStateToProps>

function ConfigureVideoModal(props: ConfigureVideoModalProps): ReactElement<ConfigureVideoModalProps> {
	const { selectMic, selectAudioOut, selectCamera, displayModalPopup } = props
	const { selectedMic, selectedAudioOut, selectedCamera } = props

	const { CAMERA, MICROPHONE, AUDIO_OUTPUT_DEVICE, OK, SAME_AS_SYSTEM } = config.strings

	const [devices, setDevices] = useState<Array<MediaDeviceInfo>>([])
	const [selectedDeviceId, setSelectedDeviceId] = useState(selectedCamera || '')
	const [selectedMicrophoneId, setSelectedMicrophoneId] = useState(selectedMic || '')
	const [selectedAudioOutId, setSelectedAudioOutId] = useState(selectedAudioOut || '')
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(true)

	useMount(async () => {
		const mediaDevices = await navigator.mediaDevices.enumerateDevices()
		const videoDevices = mediaDevices.filter(d => d.kind === 'videoinput')
		if (videoDevices.length === 0) {
			const errorMessage = 'No video devices available. Please ensure you have a camera connected.'
			setError(errorMessage)
		}
		setDevices(mediaDevices)
		setLoading(false)
	})

	const onChangeCam = async (event: React.ChangeEvent<HTMLSelectElement>) => {
		setError('')
		setSelectedDeviceId(event.target.value)
	}

	const onChangeMic = async (event: React.ChangeEvent<HTMLSelectElement>) => {
		setError('')
		setSelectedMicrophoneId(event.target.value)
	}

	const onChangeAudioOut = async (event: React.ChangeEvent<HTMLSelectElement>) => {
		setError('')
		setSelectedAudioOutId(event.target.value)
	}
	const mapMediaDeviceToOption = ({ deviceId, label }: MediaDeviceInfo) => {
		let _label = label

		// Show 'default' devices as 'Same as system default
		if (_label.indexOf('Default - ') === 0) _label = SAME_AS_SYSTEM

		// Remove the weird "(046d:086b)" codes that sometimes appear on the end of device labels
		_label = _label.replace(/\([0-9a-f]{4}:[0-9a-f]{4}\)/, '')

		return (
			<option key={deviceId} value={deviceId}>
				{_label}
			</option>
		)
	}

	console.log(
		'video devices',
		devices.filter(d => d.kind === 'videoinput')
	)
	console.log(
		'audio devices',
		devices.filter(d => d.kind === 'audioinput')
	)
	console.log(
		'output devices',
		devices.filter(d => d.kind === 'audiooutput')
	)

	const deviceOptions = devices.filter(d => d.kind === 'videoinput').map(mapMediaDeviceToOption)
	const micDeviceOptions = devices.filter(d => d.kind === 'audioinput').map(mapMediaDeviceToOption)
	const audioOutOptions = devices.filter(d => d.kind === 'audiooutput').map(mapMediaDeviceToOption)

	// Add 'Select...' as the first option
	deviceOptions.unshift(<BlankOption key=" ">Select camera...</BlankOption>)
	micDeviceOptions.unshift(<BlankOption key=" ">Select microphone...</BlankOption>)
	audioOutOptions.unshift(<BlankOption key=" ">Select audio output device...</BlankOption>)

	const onClickOK = () => {
		selectCamera(selectedDeviceId)
		selectMic(selectedMicrophoneId)
		selectAudioOut(selectedAudioOutId)
		displayModalPopup('')
	}

	if (loading) {
		return (
			<div className="configure-video-modal">
				<div className="configure-video-modal__main" role="dialog">
					<div className="configure-video-modal__content">
						<Preloader />
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className="configure-video-modal">
			<div className="configure-video-modal__main" role="dialog">
				<div className="configure-video-modal__content">
					<div className="input">
						<label htmlFor="configure-video-modal__cam">{CAMERA}</label>
						<select id="configure-video-modal__cam" value={selectedDeviceId} onChange={onChangeCam}>
							{deviceOptions}
						</select>
					</div>
					<div className="input">
						<label htmlFor="configure-video-modal__mic">{MICROPHONE}</label>
						<select id="configure-video-modal__mic" value={selectedMicrophoneId} onChange={onChangeMic}>
							{micDeviceOptions}
						</select>
					</div>
					<div className="input">
						<label htmlFor="configure-video-modal__output">{AUDIO_OUTPUT_DEVICE}</label>
						<select id="configure-video-modal__output" value={selectedAudioOutId} onChange={onChangeAudioOut}>
							{audioOutOptions}
						</select>
					</div>
					{error && <div className="error">{error}</div>}
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
						<Button text={OK} onClick={onClickOK} />
					</div>
				</div>
			</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (
	state: StateTree
): { selectedCamera: string; selectedMic: string; selectedAudioOut: string } => ({
	selectedAudioOut: state.videoconf.selectedAudioOut,
	selectedCamera: state.videoconf.selectedCamera,
	selectedMic: state.videoconf.selectedMic,
})
const actions = {
	displayModalPopup: Actions.misc.displayModalPopup,
	selectCamera: Actions.videoconf.selectCamera,
	selectMic: Actions.videoconf.selectMic,
	selectAudioOut: Actions.videoconf.selectAudioOut,
}
export default connect(mapStateToProps, actions)(ConfigureVideoModal)
