export default {
	/* Team view */
	OK: 'OK',
	CANCEL: 'Отменить',
	TUTOR: 'Координатор',
	CURRENT_SESSION: 'Текущая сессия',
	TEAM: 'Команда',
	SIGNOUT: 'Выход из системы',
	INITIAL_UPDATE: 'Информация о сессии',
	ADVICE: 'Решение',
	RATIONALE: 'Логическое обоснование',
	MEDIA_FEED: 'информация',
	MESSAGES: 'Сообщения',
	PREVSESSIONS: 'Предыдущие сессии',
	RETURN_TO_CURRENT: 'Возврат к текущей сессии',
	SESSION_IS_PAUSED: 'Сессия в настоящее время ПРИОСТАНОВЛЕНА',
	ATTRIB_CURR_AUTHOR: 'Лицо, принимающее решение',
	SAVE: 'Сохранить',
	CHANGE: 'Изменить',
	NO_AUTHOR: 'Не установлено',
	NO_INITIAL_INFO: 'Пожалуйста, дождитесь начала следующей сессии',
	DECISION_MAKER_SELECT: 'Пожалуйста, выберите лицо, ответственное за принятие решений',
	SESSION_COMPLETE: 'Предыдущая сессия завершена',
	OPENED_AT: 'Открыто в',
	SEND_MESSAGE_PLACEHOLDER: 'Написать сообщение',
	SEND: 'Отправить',
	TO_ALL_GROUPS: 'Всем группам',
	SHOW_TRANSLATION: 'Показать перевод',
	SHOW_ORIGINAL: 'Показать исходный текст ',
	CREDENTIALS_EXPIRED: 'Ваш пароль истек. Пожалуйста, пройдите авторизацию заново',
	INCOMING_MESSAGE: 'Входящее сообщение',
	INCOMING_MESSAGE_TEXT: 'Получено новое сообщение',
	INCOMING_MEDIA: 'Входящая информация из медиа источников',
	INCOMING_MEDIA_TEXT: 'Получена новая информация из медиа источников',
	INCOMING_UPDATE: 'Входящие обновления',
	INCOMING_UPDATE_TEXT: 'Получено обновление',
	CONFIRM_LOGOUT: 'Вы уверены, что хотите выйти?',
	MUTED_MESSAGE: 'Ваш микрофон выключен',
	NEWSTYPE_UPDATE: 'Получено обновление',
	WARNING_TIME_MSG: 'Осталось 5 минут',
}
