import { connect } from 'react-redux'
import React, { FunctionComponent } from 'react'

import PreviousSessionMessage from './PreviousSessionMessage'

import { sessionIsActive } from '../util/SessionHelpers'
import config from '../../config'
import Actions from '../actions'

type StatusBarMessageProps = {
	viewingPreviousSession?: boolean
	currentSession: CurrentSession
	active?: boolean
}

const StatusBarMessage: FunctionComponent<StatusBarMessageProps> = props => {
	let message = null
	const { viewingPreviousSession, currentSession, active } = props
	const { paused } = currentSession

	if (viewingPreviousSession) return <PreviousSessionMessage />

	if (!currentSession.id) {
		message = null
	} else if (!active) {
		message = config.strings.SESSION_COMPLETE
	} else if (paused) {
		message = config.strings.SESSION_IS_PAUSED
	}

	return message && <div className="status-bar-message">{message}</div>
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<StatusBarMessageProps> => {
	const groupData = state.groupData || ({} as GroupData)
	const currentSession = state.currentSession || groupData.currentSession || ({} as CurrentSession)
	const active = sessionIsActive(currentSession)
	const { viewingPreviousSession } = state

	return {
		viewingPreviousSession,
		currentSession,
		active,
	}
}
const actions = {
	returnToCurrentSession: Actions.sessions.returnToCurrentSession,
}
export default connect(mapStateToProps, actions)(StatusBarMessage)
