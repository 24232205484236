import config from '../../config'

type GetMediaUrlResponse = { url: string }
type GetSettingsResponse = Settings
type GetLinkedAccessTokenResponse = { token: string }
type GetIpAddressResponse = { ipAddress: string }
type GetMediaItemsResponse = MediaItem[]
type GetServerTimeResponse = { time: number }
type GetParticipantsResponse = Participant[]

export type SendMessageBody = {
	id?: string
	message: string
	tutor?: boolean
	groupId?: string
	observerId?: string
	observerName?: string
	participantId?: string
}
type SendMessageResponse = Message

const {
	SERVER_URL,
	SERVER_URL_AUTH,
	SERVER_URL_CASES,
	SERVER_URL_MEDIA,
	SERVER_URL_SESSIONS,
	SERVER_URL_RELEASES,
	SERVER_URL_MESSAGING,
	SERVER_URL_PASSWORDS,
	SERVER_URL_VIDEOCONF,
	SERVER_URL_PARTICIPANTS,
	SERVER_URL_PREVSESSIONS,
} = config

const { CREDENTIALS_EXPIRED } = config.strings

class OnlineComms {
	private clientId: string

	private gets: Record<string, Promise<SimpleObject | unknown[]>>

	public authToken: string

	constructor() {
		this.updateDecisionLog = this.updateDecisionLog.bind(this)
		this.authToken = sessionStorage.getItem('authtoken')
		this.gets = {}

		// Create/get a unique ID for this client
		this.clientId = localStorage.getItem('clientId')
		if (!this.clientId) {
			this.clientId = String(Math.floor(Math.random() * 1000000000))
			localStorage.setItem('clientId', this.clientId)
		}
	}

	getAuthToken() {
		if (window.state && window.state.linkedAccess && window.state.linkedAccess.token) {
			return window.state.linkedAccess.token
		}
		return this.authToken
	}

	params(method: string, headers = {}): RequestInit {
		return {
			method,
			mode: 'cors' as RequestMode,
			cache: 'default' as RequestCache,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${this.getAuthToken()}`,
				...headers,
			},
		}
	}

	paramsForGET(headers = {}): RequestInit {
		return this.params('GET', headers)
	}

	paramsForPUT(body: SimpleObject): RequestInit {
		return { ...this.params('PUT'), body: JSON.stringify(body) }
	}

	paramsForPOST(body: SimpleObject): RequestInit {
		return { ...this.params('POST'), body: JSON.stringify(body) }
	}

	paramsForDELETE(): RequestInit {
		return { ...this.params('DELETE') }
	}

	getServerUrl(endpoint): string {
		if (endpoint.indexOf('http') === 0) {
			return endpoint
		}
		if (SERVER_URL_AUTH && endpoint.includes('/auth')) {
			return SERVER_URL_AUTH + endpoint
		}
		return SERVER_URL + endpoint
	}

	fetchJSON(url: string, params: RequestInit, endpoint = ''): Promise<SimpleObject | Array<unknown>> {
		const _endpoint = endpoint ? endpoint + url : this.getServerUrl(url)
		let _resp
		return fetch(_endpoint, params)
			.then(resp => {
				_resp = resp
				return resp.json()
			})
			.then(json => {
				if (_resp.status === 403 && !url.includes('/auth/')) {
					// If user is forbidden from calling a particular API due to their credetials,
					// log them out.
					if (sessionStorage.getItem('authtoken')) {
						sessionStorage.setItem('authtoken', '')
						// eslint-disable-next-line no-alert
						alert(CREDENTIALS_EXPIRED)
					}
					window.location.href = window.location.origin
					return
				}
				if (_resp.status > 299) {
					const err = new Error((json && json.message) || 'Sorry, that request failed')
					throw err
				}
				return json
			})
			.catch(err => {
				const msg = err.message && err.message !== 'Failed to fetch' ? err.message : 'Sorry, that request failed'
				const errWithCode: Error & { code?: string; status?: string } = new Error(msg)
				errWithCode.code = (_resp && _resp.status) || 500
				errWithCode.status = errWithCode.code
				throw errWithCode
			})
	}

	GET(url: string, endpoint = null) {
		// Prevent duplicate simultaneous GET requests by keeping a list of active requests
		if (this.gets[url]) {
			return this.gets[url]
		}
		const prom = this.fetchJSON(url, this.paramsForGET(), endpoint)
		this.gets[url] = prom
		// Return an empty object if request failed. Remove request from active GET requests list
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		prom.catch(() => {}).then(() => (this.gets[url] = null))
		return prom
	}

	POST(url: string, body, endpoint = null) {
		return this.fetchJSON(url, this.paramsForPOST(body), endpoint)
	}

	PUT(url: string, body, endpoint = null) {
		return this.fetchJSON(url, this.paramsForPUT(body), endpoint)
	}

	DELETE(url: string, endpoint = null) {
		return this.fetchJSON(url, this.paramsForDELETE(), endpoint)
	}

	async loginAsTutor(facilitatorId: string, password: string) {
		const body = { facilitatorId, password }
		const response = await this.login(body, `/auth/facilitator`)
		return response
	}

	async loginAsGroup(groupId: string, passcode: string, language: string) {
		const body = { passcode, language, groupId }
		const response = await this.login(body, `/auth/group`)
		return response.colour
	}

	async loginAsParticipant(participantId: string, passcode: string, language: string, name: string) {
		const body = { passcode, language, name, participantId }
		const response = await this.login(body, `/auth/participant`)
		return response
	}

	async login(body, endpoint) {
		try {
			const response = await fetch(this.getServerUrl(endpoint), this.paramsForPOST(body))
			const { ok } = response
			if (!ok) throw new Error('Passcode invalid')

			const json = await response.json()
			if (json.token) {
				this.authToken = json.token
				sessionStorage.setItem('authtoken', this.authToken)
			}
			return json
		} catch (err) {
			if (err.message === 'Failed to fetch') {
				throw new Error('Failed to contact the server')
			}
			throw err
		}
	}

	getCaseList() {
		return this.GET('/cases', SERVER_URL_CASES)
	}

	addNewCase(id: string, title: string, description: string, timestamp) {
		return this.PUT('/cases', { id, title, description, timestamp }, SERVER_URL_CASES)
	}

	loadCase(id: string) {
		return this.GET(`/cases/${id}`, SERVER_URL_CASES)
	}

	deleteCase(id: string) {
		return this.DELETE(`/cases/${id}`, SERVER_URL_CASES)
	}

	updateCase(caseId: string, details = {}) {
		return this.POST(`/cases/${caseId}`, details, SERVER_URL_CASES)
	}

	addNewSession(caseId: string, obj) {
		return this.PUT(`/cases/${caseId}/sessions`, obj, SERVER_URL_SESSIONS)
	}

	updateSession(caseId: string, sessionId: string, obj) {
		return this.POST(`/cases/${caseId}/sessions/${sessionId}`, obj, SERVER_URL_SESSIONS)
	}

	deleteSession(caseId: string, sessionId: string) {
		return this.DELETE(`/cases/${caseId}/sessions/${sessionId}`, SERVER_URL_SESSIONS)
	}

	addReleaseRow(caseId: string, sessionId: string, obj) {
		return this.PUT(`/cases/${caseId}/sessions/${sessionId}/rows`, obj, SERVER_URL_SESSIONS)
	}

	removeReleaseRow(caseId: string, sessionId: string, rowId: string) {
		return this.DELETE(`/cases/${caseId}/sessions/${sessionId}/rows/${rowId}`, SERVER_URL_SESSIONS)
	}

	updateReleaseRow(caseId: string, sessionId: string, rowId: string, obj) {
		return this.POST(`/cases/${caseId}/sessions/${sessionId}/rows/${rowId}`, obj, SERVER_URL_SESSIONS)
	}

	startSession(caseId: string, sessionId: string) {
		return this.POST(`/cases/${caseId}/sessions/${sessionId}/start`, {}, SERVER_URL_SESSIONS)
	}

	endSession(caseId: string, sessionId: string) {
		return this.POST(`/cases/${caseId}/sessions/${sessionId}/end`, {}, SERVER_URL_SESSIONS)
	}

	getCurrentSession() {
		return this.GET('/currentsession', SERVER_URL_SESSIONS)
	}

	updateDecisionLog(groupId: string, currSessionId: string, data: SimpleObject) {
		return this.POST(`/group/${groupId}/decisionlog/${currSessionId}`, data, SERVER_URL_SESSIONS)
	}

	getGroupsList() {
		return this.GET('/groups', SERVER_URL_SESSIONS)
	}

	getDecisionLog(groupId: string, sessionId: string) {
		return this.GET(`/group/${groupId}/decisionlog/${sessionId}`, SERVER_URL_SESSIONS)
	}

	getPasswords() {
		return this.GET(`/passwords`, SERVER_URL_PASSWORDS)
	}

	addNewPassword(password: string = null, expiryHours: number = null) {
		return this.PUT(`/passwords`, { password, expiryHours }, SERVER_URL_PASSWORDS)
	}

	deletePassword(key: string) {
		return this.DELETE(`/passwords/${key}`, SERVER_URL_PASSWORDS)
	}

	setOpenCase(caseId: string) {
		return this.POST('/opencase', { caseId }, SERVER_URL_CASES)
	}

	getOpenCase() {
		return this.GET('/opencase', SERVER_URL_CASES)
	}

	addNewMedia(caseId: string, obj) {
		return this.PUT(`/cases/${caseId}/media`, { ...obj, caseId }, SERVER_URL_MEDIA)
	}

	getMediaItems(caseId: string) {
		return this.GET(`/cases/${caseId}/media`, SERVER_URL_MEDIA) as Promise<GetMediaItemsResponse>
	}

	deleteMedia(caseId: string, mediaId: string) {
		return this.DELETE(`/cases/${caseId}/media/${mediaId}`, SERVER_URL_MEDIA)
	}

	updateMedia(caseId: string, mediaId: string, data) {
		return this.POST(`/cases/${caseId}/media/${mediaId}`, data, SERVER_URL_MEDIA)
	}

	getMediaUrl(caseId: string, mediaId: string): Promise<GetMediaUrlResponse> {
		return this.GET(`/cases/${caseId}/media/${mediaId}/url`, SERVER_URL_MEDIA) as Promise<GetMediaUrlResponse>
	}

	getMessages(groupId: string, startFrom: number | string) {
		return this.GET(`/groups/${groupId}/messages/${startFrom}`, SERVER_URL_MESSAGING)
	}

	getAllConversations() {
		return this.GET('/messages', SERVER_URL_MESSAGING)
	}

	sendMessage(groupId: string, message: SendMessageBody): Promise<SendMessageResponse> {
		return this.PUT(`/groups/${groupId}/messages`, message, SERVER_URL_MESSAGING) as Promise<SendMessageResponse>
	}

	getPreviousSessions(caseId: string) {
		return this.GET(`/cases/${caseId}/prevsessions`, SERVER_URL_PREVSESSIONS)
	}

	getPreviousSession(caseId: string, prevSessionId: string) {
		return this.GET(`/cases/${caseId}/prevsessions/${prevSessionId}`, SERVER_URL_PREVSESSIONS)
	}

	getPreviousSessionDocument(caseId: string, prevSessionId: string, translate = false) {
		const headers = {
			Accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		}
		let url = `${SERVER_URL_PREVSESSIONS}/cases/${caseId}/prevsessions/${prevSessionId}/document`
		if (translate) {
			url += '?translate=true'
		}
		return fetch(url, this.paramsForGET(headers)).then(resp => resp.blob())
	}

	deletePreviousSession(caseId: string, prevSessionId: string) {
		return this.DELETE(`/cases/${caseId}/prevsessions/${prevSessionId}`, SERVER_URL_PREVSESSIONS)
	}

	getServerTime(): Promise<GetServerTimeResponse> {
		return this.GET('/time', SERVER_URL_SESSIONS) as Promise<GetServerTimeResponse>
	}

	releaseToGroup(groupId: string, rowId: string) {
		return this.POST('/releaseToGroup', { groupId, rowId }, SERVER_URL_RELEASES)
	}

	releaseToAll(rowId: string) {
		return this.POST('/releaseToAll', { rowId }, SERVER_URL_RELEASES)
	}

	pauseCurrentSession() {
		return this.PUT('/currentsession/pause', {}, SERVER_URL_SESSIONS)
	}

	resumeCurrentSession() {
		return this.DELETE('/currentsession/pause', SERVER_URL_SESSIONS)
	}

	translate(fromLang: string, toLang: string, text: string[]) {
		return this.POST('/translate', { fromLang, toLang, text }, SERVER_URL_SESSIONS) as Promise<string[]>
	}

	async getMediaData(mediaId: string) {
		const unnecessaryCaseId = '0'
		const response = await this.getMediaUrl(unnecessaryCaseId, mediaId)
		if (!response || !response.url) {
			throw new Error('Failed to get media URL from server')
		}
		console.info(`Preloading media item ${mediaId}`)

		// If this is *NOT* a CloudFront signed URL, (i.e. our own server) then send our auth
		// token in the headers
		const opts: Record<string, unknown> = {}
		if (!response.url.includes('cloudfront.net')) {
			opts.headers = { Authorization: `Bearer ${this.authToken}` }
		}
		const response2 = await fetch(response.url, opts)
		const blob = await response2.blob()
		// Convert blob to arrayBuffer if it's a PDF (for the PDF.js viewer)
		if (blob.type === 'application/pdf') {
			return new Promise(resolve => {
				const fileReader = new FileReader()
				fileReader.onload = event => {
					const arrayBuffer = event.target.result
					resolve(arrayBuffer)
				}
				fileReader.readAsArrayBuffer(blob)
			})
		}
		return blob
	}

	async getIpAddress() {
		return (await this.GET('/ip')) as GetIpAddressResponse
	}

	async getLinkedAccessToken(name: string, passcode: string) {
		const { clientId } = this
		const body = { passcode, clientId, name }
		const response = (await this.POST(`/auth/link`, body, SERVER_URL_AUTH)) as GetLinkedAccessTokenResponse
		const { token } = response
		this.authToken = token.replace(/"/g, '')
		sessionStorage.setItem('authtoken', this.authToken)
		return token
	}

	getLinkPasswords() {
		return this.GET(`/linkpasswords`, SERVER_URL_PASSWORDS)
	}

	addNewLinkPassword() {
		return this.PUT(`/linkpasswords`, {}, SERVER_URL_PASSWORDS)
	}

	updateLinkPassword(key: string, data: SimpleObject) {
		return this.POST(`/linkpasswords/${key}`, data, SERVER_URL_PASSWORDS)
	}

	deleteLinkPassword(key: string) {
		return this.DELETE(`/linkpasswords/${key}`, SERVER_URL_PASSWORDS)
	}

	openItem(scheduleRowId: string, sessionId: string) {
		return this.POST(`/openitem`, { scheduleRowId, sessionId }, SERVER_URL_RELEASES)
	}

	getOpenings(groupId: string, sessionId: string) {
		return this.GET(`/session/${sessionId}/openings/${groupId}`, SERVER_URL_RELEASES)
	}

	getReleasesForSession(sessionId: string) {
		return this.GET(`/session/${sessionId}/releases`, SERVER_URL_RELEASES)
	}

	deleteLinkLinkPassword(key: string) {
		return this.DELETE(`/linkpasswords/${key}`, SERVER_URL_PASSWORDS)
	}

	getLicence() {
		return this.GET(`/licence`, SERVER_URL_AUTH)
	}

	updateParticipantColour(participantId: string, colour: string) {
		return this.POST(`/participant`, { participantId, colour }, SERVER_URL_PARTICIPANTS)
	}

	updateParticipantPhoneNumber(participantId: string, phoneNumber: string) {
		return this.POST(`/participant`, { participantId, phoneNumber }, SERVER_URL_PARTICIPANTS)
	}

	getParticipants() {
		return this.GET(`/participant`, SERVER_URL_PARTICIPANTS) as Promise<GetParticipantsResponse>
	}

	getServerStatus() {
		return this.GET(`/videoconf/status`, SERVER_URL_VIDEOCONF)
	}

	getServerRegions() {
		return this.GET(`/videoconf/regions`, SERVER_URL_VIDEOCONF)
	}

	startServer(region: string) {
		return this.POST(`/videoconf/start`, { region }, SERVER_URL_VIDEOCONF)
	}

	stopServer() {
		return this.POST(`/videoconf/stop`, {}, SERVER_URL_VIDEOCONF)
	}

	startMainCall(callId: string, modId: string, mainCallWarningEnabled = false) {
		return this.POST(`/videoconf/startcall`, { callId, modId, mainCallWarningEnabled }, SERVER_URL_VIDEOCONF)
	}

	endMainCall() {
		return this.POST(`/videoconf/endcall`, {}, SERVER_URL_VIDEOCONF)
	}

	updateSettings(settings: Settings) {
		return this.POST(`/settings`, settings, SERVER_URL_SESSIONS)
	}

	getSettings() {
		return this.GET(`/settings`, SERVER_URL_SESSIONS) as Promise<GetSettingsResponse>
	}

	resendPhoneMessage(scheduleRowId: string) {
		return this.POST(`/resendPhoneMessage`, { scheduleRowId }, SERVER_URL_RELEASES)
	}
}

const comms = new OnlineComms()
window.comms = comms // Store globally so it can be tested from browser console

export default comms
