import React, { ChangeEventHandler, FunctionComponent } from 'react'

import config from '../../config'

type DecisionMakerDropdownProps = {
	participants: StateTree['participants']
	onChange: ChangeEventHandler<HTMLSelectElement>
	className?: string
	disabled?: boolean
	value?: string
}

const DecisionMakerDropdown: FunctionComponent<DecisionMakerDropdownProps> = props => {
	const { participants, className, disabled, onChange, value } = props
	const { ATTRIB_CURR_AUTHOR, DECISION_MAKER_SELECT, OFFLINE } = config.strings

	// Placeholder option
	const options = [
		<option key="" value="" disabled>
			{DECISION_MAKER_SELECT}
		</option>,
	]

	// Add an <option> for each participant. The 'value' is the ID of the participant
	// Show the option as disabled if the participant has gone offline
	options.push(
		...participants.map(participant => {
			const { online } = participant
			return (
				<option key={participant.id} value={participant.id} disabled={!online}>
					{participant.name} {online ? '' : `(${OFFLINE})`}
				</option>
			)
		})
	)

	return (
		<div className={className}>
			<label htmlFor="decisionMaker">{`${ATTRIB_CURR_AUTHOR}:`}</label>
			<div className="input">
				<select name="decisionMaker" value={value} onChange={onChange} disabled={disabled}>
					{options}
				</select>
			</div>
		</div>
	)
}
export default DecisionMakerDropdown
