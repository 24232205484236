import participants from './participants'
import passwords from './passwords'
import speedtest from './speedtest'
import translate from './translate'
import videoconf from './videoconf'
import observers from './observers'
import messages from './messages'
import sessions from './sessions'
import settings from './settings'
import groups from './groups'
import socket from './socket'
import cases from './cases'
import login from './login'
import media from './media'
import misc from './misc'
import chat from './chat'

const actions = {
	linkedAccess: observers,
	participants,
	translate,
	videoconf,
	passwords,
	speedtest,
	messages,
	sessions,
	settings,
	groups,
	socket,
	media,
	cases,
	login,
	misc,
	chat,
}

// Action creators
export default actions
