import translationSelector from '../selectors/translation'
import OnlineComms from '../core/OnlineComms'
import action from '../util/action'
import types from './types'

const { TEXT_TRANSLATED } = types

export const translate = (text: string, toLang: string, fromLang = 'EN') => async (
	dispatch: Dispatch,
	getState: GetState
): Promise<string> => {
	const state = getState()
	// Check if we already have this translation in state
	const existing = translationSelector(state, text, toLang, fromLang)
	if (existing) return existing

	if (!toLang || fromLang === toLang) return existing

	const translation = (await OnlineComms.translate(fromLang, toLang, [text]))?.[0]
	if (translation) {
		const payload = { fromLang, toLang, text, translation }
		dispatch(action(TEXT_TRANSLATED, payload))
	}
	return translation || text
}

const actions = {
	translate,
}

export default actions
