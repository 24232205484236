export default {
	/* Team view */
	OK: 'OK',
	CANCEL: 'Anulare',
	TUTOR: 'Facilitator',
	CURRENT_SESSION: 'Sesiune curentă',
	TEAM: 'Echipă',
	SIGNOUT: 'Deconectare',
	INITIAL_UPDATE: 'Informații despre sesiune',
	ADVICE: 'Decizie',
	RATIONALE: 'Justificare',
	MEDIA_FEED: 'Postări media',
	MESSAGES: 'Mesage',
	PREVSESSIONS: 'Sesiunile anterioare',
	RETURN_TO_CURRENT: 'Revenire la sesiunea curentă',
	SESSION_IS_PAUSED: 'La moment sesiunea este întreruptă',
	ATTRIB_CURR_AUTHOR: 'Factor de decizie',
	SAVE: 'Salvați',
	CHANGE: 'Schimbare',
	NO_AUTHOR: 'Nesetat',
	NO_INITIAL_INFO: 'Vă rugăm să așteptați începerea următoarei sesiuni',
	DECISION_MAKER_SELECT: 'Vă rugăm să selectați un factor de decizie',
	SESSION_COMPLETE: 'Sesiunea anterioară a fost finisată',
	OPENED_AT: 'Deschis la',
	SEND_MESSAGE_PLACEHOLDER: 'Scrieți un mesaj',
	SEND: 'Trimiteți',
	TO_ALL_GROUPS: 'Tuturor grupurilor',
	SHOW_TRANSLATION: 'Aratați traducerea',
	SHOW_ORIGINAL: 'Aratați originalul',
	CREDENTIALS_EXPIRED: 'Codul vostru de acces a expirat. Rog să vă conectați din nou',
	INCOMING_MESSAGE: 'Mesaj de intrare',
	INCOMING_MESSAGE_TEXT: 'A fost recepționat un nou mesaj',
	INCOMING_MEDIA: 'Element media spre primire',
	INCOMING_MEDIA_TEXT: 'A fost primit un nou element media',
	INCOMING_UPDATE: 'Actualizare de spre intrare',
	INCOMING_UPDATE_TEXT: 'A fost primită o actualizare',
	CONFIRM_LOGOUT: 'Sigur doriți să vă deconectați?',
	MUTED_MESSAGE: 'Microfonul dvs. este oprit',
	NEWSTYPE_UPDATE: 'Actualizare primită',
	WARNING_TIME_MSG: 'Mai sunt 5 minute',
}
