import 'react-fine-uploader/gallery/gallery.css'
import Gallery from 'react-fine-uploader'
import React, { useState, FunctionComponent } from 'react'
import { connect } from 'react-redux'

import config from '../../config'
import Actions from '../actions'

import traditionalMediaUploader from '../util/traditionalMediaUploader'
import s3MediaUploader from '../util/s3MediaUploader'

type MediaUploadModalProps = {
	addUploadedMediaToCase: (fileName: string, fileId: string) => void
}

const MediaUploadModal: FunctionComponent<MediaUploadModalProps> = props => {
	const { addUploadedMediaToCase } = props
	const { UPLOAD_TYPE } = config

	const [error, setError] = useState('')
	const [uploader, setUploader] = useState(null)

	const onError = (msg: string) => setError(msg)

	if (!uploader) {
		setUploader(
			UPLOAD_TYPE === 'traditional'
				? traditionalMediaUploader(addUploadedMediaToCase)
				: s3MediaUploader(addUploadedMediaToCase, onError)
		)
		return null
	}

	// Get the accepted file extensions from the uploader options
	const accept = uploader?.options?.validation?.acceptFiles

	return (
		<>
			<Gallery fileInput-accept={accept} className="gallery" uploader={uploader} />
			{error && <div className="error">{error}</div>}
		</>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = () => ({})
const actions = {
	addUploadedMediaToCase: Actions.media.addUploadedMediaToCase,
}
export default connect(mapStateToProps, actions)(MediaUploadModal)
