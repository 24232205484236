export default {
	/* Team view */
	OK: 'OK',
	CANCEL: 'поништити',
	TUTOR: 'Водитељ',
	CURRENT_SESSION: 'Тренутна сесија',
	TEAM: 'Тим',
	SIGNOUT: 'Одјавити се',
	INITIAL_UPDATE: 'Информација о цесији',
	ADVICE: 'Одлука',
	RATIONALE: 'Образложење',
	MEDIA_FEED: 'Информација примљена',
	MESSAGES: 'Поруке',
	PREVSESSIONS: 'Претходна',
	RETURN_TO_CURRENT: 'Повратак на тренутну сесију',
	SESSION_IS_PAUSED: 'Сесија је тренутно заустављена',
	ATTRIB_CURR_AUTHOR: 'Доносилац одлуке',
	SAVE: 'Сачувај',
	CHANGE: 'Промијени',
	NO_AUTHOR: 'Није подешено',
	NO_INITIAL_INFO: 'Молимо сачекајте да започне следећа сесија',
	DECISION_MAKER_SELECT: 'Молимо изаберите доносиоца одлуке',
	SESSION_COMPLETE: 'Претходна сесија је завршена',
	OPENED_AT: 'Отворено у',
	SEND_MESSAGE_PLACEHOLDER: 'Напиши поруку',
	SEND: 'Пошаљи',
	TO_ALL_GROUPS: 'Свим групама',
	SHOW_TRANSLATION: 'Покажи превод',
	SHOW_ORIGINAL: 'Покажи оригинал',
	CREDENTIALS_EXPIRED: 'Ваша лозинка је истекла. Молимо вас пријавите се поново',
	INCOMING_MESSAGE: 'Долазна порука',
	INCOMING_MESSAGE_TEXT: 'Примљена је нова порука',
	INCOMING_MEDIA: 'Долазна медијска ставка',
	INCOMING_MEDIA_TEXT: 'Примљена је нова медијска ставка',
	INCOMING_UPDATE: 'Долазно ажурирање',
	INCOMING_UPDATE_TEXT: 'Примљено је ажурирање',
	CONFIRM_LOGOUT: 'Да ли сте сигурни да желите да се одјавите?',
	MUTED_MESSAGE: 'Микрофон је искључен',
	NEWSTYPE_UPDATE: 'Ажурирање примљено',
	WARNING_TIME_MSG: 'Преостало је 5 минута',
}
