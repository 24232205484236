import React from 'react'
import { connect } from 'react-redux'

import Actions from '../actions'
import config from '../../config'

import BubbleHeading from '../elements/BubbleHeading'
import Textarea from '../elements/Textarea'
import Switch from '../elements/Switch'
import Input from '../elements/Input'

// =================================================================================================

const Section = props => {
	const { disabled, children, className = '' } = props
	let cls = `section ${className}`
	if (disabled) cls += ' section--disabled'
	return <div className={cls}>{children}</div>
}
const Row = props => {
	const { children, className = '' } = props
	return <div className={`row ${className}`}>{children}</div>
}

function TutorSettings(props) {
	const { SETTINGS, SETTINGS_WAITING_ROOM, SETTINGS_WAITING_ROOM_MSG } = config.strings

	const { settings, updateSettings } = props
	const {
		waitingRoomEnabled = false,
		waitingRoomMessage,
		translateInitialUpdate = false,
		allowAnyoneToChangeAuthor = false,
		allowAnyoneToSendMessages = false,
		promptParticipantPhone = false,
		videoCallWarningTime = 0,
		videoCallWarningMessage = '',
		videoCallWarningTitle = '',
		videoCallParticipantsStartMuted = true,
		videoCallObserversStartMuted = false,
		videoCallFacilitatorsStartMuted = false,
	} = settings

	const onChange = (field, value) => {
		const update = { [field]: value }
		updateSettings(update)
	}

	const onChangeForSwitch = field => checked => onChange(field, checked)

	return (
		<div className="tutor-settings">
			<div className="tutor-settings__top">
				<BubbleHeading>{SETTINGS}</BubbleHeading>
			</div>
			<div className="tutor-settings__content">
				<Section>
					<Row>
						<h2>{SETTINGS_WAITING_ROOM}</h2>
						<Switch value={waitingRoomEnabled} onChange={onChangeForSwitch('waitingRoomEnabled')} />
					</Row>
					<p>A message to be displayed to all participants at login when no current session is in progress.</p>
					{waitingRoomEnabled && (
						<Textarea
							onChange={e => onChange('waitingRoomMessage', e.target.value)}
							placeholder={SETTINGS_WAITING_ROOM_MSG}
							value={waitingRoomMessage}
						/>
					)}
				</Section>

				<Section>
					<Row>
						<h2>Translate session information</h2>
						<Switch value={translateInitialUpdate} onChange={onChangeForSwitch('translateInitialUpdate')} />
					</Row>
					<p>
						If enabled, the session information will automatically be translated for all participants into their
						selected language. They will also be given the option to see the information as it was originally written.
					</p>
				</Section>

				<Section>
					<Row>
						<h2>Allow any participant to change decision maker</h2>
						<Switch value={allowAnyoneToChangeAuthor} onChange={onChangeForSwitch('allowAnyoneToChangeAuthor')} />
					</Row>
					<p>
						If enabled, any participant in a team will have the ability to change the decision maker. Otherwise, only
						the
						<em> current </em>decision maker can switch control to a different team member.
					</p>
				</Section>

				<Section>
					<Row>
						<h2>Allow any participant to send messages</h2>
						<Switch value={allowAnyoneToSendMessages} onChange={onChangeForSwitch('allowAnyoneToSendMessages')} />
					</Row>
					<p>Only the current decision maker will be allowed to send messages to the facilitator unless enabled.</p>
				</Section>

				<Section>
					<Row>
						<h2>Ask for phone number at login</h2>
						<Switch value={promptParticipantPhone} onChange={onChangeForSwitch('promptParticipantPhone')} />
					</Row>
					<p>
						If enabled, participants will be asked for their phone number at login. This will then be used to send media
						as a voice call to their device. Media must be flagged as &quot;Can be sent to phone&quot;. Participants can
						choose not to provide their phone number.
					</p>
				</Section>

				<Section className="warningSection">
					<Row>
						<h2>Video call warning</h2>
					</Row>
					<p>
						Configure the message to be displayed to participants when a countdown warning is used prior to a video
						call.
					</p>
					<Input
						placeholder="Title"
						value={videoCallWarningTitle === ' ' ? '' : videoCallWarningTitle}
						onChange={e => onChange('videoCallWarningTitle', e.target.value || '')}
					/>
					<Textarea
						placeholder="Message"
						value={videoCallWarningMessage}
						onChange={e => onChange('videoCallWarningMessage', e.target.value)}
					/>
					<Row>
						<label htmlFor="video-call-warning-time">Number of seconds</label>
						<input
							type="number"
							id="video-call-warning-time"
							value={videoCallWarningTime}
							onChange={e => onChange('videoCallWarningTime', parseInt(e.target.value) || 0)}
						/>
					</Row>
				</Section>

				<Section>
					<Row>
						<h2>Video call default settings</h2>
					</Row>
					<Row className="label-paragraph-switch-block">
						<div>
							<label>Facilitator starts muted</label>
							<p>If selected, facilitators will begin on mute when starting a video call.</p>
						</div>
						<Switch
							value={videoCallFacilitatorsStartMuted}
							onChange={onChangeForSwitch('videoCallFacilitatorsStartMuted')}
						/>
					</Row>
					<Row className="label-paragraph-switch-block">
						<div>
							<label>Observers start muted</label>
							<p>If selected, observers will begin on mute when joining a video call.</p>
						</div>
						<Switch value={videoCallObserversStartMuted} onChange={onChangeForSwitch('videoCallObserversStartMuted')} />
					</Row>
					<Row className="label-paragraph-switch-block">
						<div>
							<label>Participants start muted</label>
							<p>If selected, participants will begin on mute when joining a video call.</p>
						</div>
						<Switch
							value={videoCallParticipantsStartMuted}
							onChange={onChangeForSwitch('videoCallParticipantsStartMuted')}
						/>
					</Row>
				</Section>
			</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = state => ({
	settings: state.settings || {},
})
const actions = {
	updateSettings: Actions.settings.updateSettings,
}
export default connect(mapStateToProps, actions)(TutorSettings)
