/* eslint-disable jsx-a11y/interactive-supports-focus */
import { connect } from 'react-redux'
import React, { FunctionComponent, useState } from 'react'

import getTextDirection from '../util/getTextDirection'
import translationSelector from '../selectors/translation'
import sessionSelector from '../selectors/session'
import config from '../../config'

// =================================================================================================
const Text: FunctionComponent = props => {
	const { children } = props
	return (
		<p className="initial-information__text" style={{ direction: getTextDirection(children) }}>
			{children}
		</p>
	)
}

// =================================================================================================

type InitialInformationProps = {
	onClick: () => void
	initialUpdate: string
	initialUpdateTranslation?: string
}

const InitialInformation: FunctionComponent<InitialInformationProps> = props => {
	const { onClick, initialUpdate, initialUpdateTranslation } = props
	const { NO_INITIAL_INFO, SHOW_TRANSLATION, SHOW_ORIGINAL } = config.strings

	const translationExists = Boolean(initialUpdateTranslation) && initialUpdate !== initialUpdateTranslation
	const [showTranslation, setShowTranslation] = useState(true)

	let text = showTranslation && translationExists ? initialUpdateTranslation : initialUpdate

	text = (text || '').trim()
	const content = text ? <Text>{text}</Text> : <div style={{ textAlign: 'center' }}>{NO_INITIAL_INFO}</div>

	const onTranslateClick = () => setShowTranslation(!showTranslation)
	return (
		<div className="initial-information" onClick={onClick}>
			<div className="initial-information__text-wrapper">{content}</div>

			{translationExists && (
				<div className="initial-information__translate" onClick={onTranslateClick} role="button">
					{showTranslation ? SHOW_ORIGINAL : SHOW_TRANSLATION}
				</div>
			)}
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<InitialInformationProps> => {
	const session = sessionSelector(state)

	const settings = state.settings || {}
	const { translateInitialUpdate } = settings

	// Check if we have a translation for the initialUpdate
	const { initialUpdate } = session
	let initialUpdateTranslation = null
	if (translateInitialUpdate && initialUpdate) {
		const text = initialUpdate
		const toLang = state.group?.language
		initialUpdateTranslation = translationSelector(state, text, toLang, 'XX')
	}

	return { initialUpdate, initialUpdateTranslation }
}
const actions = {}
export default connect(mapStateToProps, actions)(InitialInformation)
