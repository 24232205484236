import { connect } from 'react-redux'
import React from 'react'

import config from '../../config'
import Actions from '../actions'

import ConfirmationHelper from '../util/ConfirmationHelper'
import { sessionIsActive } from '../util/SessionHelpers'
import IconButton from '../elements/IconButton'
import Textarea from '../elements/Textarea'
import Button from '../elements/Button'

type ReleaseScheduleRowProps = {
	removeReleaseRow?: (sessionId: string, rowId: string) => void
	displayModalPopup?: typeof Actions.misc.displayModalPopup
	currentSession?: StateTree['currentSession']
	onDataChange?: (row: ScheduleRow) => void
	releaseNow?: (rowId: string) => void
	viewingPreviousSession?: boolean
	mediaItems?: Array<MediaItem>
	groups?: StateTree['groups']
	releases?: Array<Release>
	data?: ScheduleRow
}

type ReleaseScheduleRowState = {
	mediaitem?: string
	release?: string
	update?: string
	type?: string
	at?: number
}

class ReleaseScheduleRow extends React.Component<ReleaseScheduleRowProps, ReleaseScheduleRowState> {
	constructor(props: ReleaseScheduleRowProps) {
		super(props)
		const { type = 'update', release = 'manual', at = 0, mediaitem = null, update = '' } = props.data
		this.state = { type, release, at, mediaitem, update }
		this._onAnyFieldChange = this._onAnyFieldChange.bind(this)
		this._onClickRelease = this._onClickRelease.bind(this)
		this._onClickRemove = this._onClickRemove.bind(this)
	}

	_onAnyFieldChange(name: string, e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
		// Grab input field value and store in state
		const { onDataChange, data } = this.props

		// eslint-disable-next-line prefer-destructuring
		let value: string | number = e.currentTarget.value

		if (name === 'at') value = parseInt(value) || 0
		const changes = {}
		changes[name] = value

		// Broadcast data in row
		if (onDataChange) {
			const row = { ...data, ...this.state, ...changes }
			onDataChange(row)
		}

		this.setState(changes)
	}

	_onClickRemove() {
		const { removeReleaseRow, data = {} as ScheduleRow } = this.props
		const { CONFIRM_DELETE_ROW } = config.strings
		ConfirmationHelper.confirm(CONFIRM_DELETE_ROW, () => removeReleaseRow(data.sessionId, data.timestamp))
	}

	_onClickRelease() {
		const { releaseNow, data = {} as ScheduleRow } = this.props
		const { CONFIRM_RELEASE_NOW } = config.strings
		ConfirmationHelper.confirm(CONFIRM_RELEASE_NOW, () => releaseNow(data.timestamp))
	}

	_renderReleaseMessage() {
		const { data, releases } = this.props
		const release = releases.filter(r => r.rowId === data.timestamp)

		const className = 'scheduleRow__message'
		if (release.find(r => r.groupId === 'ALL')) {
			const releaseTime = release.find(r => r.groupId === 'ALL').timestamp
			return <span className={className}>Released at {new Date(releaseTime).toTimeString().substr(0, 8)}</span>
		}
		const count = release.length
		if (count > 0) {
			return <span className={className}>Released to {count} groups</span>
		}
		return <span className={className} />
	}

	_renderReleaseToGroupsButton(isCurrentSession: boolean) {
		const { displayModalPopup, data } = this.props
		if (!isCurrentSession) return null

		const enabled = this._allowRelease(isCurrentSession)
		const onClick = () => displayModalPopup('modal-grouprelease', data.timestamp)
		return <Button text={config.strings.RELEASE_TO_GROUPS} enabled={enabled} onClick={onClick} />
	}

	_allowRelease(isCurrentSession: boolean) {
		const { data, groups, releases } = this.props

		if (!isCurrentSession) return false

		const groupCount = groups.length

		const releasesOfThisRow = releases.filter(r => r.rowId === data.timestamp)
		const releasedToAll = Boolean(releasesOfThisRow.find(r => r.groupId === 'ALL'))
		const releasedGroupCount = releasesOfThisRow.filter(r => r.groupId !== 'ALL').length
		return !releasedToAll && (groupCount > releasedGroupCount || groupCount === 0)
	}

	render() {
		const { data, currentSession, mediaItems, viewingPreviousSession } = this.props
		const { update, type, release, mediaitem, at } = this.state
		const { UPDATE_TEXT_PLACEHOLDER, MEDIA_ITEM, UPDATE, MANUALLY, AUTOMATIC } = config.strings
		const { SELECT, RELEASE, REMOVE, RELEASE_NOW } = config.strings

		const mediaOptions = mediaItems.map(m => (
			<option key={m.timestamp} value={m.timestamp}>
				{m.name}
			</option>
		))
		mediaOptions.unshift(
			<option key="" value="">
				{SELECT}
			</option>
		)

		const isCurrentSession = !viewingPreviousSession && sessionIsActive(currentSession)

		// Allow item to be released now if this isession is the current session and it's active
		const allowRelease = this._allowRelease(isCurrentSession)

		const typeDropdown = (
			<select className="type_dropdown" onChange={e => this._onAnyFieldChange('type', e)} value={type}>
				<option value="media">{MEDIA_ITEM}</option>
				<option value="update">{UPDATE}</option>
			</select>
		)
		const mediaDropdown = (
			<select
				className="media_dropdown"
				onChange={e => this._onAnyFieldChange('mediaitem', e)}
				value={mediaitem || undefined}
				required
			>
				{mediaOptions}
			</select>
		)
		const releaseWhenDropdown = (
			<select className="release_dropdown" onChange={e => this._onAnyFieldChange('release', e)} value={release}>
				<option value="manual">{MANUALLY}</option>
				<option value="automatic">{AUTOMATIC}</option>
			</select>
		)
		const releaseAtInput = (
			<div style={{ flexShrink: 0 }}>
				after
				<input className="at_input" type="number" onChange={e => this._onAnyFieldChange('at', e)} value={at} />
				minutes
			</div>
		)

		const releaseMessage = this._renderReleaseMessage()

		return (
			<div key={data.timestamp} className="scheduleRow">
				<div className="row">
					<span className="scheduleRow__index">{data.index + 1}</span>
					<span className="label">{RELEASE}</span>
					{typeDropdown}
					{type === 'media' && mediaDropdown}
					{releaseWhenDropdown}
					{release === 'automatic' && releaseAtInput}
					<div className="row-spacer" />
					<IconButton iconName="trash-alt" title={REMOVE} onClick={this._onClickRemove} />
				</div>
				{type === 'update' && (
					<Textarea
						value={update}
						onChange={e => this._onAnyFieldChange('update', e)}
						placeholder={UPDATE_TEXT_PLACEHOLDER}
					/>
				)}

				{isCurrentSession && (
					<div className="row">
						{releaseMessage}
						{isCurrentSession && <Button text={RELEASE_NOW} enabled={allowRelease} onClick={this._onClickRelease} />}
						{this._renderReleaseToGroupsButton(isCurrentSession)}
					</div>
				)}
			</div>
		)
	}
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<ReleaseScheduleRowProps> => {
	const { viewingPreviousSession } = state
	const releases = viewingPreviousSession ? state?.previousSession?.releases : state?.releases
	return {
		currentSession: state.currentSession || ({} as CurrentSession),
		mediaItems: state.openCase.media || ([] as Array<MediaItem>),
		groups: state.groups || ([] as Array<GroupDetails>),
		releases: releases || ([] as Array<Release>),
		viewingPreviousSession,
	}
}
const actions: Partial<ReleaseScheduleRowProps> = {
	removeReleaseRow: Actions.sessions.removeReleaseRow,
	displayModalPopup: Actions.misc.displayModalPopup,
	releaseNow: Actions.sessions.releaseNow,
}
export default connect(mapStateToProps, actions)(ReleaseScheduleRow)
