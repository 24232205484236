import React, { ReactElement } from 'react'

import config from '../../config'

// Jitsi Meet API docs: https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-iframe
// Config.js: https://github.com/jitsi/jitsi-meet/blob/master/config.js
// Interface config: https://github.com/jitsi/jitsi-meet/blob/master/interface_config.js
const options = (callId: string, displayName: string) => ({
	roomName: callId,
	parentNode: document.getElementById('side-call__content'),
	configOverwrite: { startWithAudioMuted: false },
	interfaceConfigOverwrite: {
		filmStripOnly: true,
		TOOLBAR_ALWAYS_VISIBLE: true,
		TOOLBAR_BUTTONS: ['microphone', 'camera', 'profile', 'settings'],
		FILM_STRIP_MAX_HEIGHT: 200,
		// DISABLE_FOCUS_INDICATOR: true,
		// DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
		LOCAL_THUMBNAIL_RATIO: 16 / 9,
		REMOTE_THUMBNAIL_RATIO: 16 / 9,
		SHOW_JITSI_WATERMARK: false,
		HIDE_INVITE_MORE_HEADER: true,
		DISABLE_VIDEO_BACKGROUND: true,
	},
	userInfo: { displayName },
})

type SideCallProps = {
	domain: string
	callId: string
	displayName: string
}

type SideCallState = {
	muted: boolean
}

class SideCall extends React.Component<SideCallProps, SideCallState> {
	private api

	private callId: string

	constructor(props: SideCallProps) {
		super(props)
		this.state = { muted: false }
	}

	componentDidMount(): void {
		document.body.classList.add('in-side-call')

		const { domain, callId, displayName } = this.props
		if (callId && domain) {
			this.launchJitsi(domain, callId, displayName)
		}
	}

	componentDidUpdate(): void {
		const { domain, callId, displayName } = this.props
		if (callId !== this.callId && domain) {
			this.launchJitsi(domain, callId, displayName)
		}
	}

	componentWillUnmount(): void {
		if (this.api) this.api.dispose()
		document.body.classList.remove('in-side-call')
	}

	launchJitsi(domain: string, callId: string, displayName: string): void {
		if (this.api) this.api.dispose()

		this.callId = callId

		try {
			this.api = new window.JitsiMeetExternalAPI(domain, options(callId, displayName))

			if (!this.api) throw new Error('Failed to create JitsiMeetExternalAPI istance')

			// Force blank room name
			this.api.executeCommand('subject', ' ')
			this.api.on('videoConferenceJoined', _ => {
				this.api.executeCommand('subject', ' ')
			})

			// Store muted status in state so we can display alert bar
			this.api.on('audioMuteStatusChanged', ({ muted }) => this.setState({ muted }))
		} catch (error) {
			console.error('Failed to start the conference', error)
		}
	}

	render(): ReactElement {
		const { muted } = this.state
		const { MUTED_MESSAGE } = config.strings

		return (
			<div id="side-call">
				<div id="side-call__content" />
				{muted && <div className="main-call__mute-message">{MUTED_MESSAGE}</div>}
			</div>
		)
	}
}

export default SideCall
