/* eslint-disable react/prefer-stateless-function */
import React, { ReactElement } from 'react'

import Message from '../elements/Message'

type MessageListProps = {
	data: Array<Message>
	showWhoMessageIsTo?: boolean
}

function MessageList(props: MessageListProps): ReactElement<MessageListProps> {
	const { data, showWhoMessageIsTo } = props
	const className = 'message-list'

	const messages = (data || []).map(msg => (
		<Message key={msg.id} message={msg} showWhoMessageIsTo={showWhoMessageIsTo} />
	))
	return <div className={className}>{messages}</div>
}

export default MessageList
