import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useUnmount } from 'react-use'
import JitsiClient, { JitsiClientEvents } from '../util/JitsiClient'
import IconButton from '../elements/IconButton'

type VideoCallCameraButtonProps = {
	client: JitsiClient
}

function VideoCallCameraButton(props: VideoCallCameraButtonProps): ReactElement<VideoCallCameraButtonProps> {
	const { client } = props
	const [muted, setMuted] = useState(false)

	// Array of JitsiClient event handler references
	const eventHandlerRefs = useRef<number[]>([])

	useEffect(() => {
		if (!client) return
		const eventHandlers = eventHandlerRefs.current

		// When we join a room, check that our muted status matches our button
		const refresh = () => setMuted(client.localParticipant.isCameraMuted)
		eventHandlers.push(client.on(JitsiClientEvents.ROOM_JOINED, refresh))
		eventHandlers.push(client.on(JitsiClientEvents.LOCAL_PARTICIPANT_CHANGED, refresh))
		// Also do that now to initialise the state
		refresh()
	}, [client])

	// On unmount, detach event handler callbacks from Jitsi client
	useUnmount(() => eventHandlerRefs.current.forEach(ref => client.off(ref)))

	// Toggle camera when button is clicked
	const onClickMute = async () => {
		if (!client) return
		setMuted(!muted)
		// There will also be a LOCAL_PARTICIPANT_CHANGED event that we will respond to
		await client.toggleMuteCamera()
	}

	// Display correct icon if microphone/camera is muted
	const camMuteIcon = muted ? 'video-slash' : 'video'

	return <IconButton iconName={camMuteIcon} onClick={onClickMute} selected={muted} title="Toggle camera" />
}

export default VideoCallCameraButton
