export default {
	/* Team view */
	OK: 'OK',
	CANCEL: 'Batal',
	TUTOR: 'Fasilitator',
	CURRENT_SESSION: 'Sesi semasa',
	TEAM: 'Pasukan',
	SIGNOUT: 'Log keluar',
	INITIAL_UPDATE: 'Maklumat sesi',
	ADVICE: 'Keputusan',
	RATIONALE: 'Rasional',
	MEDIA_FEED: 'Suapan Media',
	MESSAGES: 'Mesej',
	PREVSESSIONS: 'Sesi sebelumnya',
	RETURN_TO_CURRENT: 'Kembali ke sesi semasa',
	SESSION_IS_PAUSED: 'Sesi dihentikan buat seketika pada masa ini',
	ATTRIB_CURR_AUTHOR: 'Pembuat keputusan',
	SAVE: 'Simpan',
	CHANGE: 'Ubah',
	NO_AUTHOR: 'Tidak ditetapkan',
	NO_INITIAL_INFO: 'Sila tunggu sesi seterusnya bermula',
	DECISION_MAKER_SELECT: 'Sila pilih pembuat keputusan',
	SESSION_COMPLETE: 'Sesi sebelumnya telah pun selesai',
	OPENED_AT: 'Dibuka pada',
	SEND_MESSAGE_PLACEHOLDER: 'Tulis mesej',
	SEND: 'Hantar',
	TO_ALL_GROUPS: 'Kepada semua kumpulan',
	SHOW_TRANSLATION: 'Tunjukkan terjemahan',
	SHOW_ORIGINAL: 'Paparan asal',
	CREDENTIALS_EXPIRED: 'Kod laluan anda telah tamat tempoh. Sila log masuk semula',
	INCOMING_MESSAGE: 'Mesej masuk',
	INCOMING_MESSAGE_TEXT: 'Mesej baru telah diterima',
	INCOMING_MEDIA: 'Item media masuk',
	INCOMING_MEDIA_TEXT: 'Item media baru telah diterima',
	INCOMING_UPDATE: 'Kemaskini masuk',
	INCOMING_UPDATE_TEXT: 'Kemaskini telah di terima',
	CONFIRM_LOGOUT: 'Adakah anda pasti mahu log keluar?',
	MUTED_MESSAGE: 'Mikrofon anda diredam',
	NEWSTYPE_UPDATE: 'Kemas kini diterima',
	WARNING_TIME_MSG: 'Tinggal 5 minit lagi',
}
