import React, { FunctionComponent } from 'react'

import Icon from './Icon'

type LoginButtonProps = {
	iconName?: string
	onClick?: () => unknown
	text?: string
}

const LoginButton: FunctionComponent<LoginButtonProps> = props => {
	const { iconName, onClick, text } = props
	return (
		<div className="login-button" onClick={onClick} role="button" tabIndex={0} onKeyPress={onClick}>
			<div className="login-button__circle">
				<Icon name={iconName} />
			</div>
			<span className="login-button__text">{text}</span>
		</div>
	)
}

export default LoginButton
