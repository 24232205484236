import React, { FunctionComponent } from 'react'

import JitsiClient from '../util/JitsiClient'

import VideoCallMuteButton from './VideoCallMuteButton'
import IconButton from '../elements/IconButton'
import VideoCallCameraButton from './VideoCallCameraButton'
import VideoCallScreenshareButton from './VideoCallScreenshareButton'
import VideoCallChatButton from './VideoCallChatButton'

type VideoCallButtonsProps = {
	onClickChangeLayout: () => unknown
	onClickSettings: () => unknown
	onClickEndCall: () => unknown
	onClickChat: () => unknown
	showEndCall: boolean
	showScreenShare: boolean
	client: JitsiClient
	isScreenshareActive?: boolean
	viewMode?: string
}

const VideoCallButtons: FunctionComponent<VideoCallButtonsProps> = props => {
	const { onClickChangeLayout, onClickSettings, onClickEndCall, onClickChat } = props
	const { showEndCall, showScreenShare, viewMode, client } = props

	return (
		<div className="video-call-buttons">
			<VideoCallChatButton client={client} onClick={onClickChat} />
			{showScreenShare && <VideoCallScreenshareButton client={client} />}
			<IconButton
				iconName={viewMode === 'TILE' ? 'speaker-view-light' : 'grid'}
				onClick={onClickChangeLayout}
				title="Change layout"
			/>
			{showEndCall && (
				<IconButton
					className="video-call-buttons__hang-up"
					iconName="phone"
					onClick={onClickEndCall}
					title="End call"
				/>
			)}
			<VideoCallMuteButton client={client} />
			<VideoCallCameraButton client={client} />
			<IconButton iconName="cog" onClick={onClickSettings} title="Settings" />
		</div>
	)
}

export default VideoCallButtons
