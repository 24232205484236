import React, { ChangeEventHandler, FunctionComponent, KeyboardEventHandler, MouseEventHandler } from 'react'
import { connect } from 'react-redux'

import checkFieldHasCorrectDirection from '../util/checkFieldHasCorrectDirection'
import getTextDirection from '../util/getTextDirection'
import { sessionIsActive } from '../util/SessionHelpers'
import config from '../../config'
import Actions from '../actions'

import DecisionMakerDropdown from '../elements/DecisionMakerDropdown'
import Button from '../elements/Button'
import Input from '../elements/Input'

const className = 'decision-maker'

// =================================================================================================

type DecisionMakerInputProps = {
	value?: string
	onEnter: KeyboardEventHandler<HTMLInputElement>
	onChange: ChangeEventHandler<HTMLInputElement>
}

const DecisionMakerInput: FunctionComponent<DecisionMakerInputProps> = ({ value, onEnter, onChange }) => (
	<Input focus selectAll value={value} error={!value} onEnter={onEnter} onChange={onChange} />
)

// =================================================================================================

type DecisionMakerReadOnlyProps = {
	value?: string
	onClick: MouseEventHandler<HTMLDivElement>
}

const DecisionMakerReadOnly: FunctionComponent<DecisionMakerReadOnlyProps> = ({ value, onClick }) => (
	<div className={`${className}__readonly`} onClick={onClick} style={{ direction: getTextDirection(value) }}>
		{value}
	</div>
)

// =================================================================================================

type DecisionMakerProps = {
	currAuthor?: string
	updateCurrentAuthor?: (auth: string) => unknown
	allowAnyoneToChangeAuthor?: boolean
	currAuthorId?: string
	sessionInProgress?: boolean
	participants?: Array<Participant>
	participantId?: string
}

type DecisionMakerState = {
	currAuthor?: string
	newAuthor?: string
	editing?: boolean
}

class DecisionMaker extends React.Component<DecisionMakerProps, DecisionMakerState> {
	constructor(props: DecisionMakerProps) {
		super(props)
		this.state = { editing: !props.currAuthor, currAuthor: props.currAuthor }
	}

	static getDerivedStateFromProps(nextProps: DecisionMakerProps, prevState: DecisionMakerState) {
		if (nextProps.currAuthor && nextProps.currAuthor !== prevState.currAuthor && prevState.editing) {
			return { editing: false, currAuthor: nextProps.currAuthor }
		}
		return prevState
	}

	_onChangeNewAuthor(newVal: string) {
		const { newAuthor } = this.state
		if (newVal && newVal !== newAuthor) {
			this.setState({ newAuthor: newVal })
		}
	}

	_updateCurrentAuthor() {
		const { newAuthor } = this.state
		const { updateCurrentAuthor } = this.props
		this.setState({ editing: false, currAuthor: newAuthor })
		updateCurrentAuthor(newAuthor)
	}

	_enableEditing() {
		this.setState({ editing: true })
	}

	render() {
		const { currAuthor, currAuthorId, sessionInProgress, participants, updateCurrentAuthor, participantId } = this.props
		const { allowAnyoneToChangeAuthor } = this.props
		const { newAuthor, editing } = this.state
		const _editing = sessionInProgress && editing

		// Helper functions to create buttons
		const saveButton = () => <Button text={config.strings.SAVE} onClick={() => this._updateCurrentAuthor()} />
		const changeButton = () => <Button text={config.strings.CHANGE} onClick={() => this._enableEditing()} />

		let button = null
		let mainClassName = className
		if (sessionInProgress && !participants.length) {
			mainClassName += ` ${className}--editable`
			button = _editing ? saveButton() : changeButton()
		}

		// =============================================================================================
		// If we have a list of participants, display a dropdown list
		if (participants.length) {
			const onChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement> = e => {
				updateCurrentAuthor(e.currentTarget.value)
			}
			let authorVal = currAuthorId || currAuthor || ''

			// Ensure we don't get stuck if the current author is no longer in the list of active participants
			const currentAuthorParticipant = participants.find(p => p.id === currAuthorId)
			if (!currentAuthorParticipant) authorVal = ''

			// Allow user to change the current author if the session is in progress and:
			// 1. They are the current author, or
			// 2. Anyone is allowed to change the author, or
			// 3. No author selected, or
			// 4. Current author is offline
			const authorIsOnline = currentAuthorParticipant?.online
			const userIsCurrentAuthor = authorVal === participantId
			const enabled =
				sessionInProgress && (userIsCurrentAuthor || allowAnyoneToChangeAuthor || !authorVal || !authorIsOnline)

			return (
				<DecisionMakerDropdown
					participants={participants}
					className={mainClassName}
					sessionInProgress={sessionInProgress}
					onChange={onChange}
					value={authorVal}
					disabled={!enabled}
				/>
			)
		}
		// =============================================================================================

		const onClickReadOnly = sessionInProgress ? () => this._enableEditing() : null
		const onEnter: KeyboardEventHandler<HTMLInputElement> = () => this._updateCurrentAuthor()
		const onChange: ChangeEventHandler<HTMLInputElement> = e => {
			checkFieldHasCorrectDirection(e.target)
			this._onChangeNewAuthor(e.target.value)
		}

		const content = _editing ? (
			<DecisionMakerInput value={newAuthor} onEnter={onEnter} onChange={onChange} />
		) : (
			<DecisionMakerReadOnly value={currAuthor || config.strings.NO_AUTHOR} onClick={onClickReadOnly} />
		)

		return (
			<div className={mainClassName}>
				<label>{`${config.strings.ATTRIB_CURR_AUTHOR}:`}</label>
				{content}
				<div>{button}</div>
			</div>
		)
	}
}

// =================================================================================================
// Redux wiring
// =================================================================================================
// Map global state to component properties
const mapStateToProps = (state: StateTree) => {
	// Get list of participants who are on the same team as us
	const participants = (state.participants || []).filter(p => p?.colour === state?.group?.colour)
	const group: GroupDetails = state.group || ({} as GroupDetails)
	const viewingPreviousSession = state.viewingPreviousSession || false
	const currentSession: CurrentSession = state?.groupData?.currentSession || ({} as CurrentSession)
	const { allowAnyoneToChangeAuthor } = state.settings || {}

	// NOTE: This is different to sessionIsActive; sessionInProgress = false if the session is paused
	const sessionInProgress = !viewingPreviousSession && sessionIsActive(currentSession) && !currentSession.paused

	const { currAuthor, currAuthorId, participantId } = group

	return {
		participants,
		sessionInProgress,
		allowAnyoneToChangeAuthor,
		currAuthor,
		currAuthorId,
		participantId,
	}
}

// Map actions to component properties by just passing an object of those actions to connect()
const actions = {
	updateCurrentAuthor: Actions.groups.updateCurrentAuthor,
}
export default connect(mapStateToProps, actions)(DecisionMaker)
