import getTime from './getTime'

export function sessionIsActive(session: CurrentSession): boolean {
	return (session && session.startTime && !session.endTime && sessionSecondsLeft(session) > 0 && true) || false
}

export function elapsedSeconds(session: CurrentSession): number {
	if (!session.startTime || !session.duration) return 0

	let elapsed = 0
	let lastEnd
	let paused = false
	if (session.pauses && session.pauses.length) {
		for (let p = 0; p < session.pauses.length; p++) {
			const pause = session.pauses[p]
			if (p === 0) {
				elapsed = pause.start - session.startTime
			} else {
				elapsed += pause.start - lastEnd
			}
			if (pause.end) {
				lastEnd = pause.end
			} else {
				paused = true
				break
			}
		}
	} else {
		lastEnd = session.startTime
	}
	const now = getTime()
	if (!paused) {
		elapsed += now - lastEnd
	}
	return Math.round(elapsed / 1000)
}

export function sessionSecondsLeft(session: CurrentSession): number {
	if (session.endTime) {
		return 0
	}

	const secondsLeft = session.duration * 60 - elapsedSeconds(session)

	return secondsLeft < 0 ? 0 : secondsLeft
}

export default { sessionIsActive, sessionSecondsLeft, elapsedSeconds }
