// =============================================================================
// Components > Button
// =============================================================================

import React, { ReactNode, ReactElement, ReactEventHandler } from 'react'

type ButtonProps = {
	text?: string
	primary?: boolean
	secondary?: boolean
	level?: string
	enabled?: boolean
	children?: ReactNode
	onClick?: ReactEventHandler
}

function Button(props: ButtonProps): ReactElement<ButtonProps> {
	const { text, level, children, onClick, primary = false, enabled = true, secondary = false } = props

	let className = 'button'
	if (level === 'secondary') className += ' button--secondary'
	if (enabled === false) className += ' button--disabled'
	if (primary) className += ' button--primary'
	if (secondary) className += ' button--secondary'

	const _onClick = (enabled && onClick) || null

	return (
		<div className={className}>
			<button type="button" onClick={_onClick}>
				{children}
				<span className="button__text">{text}</span>
			</button>
		</div>
	)
}

export default Button
