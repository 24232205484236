import { connect } from 'react-redux'
import React, { FunctionComponent } from 'react'

import Actions from '../actions'
import config from '../../config'
import teamNameFromColour from '../util/teamNameFromColour'

import GroupSidebarButton from './GroupSidebarButton'
import TeamBadge from '../elements/TeamBadge'

type GroupSidebarProps = {
	groupSignOut: () => void
	displayModalPopup: (name: string) => void
	group: GroupDetails
	viewingPreviousSession: boolean
	returnToCurrent?: () => void
}

const GroupSidebar: FunctionComponent<GroupSidebarProps> = props => {
	const { PREVSESSIONS, RETURN_TO_CURRENT, SIGNOUT } = config.strings
	const { groupSignOut, group, viewingPreviousSession, returnToCurrent, displayModalPopup } = props

	// prettier-ignore
	const prevSessionButton = viewingPreviousSession
	  ? <GroupSidebarButton onClick={returnToCurrent} iconName="arrow-left" label={RETURN_TO_CURRENT} />
		: <GroupSidebarButton onClick={() => displayModalPopup("modal-prevsessions")} iconName="history" label={PREVSESSIONS} />

	return (
		<div className="group-sidebar">
			<div className="group-sidebar__top">
				<TeamBadge colour={group.colour} label={teamNameFromColour(group.colour)} />
				{prevSessionButton}
			</div>
			<div className="group-sidebar__bottom">
				<GroupSidebarButton onClick={groupSignOut} name="logout" iconName="power-off" label={SIGNOUT} />
			</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	group: state.group || {},
	location: state.navigatingTo,
	linkedAccess: state.linkedAccess && state.linkedAccess.connected,
	viewingPreviousSession: state.viewingPreviousSession,
})
const actions = {
	groupSignOut: Actions.login.signOut,
	displayModalPopup: Actions.misc.displayModalPopup,
	returnToCurrent: Actions.sessions.returnToCurrentSession,
}
export default connect(mapStateToProps, actions)(GroupSidebar)
