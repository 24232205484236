import { connect } from 'react-redux'
import React, { FunctionComponent } from 'react'

import Icon from '../elements/Icon'

import config from '../../config'

const { OPENED_AT } = config.strings

function formatTime(time: string | number) {
	return new Date(time).toTimeString().substr(0, 5)
}

// =================================================================================================

type MediaOpeningsRowProps = {
	opening: Opening
	participant: Participant
}
const MediaOpeningsRow: FunctionComponent<MediaOpeningsRowProps> = props => {
	const { opening, participant = {} as Participant } = props

	const openedAt = opening.lastUpdate ? `${OPENED_AT} ${formatTime(opening.lastUpdate)}` : ''
	return (
		<div key={opening.key} className="media-openings-modal__row">
			<span className="media-openings-modal__name">{participant.name || ''}</span>
			<span className="media-openings-modal__time">{openedAt}</span>
			<Icon name="check" />
		</div>
	)
}

// =================================================================================================

type MediaOpeningsModalProps = {
	rowId?: string
	openings: Opening[]
	participants: Participant[]
}
const MediaOpeningsModal: FunctionComponent<MediaOpeningsModalProps> = props => {
	const { rowId, openings, participants } = props

	// Find openings for this rowId
	const rowOpenings = openings.filter(o => o.key === rowId)
	return (
		<div className="media-openings-modal">
			{rowOpenings.map(o => (
				<MediaOpeningsRow
					key={o.participantId}
					opening={o}
					participant={participants.find(p => p.id === o.participantId)}
				/>
			))}
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = state => ({
	participants: state.participants || [],
	openings: state.openings || [],
})
const actions = {}
export default connect(mapStateToProps, actions)(MediaOpeningsModal)
