import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import config from '../../config'

import IconButton from '../elements/IconButton'

type GroupSidebarButtonProps = {
	name?: string
	iconName?: string
	showBadge?: boolean
	label?: string
	onClick?: () => void
	location: string
}

const GroupSidebarButton: FunctionComponent<GroupSidebarButtonProps> = props => {
	const { name, iconName, location, showBadge, label, onClick } = props

	// If we haven't been given an explicit onClick callback, then navigate to the place inferred
	// by the button name.
	const _label = label || config.strings[name.toUpperCase()]
	const selected = location && location.indexOf(`-${name}`) > 0

	return (
		<div role="button" tabIndex={0} className="sidebar-button" onKeyPress={onClick} onClick={onClick}>
			<IconButton key={name} iconName={iconName} selected={selected} showBadge={showBadge} />
			<div className="sidebar-button__label">{_label}</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	location: state.navigatingTo,
})
const actions = {}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Omit<GroupSidebarButtonProps, keyof (PropsFromState & ReduxActions)>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(GroupSidebarButton)
