import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useUnmount } from 'react-use'
import JitsiClient, { JitsiClientEvents } from '../util/JitsiClient'
import IconButton from '../elements/IconButton'

type VideoCallMuteButtonProps = {
	client: JitsiClient
}

const VideoCallMuteButton: FunctionComponent<VideoCallMuteButtonProps> = props => {
	const { client } = props
	const [muted, setMuted] = useState(client?.localParticipant?.isMuted)

	// Array of JitsiClient event handler references
	const eventHandlerRefs = useRef<number[]>([])

	useEffect(() => {
		if (!client) return
		const eventHandlers = eventHandlerRefs.current

		// When we join a room or the local participant is changed in some way, check that the muted state matches button
		const checkMute = () => setMuted(client?.localParticipant?.isMuted)
		eventHandlers.push(client.on(JitsiClientEvents.ROOM_JOINED, checkMute))
		eventHandlers.push(client.on(JitsiClientEvents.LOCAL_PARTICIPANT_CHANGED, checkMute))
		// Also do that now to initialise the state
		checkMute()
	}, [client])

	// On unmount, detach event handler callbacks from Jitsi client
	useUnmount(() => eventHandlerRefs.current.forEach(ref => client.off(ref)))

	// Toggle microphone mute when button is clicked
	const onClickMute = async () => {
		if (!client) return
		setMuted(!muted)
		// There will also be a LOCAL_PARTICIPANT_CHANGED event that we will respond to
		await client.toggleMuteAudio()
	}

	// Display correct icon
	const muteIcon = muted ? 'microphone-slash' : 'microphone'

	return <IconButton iconName={muteIcon} onClick={onClickMute} selected={muted} title="Toggle mute" />
}

export default VideoCallMuteButton
