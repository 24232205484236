function downloadBlob(blob: Blob, mimeType: string, filename = 'file.docx'): void {
	// It is necessary to create a new blob object with mime-type explicitly set
	// otherwise only Chrome works like it should
	const newBlob = new Blob([blob], { type: mimeType })

	// IE doesn't allow using a blob object directly as link href
	// instead it is necessary to use msSaveOrOpenBlob
	if (window.navigator && window.navigator.msSaveOrOpenBlob) {
		window.navigator.msSaveOrOpenBlob(newBlob)
		return
	}

	// For other browsers:
	// Create a link pointing to the ObjectURL containing the blob.
	const data = window.URL.createObjectURL(newBlob)
	const link = document.createElement('a')
	link.href = data
	link.download = filename
	link.click()

	// For Firefox it is necessary to delay revoking the ObjectURL
	setTimeout(() => window.URL.revokeObjectURL(data), 100)
}

export default downloadBlob
