import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import React from 'react'

import Actions from '../actions'
import config from '../../config'

import TutorObserverPasswords from './TutorObserverPasswords'
import TutorParticipants from './TutorParticipants'
import TutorPrevSessions from './TutorPrevSessions'
import TutorPasswords from './TutorPasswords'
import TutorVideoConf from './TutorVideoConf'
import TutorMessages from './TutorMessages'
import TutorSessions from './TutorSessions'
import TutorSettings from './TutorSettings'
import TutorGroups from './TutorGroups'
import TutorMedia from './TutorMedia'
import TutorCases from './TutorCases'

import TeamBreakoutRoomJoined from '../components/TeamBreakoutRoomJoined'
import ObserverBreakoutRoom from '../components/ObserverBreakoutRoom'
import VideoCallWaitMessage from '../components/VideoCallWaitMessage'
import MediaCacheComponent from '../components/MediaCacheComponent'
import TutorSidebar from '../components/TutorSidebar'
import SyncManager from '../components/SyncManager'
import VideoCall from '../components/VideoCall'
import MainCall from '../components/MainCall'

import StatusBar from '../elements/StatusBar'

import inCallSelector from '../selectors/inCall'

const TutorView = props => {
	const { mainCallId, domain, joinedBreakoutGroupId, serverStatus, observerBreakoutRoomsId } = props
	const { displayName, showMainCall } = props
	// react-router props
	const { location, match } = props

	const { VIDEO__NEW_COMPONENTS } = config

	// Check if we need to display a full screen video call ("main call") or breakout room.
	let mainCall = null
	let sideCall = null
	// Firstly, is the video conferencing server online?
	const vcAvailable = domain && serverStatus === 'online'

	if (vcAvailable && showMainCall) {
		if (VIDEO__NEW_COMPONENTS) mainCall = <VideoCall callId={mainCallId} domain={domain} displayName={displayName} />
		else mainCall = <MainCall callId={mainCallId} />
	}

	const showJoinedBreakoutRoom = vcAvailable && !showMainCall && joinedBreakoutGroupId
	if (showJoinedBreakoutRoom) sideCall = <TeamBreakoutRoomJoined />

	const showObserverBreakoutRoom = vcAvailable && !showMainCall && !showJoinedBreakoutRoom && observerBreakoutRoomsId
	if (showObserverBreakoutRoom) sideCall = <ObserverBreakoutRoom />

	// Show the selected facilitator page unless it is the videoconf page and we are in a call
	const showContent = location.pathname !== `${match.url}/videoconf` || !showMainCall

	return (
		<div className="tutor-view">
			<MediaCacheComponent />
			<SyncManager />
			<StatusBar />
			<div className="tutor-view__main">
				<TutorSidebar />
				<div className="tutor-view__content">
					{showContent && (
						<>
							<Switch>
								<Route path={`${match.url}/cases`} component={TutorCases} />
								<Route path={`${match.url}/media`} component={TutorMedia} />
								<Route path={`${match.url}/groups`} component={TutorGroups} />
								<Route path={`${match.url}/settings`} component={TutorSettings} />
								<Route path={`${match.url}/sessions`} component={TutorSessions} />
								<Route path={`${match.url}/messages`} component={TutorMessages} />
								<Route path={`${match.url}/passcodes`} component={TutorPasswords} />
								<Route path={`${match.url}/videoconf`} component={TutorVideoConf} />
								<Route path={`${match.url}/prevsessions`} component={TutorPrevSessions} />
								<Route path={`${match.url}/participants`} component={TutorParticipants} />
								<Route path={`${match.url}/observers`} component={TutorObserverPasswords} />
								<Route path={`${match.url}/`} component={TutorCases} />
							</Switch>
							<div className="tutor-view__content-overlay" />
						</>
					)}
					{mainCall}
					<VideoCallWaitMessage />
				</div>
				{sideCall}
			</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = state => {
	const loggedInWithLinkedAccess = state.linkedAccess && state.linkedAccess.connected
	const videoconf = state.videoconf || {}
	const showMainCall = inCallSelector(state)
	return {
		displayName: state.linkedAccess?.name || state?.tutor?.name || config.strings.TUTOR,
		observerBreakoutRoomsId: videoconf.observerBreakoutRoomsId,
		loggedIn: state.tutor.loggedIn || loggedInWithLinkedAccess,
		joinedBreakoutGroupId: state.joinedBreakout,
		linkedAccess: loggedInWithLinkedAccess,
		mainCallId: videoconf.mainCallId,
		serverStatus: videoconf.status,
		domain: videoconf.domain,
		showMainCall,
	}
}
const actions = {}

export default connect(mapStateToProps, actions)(TutorView)
