import React, { FunctionComponent, useState } from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { useMount } from 'react-use'

import Actions from '../actions'

import SocketHandler from '../components/SocketHandler'
import VideoCall from '../components/VideoCall'
import Modal from '../components/Modal'

import LoadingScreen from './LoadingScreen'
import GroupView from './GroupView'
import TutorView from './TutorView'
import Page404 from './Page404'
import Login from './Login'

import history from '../core/history'
import '../core/SocketComms'

import ConfirmationHelper from '../util/ConfirmationHelper'

// Make available globally to be used from Electron
window.ConfirmationHelper = ConfirmationHelper

type AppProps = {
	initialiseApp: () => void
	checkLogin: () => void
	navigatingTo?: string
	loggedIn: boolean
}

const App: FunctionComponent<AppProps> = props => {
	const { initialiseApp, checkLogin, navigatingTo, loggedIn } = props
	const [initialised, setInitialised] = useState(false)

	useMount(async () => {
		if (window.location.pathname !== '/videotest') {
			await initialiseApp()
			await checkLogin()
		}
		setInitialised(true)
	})

	if (!initialised) {
		return <LoadingScreen />
	}

	return (
		<Router history={history}>
			<div className="app">
				<Switch>
					<Route path="/videotest">
						<VideoCall callId="test" domain="video-eu-10a.v360g.com" displayName="James" />
					</Route>
					<Route path="/facilitator" component={TutorView} />
					<Route path="/participant" component={GroupView} />
					<Route path="/login" component={Login} />
					<Route exact path="/" component={Login} />
					<Route component={Page404} />
				</Switch>
				<Modal />
				<SocketHandler />
			</div>
		</Router>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	navigatingTo: state.navigatingTo,
	loggedIn: Boolean(state?.tutor?.loggedIn) || Boolean(state?.linkedAccess?.connected),
})

const actions: Partial<AppProps> = {
	initialiseApp: Actions.misc.initialiseApp,
	checkLogin: Actions.login.checkLogin,
}
export default connect(mapStateToProps, actions)(App)
