import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { v4 as uuid } from 'uuid'

import Actions from '../actions'

import MessageEntryElem from '../elements/MessageEntryElem'

type MessageEntryProps = {
	toTutor?: boolean
	groupId?: string
	placeholder?: string
	participantId?: string
	currAuthorId?: string
	participants?: StateTree['participants']

	viewingPreviousSession?: boolean
	allowAnyoneToSendMessages?: boolean

	onSendMessage?: (details: { message: string; id: string }) => void
	sendMessageToTutor: (messageText: string, id: string) => void
	sendMessageToAllGroups: (messageText: string, id: string) => void
	sendMessageToGroup: (groupId: string, messageText: string, id: string) => void
}

// =================================================================================================
const MessageEntry: FunctionComponent<MessageEntryProps> = props => {
	const sendMessage = (message: string) => {
		const { sendMessageToTutor, sendMessageToAllGroups, sendMessageToGroup } = props
		const { groupId, toTutor, onSendMessage } = props
		if (!message) return

		const id = uuid()

		if (toTutor) {
			sendMessageToTutor(message, id)
		} else if (groupId === 'all-groups') {
			sendMessageToAllGroups(message, id)
		} else {
			sendMessageToGroup(groupId, message, id)
		}
		if (onSendMessage) onSendMessage({ message, id })
	}

	const { placeholder, groupId, participantId, currAuthorId, participants } = props
	const { viewingPreviousSession, allowAnyoneToSendMessages } = props

	const userIsCurrentAuthor = currAuthorId && participantId && participantId === currAuthorId
	const currAuthor = currAuthorId && participants.find(p => p.id === currAuthorId)
	const currentAuthorIsOnline = currAuthor?.online

	// Participants are allowed to send messages if they are the current author, or the current author is offline, or
	// all participants are allowed to send messages (facilitator setting)
	const allowedToSendMessages = Boolean(userIsCurrentAuthor || !currentAuthorIsOnline || allowAnyoneToSendMessages)

	const enabled = Boolean(groupId && !viewingPreviousSession && allowedToSendMessages)

	return <MessageEntryElem enabled={enabled} initialValue="" placeholder={placeholder} onSubmit={sendMessage} />
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	const group = state.group || ({} as GroupDetails)
	const { allowAnyoneToSendMessages } = state.settings || {}
	return {
		viewingPreviousSession: state.viewingPreviousSession,
		participants: state.participants || [],
		participantId: group.participantId,
		currAuthorId: group.currAuthorId,
		allowAnyoneToSendMessages,
	}
}
const actions = {
	sendMessageToAllGroups: Actions.messages.sendMessageToAllGroups,
	sendMessageToGroup: Actions.messages.sendMessageToGroup,
	sendMessageToTutor: Actions.messages.sendMessageToTutor,
}
export default connect(mapStateToProps, actions)(MessageEntry)
