export default {
	/* Team view */
	OK: 'OK',
	CANCEL: 'Batal',
	TUTOR: 'Người điều hành',
	CURRENT_SESSION: 'Phiên hiện tại',
	TEAM: 'Đội',
	SIGNOUT: 'Thoát ra',
	INITIAL_UPDATE: 'Thông tin của phiên',
	ADVICE: 'Quyết định',
	RATIONALE: 'Lí do căn bản',
	MEDIA_FEED: 'Nguồn cấp dữ liệu phương tiện',
	MESSAGES: 'Các tin nhắn',
	PREVSESSIONS: 'Các phiên trước',
	RETURN_TO_CURRENT: 'Quay lại phiên hiện tại',
	SESSION_IS_PAUSED: 'Phiên hiện đang bị TẠM DỪNG',
	ATTRIB_CURR_AUTHOR: 'Người ra quyết định',
	SAVE: 'Lưu',
	CHANGE: 'Thay đổi',
	NO_AUTHOR: 'Không thiết lập',
	NO_INITIAL_INFO: 'Vui lòng chờ phiên tiếp theo bắt đầu',
	DECISION_MAKER_SELECT: 'Vui lòng chọn người ra quyết định',
	SESSION_COMPLETE: 'Phiên trước đã kết thúc',
	OPENED_AT: 'Đã mở lúc',
	SEND_MESSAGE_PLACEHOLDER: 'Viết tin nhắn',
	SEND: 'Gửi',
	TO_ALL_GROUPS: 'cho tất cả các nhóm',
	SHOW_TRANSLATION: 'Hiển thị bản dịch',
	SHOW_ORIGINAL: 'Hiển thị bản gốc',
	CREDENTIALS_EXPIRED: 'Mật mã của bạn đã hết hạn. Vui lòng đăng nhập lại',
	INCOMING_MESSAGE: 'Tin nhắn đến',
	INCOMING_MESSAGE_TEXT: 'Đã nhận một tin nhắn mới',
	INCOMING_MEDIA: 'Muc phương tiện đến',
	INCOMING_MEDIA_TEXT: 'Đã nhận một mục phương tiện mới',
	INCOMING_UPDATE: 'Bản cập nhật đến',
	INCOMING_UPDATE_TEXT: 'Đã nhận một bản cập nhật',
	CONFIRM_LOGOUT: 'Bạn có chắc chắn bạn muốn thoát?',
	MUTED_MESSAGE: 'Micrô của bạn đã tắt',
	NEWSTYPE_UPDATE: 'Cập nhật đã nhận',
	WARNING_TIME_MSG: 'Còn 5 phút nữa',
}
