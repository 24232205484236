/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */

import { connect } from 'react-redux'
import React, { FunctionComponent } from 'react'

import Actions from '../actions'

import Preloader from '../elements/Preloader'
import Button from '../elements/Button'
import Icon from '../elements/Icon'
import PhonePreloader from '../elements/PhonePreloader'
import Row from '../elements/Row'

// =================================================================================================

type MediaForPhoneModalProps = {
	displayModalPopup: (name: string, params?: SimpleObject) => void
	resendPhoneMessage: (scheduleRowId: string) => void
	markMediaAsSeen: (id: string) => void
	getMediaData: (id: string) => Promise<Blob>
	releases: StateTree['releases']
	media: MediaItem
	rowId: string
}

const PENDING_MESSAGE = ['Please check your phone.', ' ']

const SENT_MESSAGE = to => [
	`A voice message was sent to ${to}.`,
	`Click the RESEND button to have the message resent to your device.`,
]
const VOICEMAIL_MESSAGE = to => [
	`The voice message was sent to ${to} but went to your voicemail.`,
	`Click the RESEND button to have the message resent to your device.`,
]
const FAILED_MESSAGE = to => [
	`A voice message was sent to ${to} but did not get through.`,
	`Please check your phone and click the RESEND button.`,
]

const phoneLogStatusMap = {
	queued: 'pending',
	initiated: 'pending',
	ringing: 'pending',
	'in-progress': 'pending',
	completed: 'sent',
	'completed-human': 'sent',
	'completed-unknown': 'sent',
	busy: 'failed',
	'no-answer': 'failed',
	cancelled: 'failed',
	failed: 'failed',
	'completed-fax': 'failed',
	'completed-machine-start': 'voicemail',
}
const phoneLogStatusTitleMap = {
	queued: 'Incoming call',
	initiated: 'Incoming call',
	ringing: 'Your phone is ringing',
	'in-progress': 'Call in progress',
	completed: 'Phone message sent',
	'completed-human': 'Phone message sent',
	'completed-unknown': 'Phone message sent',
	busy: 'Call failed',
	'no-answer': 'No answer',
	cancelled: 'Call failed',
	failed: 'Call failed',
	'completed-fax': 'Call failed',
	'completed-machine-start': 'Call failed',
}

const MediaForPhoneModal: FunctionComponent<MediaForPhoneModalProps> = props => {
	const { displayModalPopup, media, releases, rowId, resendPhoneMessage } = props

	// Find phone log record
	const { phonelog } = releases.find(r => r.rowId === rowId)
	if (!phonelog) {
		return (
			<div className="media-phone-modal__main">
				<Preloader />
			</div>
		)
	}

	const date = new Date(phonelog?.statusTime)
	const time = Number.isNaN(date.getTime()) ? null : date.toLocaleTimeString()
	const toString = phonelog.to + (time ? ` at ${time}` : '')

	let message = PENDING_MESSAGE
	const shortStatus = phoneLogStatusMap[phonelog?.status]
	if (shortStatus === 'sent') message = SENT_MESSAGE(toString)
	if (shortStatus === 'failed') message = FAILED_MESSAGE(toString)
	if (shortStatus === 'voicemail') message = VOICEMAIL_MESSAGE(toString)

	const resendEnabled = shortStatus !== 'pending'
	const title = phoneLogStatusTitleMap[phonelog?.status] || 'Please wait'

	return (
		<div className="media-phone-modal__main">
			{/* <Icon name="phone" /> */}
			<PhonePreloader idle={shortStatus !== 'pending'} />
			{title ? <h2>{title}</h2> : null}

			{message.map(m => (
				<p>{m}</p>
			))}
			<Row>
				<Button primary text="Resend" onClick={() => resendPhoneMessage(rowId)} enabled={resendEnabled} />
				<Button secondary text="Play in browser" onClick={() => displayModalPopup('modal-media', media)} />
			</Row>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<MediaForPhoneModalProps> => ({
	releases: state.releases || [],
})
const actions: Partial<MediaForPhoneModalProps> = {
	resendPhoneMessage: Actions.sessions.resendPhoneMessage,
	displayModalPopup: Actions.misc.displayModalPopup,
	markMediaAsSeen: Actions.media.markMediaAsSeen,
}
export default connect(mapStateToProps, actions)(MediaForPhoneModal)
