import { connect } from 'react-redux'
import React from 'react'
import md5 from 'md5'

import SideCall from './SideCall'
import SideCallNew from './SideCallNew'

import config from '../../config'

const { VIDEO__NEW_COMPONENTS } = config

type ObserverBreakoutRoomProps = {
	domain: string
	instanceId: string
	facilitatorId: string
	displayName: string
}

// For an observer breakout room, we open up the SideCall component and use a call ID which is:
// md5(facilitatorId + server instance ID)
function ObserverBreakoutRoom(props: ObserverBreakoutRoomProps) {
	const { domain, instanceId, facilitatorId, displayName } = props
	const callId = md5(facilitatorId + instanceId)

	return VIDEO__NEW_COMPONENTS ? (
		<SideCallNew callId={callId} domain={domain} displayName={displayName} />
	) : (
		<SideCall callId={callId} domain={domain} displayName={displayName} />
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): ObserverBreakoutRoomProps => ({
	facilitatorId: state.linkedAccess?.facilitatorId || state.tutor.loggedInAs,
	displayName: state.linkedAccess?.name || config.strings.TUTOR,
	instanceId: state.videoconf?.instanceId,
	domain: state.videoconf?.domain,
})
const actions = {}
export default connect(mapStateToProps, actions)(ObserverBreakoutRoom)
