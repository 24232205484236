/* eslint-disable jsx-a11y/label-has-associated-control */
import { connect } from 'react-redux'
import React from 'react'

import ContentLoadingWrapper from '../components/ContentLoadingWrapper'
import BubbleHeading from '../elements/BubbleHeading'
import Preloader from '../elements/Preloader'
import Centered from '../components/Centered'
import Button from '../elements/Button'

import config from '../../config'
import Actions from '../actions'
import Passcode from '../elements/Passcode'

const { VIDEO__NEW_COMPONENTS } = config

class TutorObserverPasswords extends React.Component {
	componentDidMount() {
		const { getPasswords } = this.props
		getPasswords()
		// Fetch passwords at a regular interval
		this.interval = setInterval(getPasswords, 3000)
	}

	componentWillUnmount() {
		clearInterval(this.interval)
	}

	render() {
		const { props } = this
		const { passwords, loadingPasswords, creatingLinkPassword } = props
		const { generateNewPassword, deletePassword, copyPasswordToClipboard, updatePassword } = props
		const { GENERATE_NEW_PASSWORD, LINKEDACCESSPASSWORDS, CHANNEL_NAME, INTERPRETER } = config.strings

		// For some reason I got a crash that .map didn't exist... which doesn't
		// make sense as it should be at minimum an empty array, but oh well.
		const passwordArray = [...(passwords || [])]

		const content = passwordArray
			.sort((a, b) => (a.lastUpdate > b.lastUpdate ? -1 : 1))
			.map(password => (
				<Passcode
					key={password.key}
					password={password}
					deletePassword={deletePassword}
					copyPasswordToClipboard={copyPasswordToClipboard}
				>
					{VIDEO__NEW_COMPONENTS && (
						<div className="input row">
							<label>{INTERPRETER}</label>
							<input
								type="checkbox"
								checked={password.interpreter || false}
								onChange={e => updatePassword(password.key, { interpreter: e.target.checked })}
							/>
							{password.interpreter && (
								<input
									type="text"
									placeholder={CHANNEL_NAME}
									title={CHANNEL_NAME}
									required
									value={password.channel || ''}
									onChange={e => updatePassword(password.key, { channel: e.target.value })}
								/>
							)}
						</div>
					)}
				</Passcode>
			))

		return (
			<div className="tutor-linked-access-passwords">
				<div className="tutor-linked-access-passwords__top">
					<BubbleHeading>{LINKEDACCESSPASSWORDS}</BubbleHeading>
					<Button text={GENERATE_NEW_PASSWORD} onClick={generateNewPassword} />
				</div>
				<div className="tutor-linked-access-passwords__content">
					{creatingLinkPassword && (
						<Centered>
							<Preloader />
						</Centered>
					)}
					{!(creatingLinkPassword && passwordArray.length === 0) && (
						<ContentLoadingWrapper loading={loadingPasswords}>{content}</ContentLoadingWrapper>
					)}
				</div>
			</div>
		)
	}
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = state => ({
	creatingLinkPassword: state.creatingLinkPassword || false,
	loadingPasswords: state.loadingLinkPasswords || false,
	passwords: state.linkPasswords || [],
})
const actions = {
	getPasswords: Actions.linkedAccess.getPasswords,
	deletePassword: Actions.linkedAccess.deletePassword,
	generateNewPassword: Actions.linkedAccess.generatePassword,
	copyPasswordToClipboard: Actions.passwords.copyPasswordToClipboard,
	updatePassword: Actions.linkedAccess.updatePassword,
}
export default connect(mapStateToProps, actions)(TutorObserverPasswords)
