/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { connect } from 'react-redux'
import React, { FocusEvent } from 'react'

import Actions from '../actions'
import Button from '../elements/Button'
import config from '../../config'
import ConfirmationHelper from '../util/ConfirmationHelper'
import { sessionIsActive } from '../util/SessionHelpers'

import ReleaseSchedule from './ReleaseSchedule'
import Textarea from '../elements/Textarea'

const MAX_SESSION_DURATION = config.MAX_SESSION_DURATION || 120
const MIN_SESSION_DURATION = config.MIN_SESSION_DURATION || 1

type SessionProps = {
	className: string
	data: Session
	licence: Licence
	currentSession: CurrentSession
	updateSession: (session: Partial<Session>) => void
	updateReleaseScheduleRow: typeof Actions.sessions.updateReleaseScheduleRow
	endSession: typeof Actions.sessions.endSession
	startSession: typeof Actions.sessions.startSession
	pauseSession: typeof Actions.sessions.pauseSession
	resumeSession: typeof Actions.sessions.resumeSession
	addReleaseRow: typeof Actions.sessions.addReleaseRow
	deleteSession: typeof Actions.sessions.deleteSession
}

type SessionState = {
	duration: number
	initialUpdate: string
	showDurationWarning: boolean
}

// This is called SessionComponent to not be confused with the Session data type
class SessionComponent extends React.Component<SessionProps, SessionState> {
	element: HTMLElement
	waitingForNewReleaseRow: number

	constructor(props: SessionProps) {
		super(props)
		const data = props.data || ({} as Session)

		this.state = {
			duration: data.duration || 0,
			initialUpdate: data.initialUpdate || '',
			showDurationWarning: false,
		}
	}

	componentDidUpdate() {
		// If the user clicked the 'Add Release Row' button, and we were waiting for it to appear,
		// and the scroll height has increased (i.e. the row has appeared) then
		// scroll down a bit so that the row and the button are still visible
		const parent = this.element.parentElement
		if (this.waitingForNewReleaseRow && this.element && this.waitingForNewReleaseRow < parent.scrollHeight) {
			const prevScrollHeight = this.waitingForNewReleaseRow || 0
			parent.scrollTop += parent.scrollHeight - prevScrollHeight
			this.waitingForNewReleaseRow = 0
		}
	}

	_onAnyFieldChange(name: string, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
		// eslint-disable-next-line prefer-destructuring
		let value: number | string = e.currentTarget.value

		if (name === 'duration') {
			value = parseInt(value) || '' // Allow user to type in blank value while they are editing it
			if (value > MAX_SESSION_DURATION) {
				value = MAX_SESSION_DURATION
				this.setState({ showDurationWarning: true })
			}
			if (value && value < MIN_SESSION_DURATION) value = MIN_SESSION_DURATION
		}

		const data = {}
		data[name] = value
		this.setState(data)

		if (name === 'duration' && !value) return

		this._dispatchUpdate(data)
	}

	_onDurationBlur(e: FocusEvent<HTMLInputElement>) {
		// Check that duration is numeric and within acceptable range
		const { value } = e.currentTarget
		let newValue = parseInt(value) || 1
		if (newValue > MAX_SESSION_DURATION) newValue = MAX_SESSION_DURATION
		if (newValue < MIN_SESSION_DURATION) newValue = MIN_SESSION_DURATION
		if (newValue !== parseInt(value)) {
			const data = { duration: newValue }
			this.setState(data)
			this._dispatchUpdate(data)
		}
	}

	_dispatchUpdate(newData: Partial<Session>) {
		const { data, updateSession } = this.props
		const sessUpdate = { ...data, ...this.state, ...newData }
		updateSession(sessUpdate)
	}

	_onClickStartSession() {
		const { data, startSession, licence } = this.props
		const remaining = licence.remaining_sessions_per_year
		const { CONFIRM_BEGIN_SESSION, CONFIRM_BEGIN_SESSION_MESSAGE } = config.strings

		ConfirmationHelper.confirm(
			CONFIRM_BEGIN_SESSION,
			() => startSession(data.id),
			remaining && CONFIRM_BEGIN_SESSION_MESSAGE(remaining)
		)
	}

	_onClickAddReleaseRow() {
		const { addReleaseRow, data } = this.props
		this.waitingForNewReleaseRow = this.element.parentElement.scrollHeight

		const sessionId = data.id
		const schedule = data.schedule || []

		const newIndex = schedule.length ? Math.max(...schedule.map(r => r.index)) + 1 : 0
		addReleaseRow(sessionId, newIndex)
	}

	render() {
		const { SESSION_IS_PAUSED, SESSION_IS_ACTIVE, SESSION_TIMEFRAME, RELEASE_SCHEDULE } = config.strings
		const { ADD_RELEASE_ROW, INITIAL_UPDATE, DURATION, RESUME, DELETE, PAUSE, STOP, RUN } = config.strings
		const { RESUME_CONFIRM, PAUSE_CONFIRM, CONFIRM_DELETE_SESSION, CONFIRM_END_SESSION } = config.strings

		const SESSION_DURATION_MAX_WARNING = config.strings.SESSION_DURATION_MAX_WARNING(MAX_SESSION_DURATION)
		const SESSION_DURATION_MIN_WARNING = config.strings.SESSION_DURATION_MIN_WARNING(MIN_SESSION_DURATION)

		const { duration, initialUpdate } = this.state
		const { updateReleaseScheduleRow, currentSession, data, className = '' } = this.props
		const { pauseSession, resumeSession, deleteSession, endSession } = this.props
		const sessionId = data.id
		const { schedule } = data

		const isCurrentSession = currentSession.timestamp === data.timestamp || currentSession.sessionId === sessionId

		const isCurrentSessionActive = sessionIsActive(currentSession)
		const isTheActiveSession = isCurrentSession && isCurrentSessionActive
		const isCurrentSessionPaused = isCurrentSessionActive && currentSession.paused

		const allowStop = isTheActiveSession
		const allowRun = !isCurrentSessionActive
		const allowDelete = !isCurrentSessionActive
		const allowPause = isTheActiveSession && !currentSession.paused
		const allowResume = isCurrentSessionPaused

		let durationWarning = ''
		if (duration >= MAX_SESSION_DURATION) {
			durationWarning = SESSION_DURATION_MAX_WARNING
		}
		if (duration < MIN_SESSION_DURATION || !duration) {
			durationWarning = SESSION_DURATION_MIN_WARNING
		}

		let { showDurationWarning } = this.state
		if (!durationWarning) showDurationWarning = false

		const message =
			(isCurrentSessionPaused && <div className="msg">{SESSION_IS_PAUSED}</div>) ||
			(isTheActiveSession && <div className="msg">{SESSION_IS_ACTIVE}</div>)

		const onClickPause = () => ConfirmationHelper.confirm(PAUSE_CONFIRM, () => pauseSession())
		const onClickResume = () => ConfirmationHelper.confirm(RESUME_CONFIRM, () => resumeSession())
		const onClickDelete = () => ConfirmationHelper.confirm(CONFIRM_DELETE_SESSION, () => deleteSession(sessionId))
		const onClickStop = () => ConfirmationHelper.confirm(CONFIRM_END_SESSION, () => endSession(sessionId))
		const onClickStartSession = () => this._onClickStartSession()

		const onBlurDuration = this._onDurationBlur
		const onChangeDuration = e => this._onAnyFieldChange('duration', e)
		const onChangeInitialUpdate = e => this._onAnyFieldChange('initialUpdate', e)

		return (
			<div key={sessionId} className={`session ${className}`} ref={ref => (this.element = ref)}>
				{message}
				<div className="top">
					<div className="left">
						<div className="row">
							<label>{INITIAL_UPDATE}</label>
							<div className="row-spacer" />
							{showDurationWarning && <div className="session__duration-warning">{durationWarning}</div>}
							<div className="session__duration">
								<label>{DURATION}</label>
								<input type="number" value={duration} onBlur={onBlurDuration} onChange={onChangeDuration} />
								<span>{SESSION_TIMEFRAME}</span>
							</div>
						</div>
						<Textarea value={initialUpdate} onChange={onChangeInitialUpdate} />
					</div>
					<div className="session__buttons">
						<Button text={RUN} enabled={allowRun} onClick={onClickStartSession} primary />
						{allowPause && <Button text={PAUSE} onClick={onClickPause} />}
						{allowResume && <Button text={RESUME} onClick={onClickResume} />}
						<Button text={STOP} enabled={allowStop} onClick={onClickStop} primary />
						<Button text={DELETE} enabled={allowDelete} onClick={onClickDelete} />
					</div>
				</div>
				<div className="releaseSchedule">
					<label>{RELEASE_SCHEDULE}</label>
					<ReleaseSchedule schedule={schedule} onRowChange={updateReleaseScheduleRow} sessionId={sessionId} />
				</div>
				<div className="row centred">
					<Button text={ADD_RELEASE_ROW} onClick={() => this._onClickAddReleaseRow()} />
				</div>
			</div>
		)
	}
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<SessionProps> => ({
	currentSession: state.currentSession || ({} as CurrentSession),
	licence: state.licence || ({} as Licence),
})
const actions = {
	updateReleaseScheduleRow: Actions.sessions.updateReleaseScheduleRow,
	addReleaseRow: Actions.sessions.addReleaseRow,
	deleteSession: Actions.sessions.deleteSession,
	resumeSession: Actions.sessions.resumeSession,
	updateSession: Actions.sessions.updateSession,
	pauseSession: Actions.sessions.pauseSession,
	startSession: Actions.sessions.startSession,
	endSession: Actions.sessions.endSession,
}
export default connect(mapStateToProps, actions)(SessionComponent)
