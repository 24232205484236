/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { FunctionComponent, MouseEventHandler } from 'react'

import Icon from './Icon'

type IconButtonProps = {
	selected?: boolean
	onClick?: MouseEventHandler
	title?: string
	iconName: string
	showBadge?: boolean
	className?: string
}

const IconButton: FunctionComponent<IconButtonProps> = props => {
	const { selected, onClick, title, iconName, showBadge, className } = props
	let _className = 'icon-button'
	if (selected) {
		_className += ' icon-button--selected'
	}
	if (className) {
		_className += ` ${className}`
	}
	const _onClick: MouseEventHandler = e => {
		if (onClick) {
			e.stopPropagation()
			onClick(e)
		}
	}
	return (
		<div className={_className} onClick={_onClick} title={title}>
			<Icon name={iconName} />
			{showBadge && <div className="badge" />}
		</div>
	)
}

IconButton.defaultProps = {
	iconName: '',
	className: '',
	showBadge: false,
	selected: false,
	onClick: null,
	title: null,
}

export default IconButton
