const PASSWORDS_LOADING: Reducer = state => ({ ...state, loadingPasswords: true })

const PASSWORDS_LOADING_FAILED: Reducer = state => ({ ...state, loadingPasswords: false })

const PASSWORDS_LOADED: Reducer = (state, action) => ({ ...state, passwords: action.payload, loadingPasswords: false })

const PASSWORD_CREATED: Reducer = (state, action) => ({ ...state, passwords: [...state.passwords, action.payload] })

const PASSWORD_DELETED: Reducer = (state, action) => {
	const key = action.payload
	const passwords = [...state.passwords.filter(p => p.key !== key)]
	return { ...state, passwords }
}

// =================================================================================================

const reducers = {
	PASSWORDS_LOADING,
	PASSWORDS_LOADING_FAILED,
	PASSWORDS_LOADED,
	PASSWORD_CREATED,
	PASSWORD_DELETED,
}

// =================================================================================================

const switchcase: Reducer = (state, action) => {
	let newState = state
	if (reducers[action.type]) {
		newState = reducers[action.type](state, action)
	}
	return newState
}

export default switchcase
