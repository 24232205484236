export default {
	/* Team view */
	OK: 'OK',
	CANCEL: 'Poništi',
	TUTOR: 'Vođa',
	CURRENT_SESSION: 'Trenutna sesija',
	TEAM: 'Tim',
	SIGNOUT: 'Provjeri',
	INITIAL_UPDATE: 'Informacije o dodjeli',
	ADVICE: 'Odluka',
	RATIONALE: 'Opravdanje',
	MEDIA_FEED: 'Informacije primljene',
	MESSAGES: 'Poruke',
	PREVSESSIONS: 'Prethodni',
	RETURN_TO_CURRENT: 'Povratak na trenutnu sesiju',
	SESSION_IS_PAUSED: 'Sesija je trenutno pauzirana',
	ATTRIB_CURR_AUTHOR: 'Donositelj odluka',
	SAVE: 'Spremi',
	CHANGE: 'Promijeniti',
	NO_AUTHOR: 'Nespreman',
	NO_INITIAL_INFO: 'Pričekajte da započne sljedeća sesija',
	DECISION_MAKER_SELECT: 'Odaberite donositelja odluke',
	SESSION_COMPLETE: 'Prethodna sesija je završena',
	OPENED_AT: 'Otvori u',
	SEND_MESSAGE_PLACEHOLDER: 'Napišite poruku',
	SEND: 'Poslati',
	TO_ALL_GROUPS: 'Svim skupinama',
	SHOW_TRANSLATION: 'Prikaži prijevod',
	SHOW_ORIGINAL: 'Prikaži original',
	CREDENTIALS_EXPIRED: 'Vaša je lozinka istekla. Molimo prijavite se ponovo',
	INCOMING_MESSAGE: 'Dolazna poruka',
	INCOMING_MESSAGE_TEXT: 'Primljena je nova poruka',
	INCOMING_MEDIA: 'Dolazna medijska stavka',
	INCOMING_MEDIA_TEXT: 'Primljena je nova medijska stavka',
	INCOMING_UPDATE: 'Dolazno ažuriranje',
	INCOMING_UPDATE_TEXT: 'Ažuriranje primljeno',
	CONFIRM_LOGOUT: 'Jeste li sigurni da se želite odjaviti?',
	MUTED_MESSAGE: 'Mikrofon je isključen',
	NEWSTYPE_UPDATE: 'Ažuriranje primljeno',
	WARNING_TIME_MSG: 'Preostalo je 5 minuta',
}
