const types = {
	CASES_START_LOAD_CASELIST: 'CASES_START_LOAD_CASELIST',
	CASES_LOAD_CASELIST_ERROR: 'CASES_LOAD_CASELIST_ERROR',
	CASES_UPDATE_CASEFILE: 'CASES_UPDATE_CASEFILE',
	CASES_LOAD_CASELIST: 'CASES_LOAD_CASELIST',
	CASES_DELETE_CASE: 'CASES_DELETE_CASE',
	CASES_LOAD_CASE: 'CASES_LOAD_CASE',

	CASES_ADD_NEW_SUCCESS: 'CASES_ADD_NEW_SUCCESS',
	CASES_ADD_NEW_FAIL: 'CASES_ADD_NEW_FAIL',
	CASES_ADDING_NEW: 'CASES_ADDING_NEW',

	CASES_UPDATING_CASE: 'CASES_UPDATING_CASE',
	CASES_UPDATED_CASE: 'CASES_UPDATED_CASE',
	CASES_UPDATE_FAIL: 'CASES_UPDATE_FAIL',

	CASES_GOT_OPEN_CASE: 'CASES_GOT_OPEN_CASE',

	CLEAR_ERRORS: 'CLEAR_ERRORS',
	ACTION_ERROR: 'ACTION_ERROR',

	LOGGED_IN_SUCCESS_TUTOR: 'LOGGED_IN_SUCCESS_TUTOR',
	LOGGED_IN_FAIL_TUTOR: 'LOGGED_IN_FAIL_TUTOR',
	STARTED_LOGGING_IN: 'STARTED_LOGGING_IN',

	STARTED_GROUP_LOGIN: 'STARTED_GROUP_LOGIN',
	LOGGED_IN_FAIL_GROUP: 'LOGGED_IN_FAIL_GROUP',
	LOGGED_IN_SUCCESS_GROUP: 'LOGGED_IN_SUCCESS_GROUP',

	STARTED_PARTICIPANT_LOGIN: 'STARTED_PARTICIPANT_LOGIN',
	LOGGED_IN_FAIL_PARTICIPANT: 'LOGGED_IN_FAIL_PARTICIPANT',
	LOGGED_IN_SUCCESS_PARTICIPANT: 'LOGGED_IN_SUCCESS_PARTICIPANT',

	PARTICIPANT_PHONE_UPDATED: 'PARTICIPANT_PHONE_UPDATED',
	PHONE_MESSAGE_RESENT: 'PHONE_MESSAGE_RESENT',

	TUTOR_GOT_LICENCE: 'TUTOR_GOT_LICENCE',

	GROUP_SIGN_OUT: 'GROUP_SIGN_OUT',
	GROUP_KICKED: 'GROUP_KICKED',

	LOGIN_TOGGLE_OFFLINE: 'LOGIN_TOGGLE_OFFLINE',
	LOGIN_GO_ONLINE: 'LOGIN_GO_ONLINE',

	MEDIA_ADD_NEW_SUCCESS: 'MEDIA_ADD_NEW_SUCCESS',
	MEDIA_MARK_AS_SEEN: 'MEDIA_MARK_AS_SEEN',
	MEDIA_DELETE: 'MEDIA_DELETE',
	MEDIA_UPDATED: 'MEDIA_UPDATED',
	MEDIA_LOADING_ITEMS: 'MEDIA_LOADING_ITEMS',
	MEDIA_LOADED_ITEMS: 'MEDIA_LOADED_ITEMS',

	NAVIGATING_TO: 'NAVIGATING_TO',
	NAVIGATING_TO_MODAL: 'NAVIGATING_TO_MODAL',

	SESSIONS_ADD_RELEASE_ROW_SUCCESS: 'SESSIONS_ADD_RELEASE_ROW_SUCCESS',
	SESSIONS_REMOVE_RELEASE_ROW: 'SESSIONS_REMOVE_RELEASE_ROW',

	SESSIONS_ADD_NEW_SUCCESS: 'SESSIONS_ADD_NEW_SUCCESS',
	SESSIONS_ADD_NEW_FAIL: 'SESSIONS_ADD_NEW_FAIL',
	SESSIONS_UPDATE_SUCCESS: 'SESSIONS_UPDATE_SUCCESS',
	SESSIONS_UPDATE_GROUP_LOG: 'SESSIONS_UPDATE_GROUP_LOG',
	SESSIONS_DELETE: 'SESSIONS_DELETE',
	SESSIONS_RESUME: 'SESSIONS_RESUME',
	SESSIONS_PAUSE: 'SESSIONS_PAUSE',
	SESSIONS_START: 'SESSIONS_START',
	SESSIONS_END: 'SESSIONS_END',
	SESSIONS_STORED: 'SESSIONS_STORED',
	SESSIONS_RETURN_TO_CURRENT: 'SESSIONS_RETURN_TO_CURRENT',
	SESSIONS_GOT_CURRENT_SESSION: 'SESSIONS_GOT_CURRENT_SESSION',

	SESSION_SELECT: 'SESSION_SELECT',
	SESSION_RELEASE_NOW: 'SESSION_RELEASE_NOW',
	SESSION_RELEASE_TO_GROUP: 'SESSION_RELEASE_TO_GROUP',
	SESSION_CURR_AUTHOR_CHANGED: 'SESSION_CURR_AUTHOR_CHANGED',

	SESSIONS_PREV_RETRIEVED: 'SESSIONS_PREV_RETRIEVED',
	SESSIONS_PREV_LOADING: 'SESSIONS_PREV_LOADING',
	SESSIONS_PREV_LOADING_LIST: 'SESSIONS_PREV_LOADING_LIST',
	SESSIONS_PREV_LOADING_LIST_FAILED: 'SESSIONS_PREV_LOADING_LIST_FAILED',
	SESSIONS_PREV_LOADED: 'SESSIONS_PREV_LOADED',
	SESSIONS_PREV_DELETED: 'SESSIONS_PREV_DELETED',

	SESSIONS_GOT_OPENINGS: 'SESSIONS_GOT_OPENINGS',
	SESSIONS_GOT_RELEASES: 'SESSIONS_GOT_RELEASES',

	TUTOR_LOGIN_SUCCESS: 'TUTOR_LOGIN_SUCCESS',
	TUTOR_LOGIN_ERROR: 'TUTOR_LOGIN_ERROR',
	TUTOR_LOGIN_START: 'TUTOR_LOGIN_START',
	TUTOR_SIGN_OUT: 'TUTOR_SIGN_OUT',

	GROUP_STORE_DECISIONLOG: 'GROUP_STORE_DECISIONLOG',
	GROUP_GENERATED_ID: 'GROUP_GENERATED_ID',
	GROUP_LOG_BACK_IN: 'GROUP_LOG_BACK_IN',

	GOT_SERVER_TIME_DIFF: 'GOT_SERVER_TIME_DIFF',

	GROUPS_FETCHING_DECISION_LOG: 'GROUPS_FETCHING_DECISION_LOG',
	GROUPS_FETCHED_DECISION_LOG: 'GROUPS_FETCHED_DECISION_LOG',
	GROUPS_TRANSLATED_DECISION_LOG: 'GROUPS_TRANSLATED_DECISION_LOG',

	GROUPS_FETCH_DATA_SUCCESS: 'GROUPS_FETCH_DATA_SUCCESS',
	GROUP_UPDATE_DECISION_LOG: 'GROUP_UPDATE_DECISION_LOG',
	GROUPS_GOT_DECISION_LOG: 'GROUPS_GOT_DECISION_LOG',
	GROUP_GOT_LOG_TRANSLATION: 'GROUP_GOT_LOG_TRANSLATION',
	GROUPS_CHANGED_LANGUAGE: 'GROUPS_CHANGED_LANGUAGE',
	GROUP_OPENED_ITEM: 'GROUP_OPENED_ITEM',

	GROUPS_LIST_LOADED: 'GROUPS_LIST_LOADED',

	MESSAGE_SENT_TO_ALL_GROUPS: 'MESSAGE_SENT_TO_ALL_GROUPS',
	MESSAGE_SENT_TO_TUTOR: 'MESSAGE_SENT_TO_TUTOR',
	MESSAGE_SENT_TO_GROUP: 'MESSAGE_SENT_TO_GROUP',
	MESSAGE_MARK_AS_READ: 'MESSAGE_MARK_AS_READ',

	MESSAGE_TRANSLATED_FACILITATOR: 'MESSAGE_TRANSLATED_FACILITATOR',
	MESSAGE_TRANSLATED_ALLGROUPS: 'MESSAGE_TRANSLATED_ALLGROUPS',
	MESSAGE_TRANSLATED: 'MESSAGE_TRANSLATED',

	TEXT_TRANSLATED: 'TEXT_TRANSLATED',

	MESSAGES_LOADING_ALL_CONVERSATIONS: 'MESSAGES_LOADING_ALL_CONVERSATIONS',
	MESSAGES_FAILED_ALL_CONVERSATIONS: 'MESSAGES_FAILED_ALL_CONVERSATIONS',
	MESSAGES_GOT_ALL_CONVERSATIONS: 'MESSAGES_GOT_ALL_CONVERSATIONS',
	MESSAGES_CLEAR_RECENT_FLAG: 'MESSAGES_CLEAR_RECENT_FLAG',
	MESSAGES_GOT_CONVERSATION: 'MESSAGES_GOT_CONVERSATION',

	GOT_IP_ADDRESS: 'GOT_IP_ADDRESS',

	PASSWORDS_LOADING_FAILED: 'PASSWORDS_LOADING_FAILED',
	PASSWORDS_LOADING: 'PASSWORDS_LOADING',
	PASSWORD_CREATING: 'PASSWORD_CREATING',
	PASSWORDS_LOADED: 'PASSWORDS_LOADED',
	PASSWORD_CREATED: 'PASSWORD_CREATED',
	PASSWORD_DELETED: 'PASSWORD_DELETED',

	OBSERVER_DISCONNECTED: 'OBSERVER_DISCONNECTED',

	OBSERVER_CONNECTING: 'OBSERVER_CONNECTING',
	OBSERVER_CONNECTED: 'OBSERVER_CONNECTED',
	OBSERVER_FAILED: 'OBSERVER_FAILED',

	OBSERVER_WHITELIST_UPDATED: 'OBSERVER_WHITELIST_UPDATED',
	OBSERVER_ENABLEMENT_UPDATED: 'OBSERVER_ENABLEMENT_UPDATED',

	OBSERVER_PASSWORDS_LOADING: 'OBSERVER_PASSWORDS_LOADING',
	OBSERVER_PASSWORDS_LOADED: 'OBSERVER_PASSWORDS_LOADED',
	OBSERVER_PASSWORD_CREATE_FAILED: 'OBSERVER_PASSWORD_CREATE_FAILED',
	OBSERVER_PASSWORD_CREATING: 'OBSERVER_PASSWORD_CREATING',
	OBSERVER_PASSWORD_CREATED: 'OBSERVER_PASSWORD_CREATED',
	OBSERVER_PASSWORD_DELETED: 'OBSERVER_PASSWORD_DELETED',
	OBSERVER_PASSWORD_UPDATED: 'OBSERVER_PASSWORD_UPDATED',

	SETTINGS_UPDATED: 'SETTINGS_UPDATED',
	SETTINGS_LOADED: 'SETTINGS_LOADED',

	SHOW_WAITING_ROOM_MESSAGE: 'SHOW_WAITING_ROOM_MESSAGE',

	SOCKET_MAIN_CALL_STARTED: 'SOCKET_MAIN_CALL_STARTED',
	SOCKET_MAIN_CALL_ENDED: 'SOCKET_MAIN_CALL_ENDED',
	SOCKET_GROUP_CONNECTED: 'SOCKET_GROUP_CONNECTED',
	SOCKET_GROUP_DISCONNECTED: 'SOCKET_GROUP_DISCONNECTED',
	SOCKET_FACILITATOR_LOGIN_OK: 'SOCKET_FACILITATOR_LOGIN_OK',
	SOCKET_BREAKOUT_ROOMS_ACTIVATED: 'SOCKET_BREAKOUT_ROOMS_ACTIVATED',
	SOCKET_BREAKOUT_ROOMS_DEACTIVATED: 'SOCKET_BREAKOUT_ROOMS_DEACTIVATED',
	SOCKET_OBSERVER_BREAKOUT_ACTIVATED: 'SOCKET_OBSERVER_BREAKOUT_ACTIVATED',
	SOCKET_OBSERVER_BREAKOUT_DEACTIVATED: 'SOCKET_OBSERVER_BREAKOUT_DEACTIVATED',
	SOCKET_CHAT_OPENED: 'SOCKET_CHAT_OPENED',
	SOCKET_CHAT_CLOSED: 'SOCKET_CHAT_CLOSED',
	SOCKET_PARTICIPANT_COLOUR_CHANGE: 'SOCKET_PARTICIPANT_COLOUR_CHANGE',
	SOCKET_PHONELOGUPDATE: 'SOCKET_PHONELOGUPDATE',

	PARTICIPANTS_UPDATED_COLOUR: 'PARTICIPANTS_UPDATED_COLOUR',
	PARTICIPANTS_GOT_LIST: 'PARTICIPANTS_GOT_LIST',

	VIDEOCONF_GOT_SERVER_STATUS: 'VIDEOCONF_GOT_SERVER_STATUS',
	VIDEOCONF_GOT_SERVER_REGIONS: 'VIDEOCONF_GOT_SERVER_REGIONS',
	VIDEOCONF_SERVER_STARTING: 'VIDEOCONF_SERVER_STARTING',
	VIDEOCONF_SELECT_REGION: 'VIDEOCONF_SELECT_REGION',
	VIDEOCONF_SERVER_STOPPING: 'VIDEOCONF_SERVER_STOPPING',
	VIDEOCONF_JOINED_BREAKOUT: 'VIDEOCONF_JOINED_BREAKOUT',
	VIDEOCONF_LEFT_BREAKOUT: 'VIDEOCONF_LEFT_BREAKOUT',
	VIDEOCONF_CHANGED_CAMERA: 'VIDEOCONF_CHANGED_CAMERA',
	VIDEOCONF_CHANGED_MIC: 'VIDEOCONF_CHANGED_MIC',
	VIDEOCONF_CHANGED_AUDIO_OUT: 'VIDEOCONF_CHANGED_AUDIO_OUT',
	VIDEOCONF_CHANGED_INTERPRETER: 'VIDEOCONF_CHANGED_INTERPRETER',
	VIDEOCONF_INTERPRETER_CHANGED_CHANNEL: 'VIDEOCONF_INTERPRETER_CHANGED_CHANNEL',
	VIDEOCONF_CHAT_TOGGLED: 'VIDEOCONF_CHAT_TOGGLED',

	CHAT_MESSAGE_RECEIVED: 'CHAT_MESSAGE_RECEIVED',
	CHAT_MESSAGE_SENT: 'CHAT_MESSAGE_SENT',
}

export default types
