/* eslint-disable camelcase */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-continue */
import Sentry from '@sentry/browser'

import loggedInAsObserver from '../selectors/loggedInAsObserver'
import sessionSelector from '../selectors/session'
import groupsSelector from '../selectors/groups'
import OnlineComms, { SendMessageBody } from '../core/OnlineComms'
import getTime from '../util/getTime'
import action from '../util/action'
import types from './types'

const FACILITATOR_LANGUAGE = 'EN'

// =================================================================================================

const sendMessageToGroup: ActionCreator = (groupId: string, message: string) => async (dispatch, getState) => {
	if (!message) return

	const payload = { message, tutor: true, observerId: null, observerName: null }

	const state = getState()
	if (loggedInAsObserver(state)) {
		payload.observerId = state.clientId
		payload.observerName = state.linkedAccess.name
	}

	const obj = await OnlineComms.sendMessage(groupId, payload)
	dispatch(action(types.MESSAGE_SENT_TO_GROUP, obj))
}

// =================================================================================================

const sendMessageToTutor: ActionCreator = (message: string, id: string) => async (dispatch, getState) => {
	if (!message) return
	const { group } = getState()
	if (!group) return
	// Send group ID
	const groupId = group.participantId ? group.colour : group.id
	const data: SendMessageBody = { groupId, message, id }
	// Send participant ID
	if (group.participantId) {
		data.participantId = group.participantId
	}

	try {
		const msg = await OnlineComms.sendMessage(groupId, data)
		dispatch(action(types.MESSAGE_SENT_TO_TUTOR, msg))
	} catch (err) {
		console.error(err)
		Sentry.captureException(err)
	}
}

// =================================================================================================

const markConversationAsRead: ActionCreator = groupId => dispatch => {
	dispatch(action(types.MESSAGE_MARK_AS_READ, { groupId }))
}

// =================================================================================================

const sendMessageToAllGroups: ActionCreator = message => dispatch => {
	if (!message) return
	// Send message to {url}/groups/all/messages
	OnlineComms.sendMessage('all', { message, tutor: true }).then(obj => {
		dispatch(action(types.MESSAGE_SENT_TO_ALL_GROUPS, obj))
	})
}

// =================================================================================================

const getAllConversations: ActionCreator = () => (dispatch, getState) => {
	const state = getState()
	if (!state.loading || !state.loading.allConversations) {
		dispatch(action(types.MESSAGES_LOADING_ALL_CONVERSATIONS))
		OnlineComms.getAllConversations()
			.then(conversations => {
				dispatch(action(types.MESSAGES_GOT_ALL_CONVERSATIONS, conversations))
			})
			.catch(err => {
				console.error('getAllConversations err: ', err)
				dispatch(action(types.MESSAGES_FAILED_ALL_CONVERSATIONS))
			})
	}
}

// =================================================================================================

const getConversation: ActionCreator = () => async (dispatch, getState) => {
	const state = getState()

	// Get current group ID
	const groupId = state?.group?.id
	if (!groupId) return

	// Get timestamp of the last known message in the conversation
	const conversation = state?.groupData?.conversation || []
	conversation.sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1))
	const lastMessage = conversation[0]
	const startFrom = (lastMessage && lastMessage.timestamp) || 0

	try {
		const messages = await OnlineComms.getMessages(groupId, startFrom)
		dispatch(action(types.MESSAGES_GOT_CONVERSATION, messages))
		checkForMessagesThatNeedTranslation()(dispatch, getState)
	} catch (err) {
		console.error(err)
	}
}

// =================================================================================================
// This action will clear a group's 'recent message' flag in the state if there
// has not been a message from the tutor in the last 20 seconds
const clearRecentFlag: ActionCreator = () => (dispatch, getState) => {
	const state = getState()
	const now = getTime()
	const twentySecsAgo = now - 20 * 1000
	const groupData = state.groupData || {}
	const convo = groupData.conversation || []
	const messagesFromTutor = convo.filter(m => m.tutor)
	const recentMessage = messagesFromTutor.find(m => parseInt(m.timestamp || '0') > twentySecsAgo)
	if (!recentMessage) {
		dispatch(action(types.MESSAGES_CLEAR_RECENT_FLAG))
	}
}

// =================================================================================================
// Message translations
const checkForMessagesThatNeedTranslation: ActionCreator = () => async (dispatch, getState) => {
	const state = getState()

	if (state.groupData) {
		checkForMessagesThatNeedTranslation_group()(dispatch, getState)
		return
	}
	checkForMessagesThatNeedTranslation_tutor()(dispatch, getState)
}

// =================================================================================================

const checkForMessagesThatNeedTranslation_tutor: ActionCreator = () => async (dispatch, getState) => {
	const state = getState()

	// Get all conversations in current/previousSession
	const session = sessionSelector(state)
	const groups = groupsSelector(state)
	const conversations = session.conversations || {}
	const groupIds = Object.keys(conversations)

	// For each group, get the conversation and language of that group
	for (let k = 0; k < groupIds.length; k++) {
		const groupId = groupIds[k]
		const group = groups.find(g => g.id === groupId) || ({} as GroupDetails)
		const groupLanguage = group.language
		if (!groupLanguage || groupLanguage === FACILITATOR_LANGUAGE) continue

		let conversation = conversations[groupId] || []

		// Filter out messages that have been translated or were sent by the tutor
		// Limit list to a max of 20 messages to send to the server
		conversation = conversation.filter(m => !m.translated && !m.tutor && m.message).slice(0, 20)

		// Send messages to server to translate
		const fromLang = groupLanguage
		const toLang = FACILITATOR_LANGUAGE
		const msgText = conversation.map(m => m.message).filter(txt => Boolean(txt))
		if (!msgText.length) continue
		const translated = await OnlineComms.translate(fromLang, toLang, msgText)
		translated.forEach((translation, i) => {
			dispatch(action(types.MESSAGE_TRANSLATED, { id: conversation[i].key, text: translation }))
		})
	}
}

// =================================================================================================

const checkForMessagesThatNeedTranslation_group: ActionCreator = () => async (dispatch, getState) => {
	// Firstly we are going to check for any messages from the facilitator that need translating into
	// the groups native language.
	const state = getState()

	// Get group language
	const group = state.group || ({} as GroupDetails)
	const toLang = group.language || 'EN'

	const conversation = (state.groupData || {}).conversation || []
	const session = sessionSelector(state) || ({} as CurrentSession)
	const allGroupMessages = session.allGroupMessages || []
	const groupId = group.participantId ? group.colour : group.id
	const sessionConversations = session.conversations || {}
	const prevSessionConversation = sessionConversations[groupId] || []

	let allMsgs = [...conversation, ...allGroupMessages, ...prevSessionConversation]

	// Filter out messages that have been translated already, or are blank
	allMsgs = allMsgs.filter(m => !m.translated && m.message)

	for (let i = 0; i < allMsgs.length; i++) {
		const msg = allMsgs[i]

		// If the message is from tutor, we are translating from the tutor's language into our own
		let fromLang = FACILITATOR_LANGUAGE
		// If message is from another participant on same time, we translate from their language to our own
		if (msg.participantId) {
			const participant = (state.participants || []).find(p => p.id === msg.participantId)
			// Skip message if participant not found
			if (!participant) continue
			fromLang = participant.language || 'EN'
		}

		// If our language is the same as theirs, skip this message
		if (fromLang === toLang) continue

		// Translate message
		const translated = await OnlineComms.translate(fromLang, toLang, [msg.message])
		translated.forEach(translation => {
			dispatch(action(types.MESSAGE_TRANSLATED, { id: msg.key, text: translation }))
		})
	}
}

export default {
	checkForMessagesThatNeedTranslation,
	markConversationAsRead,
	sendMessageToAllGroups,
	getAllConversations,
	sendMessageToGroup,
	sendMessageToTutor,
	getConversation,
	clearRecentFlag,
}
