// Define paramters for function
type calculateDominantSpeakerParameters = {
	speakers: SimpleObject
	clientId: string
	remoteParticipants: JitsiParticipantDetails[]
	localParticipant: JitsiParticipantDetails
	interpreters: InterpreterDetails[]
	selectedInterpreter: string
	loggedInAsFacilitator: boolean
	loggedInAsObserver: boolean
	loggedInAsParticipant: boolean
}

// Define function signature
type calculateDominantSpeakerFunc = (params: calculateDominantSpeakerParameters) => JitsiParticipantDetails

// =================================================================================================

const calculateDominantSpeaker: calculateDominantSpeakerFunc = params => {
	const { speakers, clientId, remoteParticipants, localParticipant, interpreters, selectedInterpreter } = params
	const { loggedInAsFacilitator, loggedInAsObserver, loggedInAsParticipant } = params

	let dominantSpeaker = localParticipant
	let dominantSpeakerLastSpoke = null
	Object.keys(speakers).forEach(id => {
		const lastSpoke = speakers[id]

		let participant = remoteParticipants.find(p => p.participantId === id)
		if (id === localParticipant.participantId) participant = localParticipant

		if (!participant) return

		let blocked = false
		const pProperties = participant.properties || {}
		const pClientId = pProperties.clientId
		const pSelectedInterpreter = pProperties.selectedInterpreter
		// const pIsFacilitator = pProperties.isFacilitator === 'true'
		// const pIsParticipant = Boolean(pProperties.participantId)
		const interpreterDetails = clientId && interpreters.find(i => i.clientId === pClientId)
		const participantIsActiveInterpreter = Boolean(interpreterDetails && !interpreterDetails.onMainChannel)
		// We block a participant from being displayed as the dominant speaker if:
		//  a. They are an interpreter (not on main channel), or
		//  b. They are a participant on a different channel to the one we are on, or
		//  c. We are a facilitator and they are a participant on any interpreter channel
		//  d. We are an observer and they are a participant on a different interpreter channel to ours, or
		//  e. We've already found someone else who spoke more recently
		blocked = Boolean(
			participantIsActiveInterpreter ||
				(loggedInAsParticipant && pSelectedInterpreter && pSelectedInterpreter !== selectedInterpreter) ||
				(loggedInAsFacilitator && pSelectedInterpreter) ||
				// (loggedInAsObserver && pIsParticipant && pSelectedInterpreter !== clientId) ||
				lastSpoke < dominantSpeakerLastSpoke
		)
		// Crown the new dominant speaker to be displayed fullscreen
		if (!blocked) {
			dominantSpeaker = participant
			dominantSpeakerLastSpoke = lastSpoke
		}
	})
	return dominantSpeaker
}

export default calculateDominantSpeaker
