import { connect } from 'react-redux'
import React from 'react'

import BubbleHeading from '../elements/BubbleHeading'
import MessagesPanel from '../components/MessagesPanel'
import GroupsList from '../components/GroupsList'
import config from '../../config'
import Actions from '../actions'

type TutorMessagesProps = {
	viewingPreviousSession?: boolean
	getConversations?: () => void
	conversations: CurrentSession['conversations']
	groups: GroupDetails[]
	allGroupMessages?: Message[]
	markConversationAsRead?: (groupId: string) => void
}

type TutorMessagesState = {
	selectedGroup?: string
}

class TutorMessages extends React.Component<TutorMessagesProps, TutorMessagesState> {
	getInterval: number

	constructor(props) {
		super(props)
		this.state = {}
		this._onClickGroup = this._onClickGroup.bind(this)
		this._onClickAllGroups = this._onClickAllGroups.bind(this)
	}

	componentDidMount() {
		const { viewingPreviousSession, getConversations } = this.props
		if (viewingPreviousSession) return

		getConversations()
		this.getInterval = window.setInterval(_ => getConversations(), 3000)
	}

	componentWillUnmount() {
		clearInterval(this.getInterval)
	}

	_onClickGroup(groupColour) {
		this.setState({ selectedGroup: groupColour })
		const { groups, markConversationAsRead } = this.props
		const selectedGroup = groups.find(g => g.colour === groupColour) || ({} as GroupDetails)
		markConversationAsRead(selectedGroup.id)
	}

	_onClickAllGroups() {
		this.setState({ selectedGroup: 'all-groups' })
	}

	_mergeConversations(conversations) {
		const { groups } = this.props
		// Sort in ascending order of timestamp
		const sortAscending = (a, b) => a.timestamp - b.timestamp

		let merged = []
		Object.keys(conversations).forEach(c => {
			const selectedGroup = groups.find(g => g.id === c || g.key === c) || ({} as GroupDetails)
			const selectedGroupColour = selectedGroup.colour
			merged = merged.concat(conversations[c].map(o => ({ ...o, groupColour: selectedGroupColour })))
		})

		return merged.sort(sortAscending)
	}

	_insertMessagesToAllGroups(conversation, allGroupMessages) {
		const sortAscending = (a, b) => a.timestamp - b.timestamp

		const msgsToAllGrps = (allGroupMessages || []).map(o => ({
			...o,
			tutor: true,
			allGroups: true,
		}))
		return (conversation || []).concat(msgsToAllGrps).sort(sortAscending)
	}

	render() {
		const { props } = this
		const { conversations, groups, allGroupMessages } = props
		const { selectedGroup } = this.state
		const className = 'tutor-messages'

		// Firstly, get a list of the groups which have unread messages
		const unreadGroups = []
		// eslint-disable-next-line no-restricted-syntax
		for (const c in conversations) {
			if (conversations[c].find(m => m.unread)) {
				unreadGroups.push(c)
			}
		}

		let selectedGroupId
		let conversation

		// If displaying messages from all groups, merge all conversations into one list
		if (selectedGroup === 'all-groups') {
			conversation = this._mergeConversations(conversations)
			selectedGroupId = 'all-groups'
		} else {
			if (selectedGroup) {
				const grp = groups.find(g => g.colour === selectedGroup) || ({} as GroupDetails)
				selectedGroupId = grp.id || grp.key
			}
			conversation = conversations[selectedGroupId]
		}

		// Always merge in the messages that were sent to all groups
		conversation = this._insertMessagesToAllGroups(conversation, allGroupMessages)

		return (
			<div className={className}>
				<div className={`${className}__col`}>
					<BubbleHeading>{config.strings.GROUPS}</BubbleHeading>
					<GroupsList
						onClickGroup={this._onClickGroup}
						onClickAllGroups={this._onClickAllGroups}
						selected={selectedGroup}
						unreadGroups={unreadGroups}
						showAllGroups
					/>
				</div>
				<div className={`${className}__col`}>
					<BubbleHeading>{config.strings.MESSAGES}</BubbleHeading>
					<MessagesPanel data={conversation} groupId={selectedGroupId} />
				</div>
			</div>
		)
	}
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = state => {
	const { viewingPreviousSession } = state
	const session = viewingPreviousSession ? state.previousSession || {} : state.currentSession || {}
	const groups = viewingPreviousSession ? session.groups || [] : state.groups || []

	return {
		openCase: state.openCase,
		groups,
		viewingPreviousSession,
		conversations: session.conversations || [],
		allGroupMessages: session.allGroupMessages || [],
		linkedAccess: state.linkedAccess && state.linkedAccess.connected,
	}
}
const actions = {
	markConversationAsRead: Actions.messages.markConversationAsRead,
	getConversations: Actions.messages.getAllConversations,
}
export default connect(mapStateToProps, actions)(TutorMessages)
