// =============================================================================
// Components > Input
// =============================================================================

import React from 'react'
import InputFieldManager from '../components/InputFieldManager'
import checkFieldHasCorrectDirection from '../util/checkFieldHasCorrectDirection'

const EyeSvg = _ => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
		<path d="M288 288a64 64 0 0 0 0-128c-1 0-1.88.24-2.85.29a47.5 47.5 0 0 1-60.86 60.86c0 1-.29 1.88-.29 2.85a64 64 0 0 0 64 64zm284.52-46.6C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 96a128 128 0 1 1-128 128A128.14 128.14 0 0 1 288 96zm0 320c-107.36 0-205.46-61.31-256-160a294.78 294.78 0 0 1 129.78-129.33C140.91 153.69 128 187.17 128 224a160 160 0 0 0 320 0c0-36.83-12.91-70.31-33.78-97.33A294.78 294.78 0 0 1 544 256c-50.53 98.69-148.64 160-256 160z" />
	</svg>
)

let timeout

type InputProps = {
	focus?: boolean
	selectAll?: string
	onEnter?: (e: React.KeyboardEvent) => void
	type?: string
	name?: string
	onChange?: (e: React.ChangeEvent) => void
	placeholder?: string
	enabled?: boolean
	error?: boolean
	style?: SimpleObject
	label?: string
	value?: string
	rows?: number
}

type InputState = {
	value: string
	showPassword?: boolean
	capsLockMessage?: string
}

class Input extends React.Component<InputProps, InputState> {
	textInput?: HTMLTextAreaElement | HTMLInputElement

	constructor(props: InputProps) {
		super(props)
		this._onChange = this._onChange.bind(this)
		this._showPassword = this._showPassword.bind(this)

		this.state = {
			value: (props.name ? (InputFieldManager.getValue(props.name) as string) : props.value) || '',
			showPassword: false,
		}
	}

	componentDidMount(): void {
		const { focus, selectAll } = this.props

		if (focus) this.textInput.focus()
		if (selectAll) this.textInput.select()

		checkFieldHasCorrectDirection(this.textInput)
	}

	_handleKeyPress(e: React.KeyboardEvent): void {
		const { onEnter, type } = this.props
		if (e.key === 'Enter' && onEnter) {
			onEnter(e)
		}
		// If user is entering a password, display a message if it looks like CapsLock is on
		if (type === 'password') {
			const s = String.fromCharCode(e.keyCode || e.which)
			if (s.toUpperCase() !== s.toLowerCase()) {
				// It is a letter
				const capsLockOn = s.toUpperCase() === s && s.toLowerCase() !== s && !e.shiftKey
				this.setState({ capsLockMessage: capsLockOn ? 'Caps Lock is ON' : null })
				clearTimeout(timeout)
				if (capsLockOn) {
					timeout = setTimeout(_ => this.setState({ capsLockMessage: null }), 2000)
				}
			}
		}
	}

	_onChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
		const { name, onChange } = this.props
		this.setState({ value: e.target.value })
		InputFieldManager.setValue(name, e.target.value)
		if (onChange) onChange(e)
	}

	_renderSimpleInput(enabled?: boolean): JSX.Element {
		const { placeholder } = this.props
		let { type = 'text' } = this.props
		const { showPassword, value } = this.state
		if (type === 'password' && showPassword) {
			type = 'text'
		}
		return (
			<input
				ref={input => (this.textInput = input)}
				type={type}
				onChange={this._onChange}
				onKeyPress={this._handleKeyPress.bind(this)}
				value={value}
				disabled={!enabled}
				placeholder={placeholder}
			/>
		)
	}

	_showPassword(): void {
		const { showPassword, value } = this.state
		this.setState({ showPassword: !showPassword })
		setTimeout(_ => {
			this.textInput.focus()
			const caretPos = value.length
			this.textInput.setSelectionRange(caretPos, caretPos)
		}, 100)
	}

	_renderTextArea(enabled: boolean): JSX.Element {
		const { value } = this.state
		const { rows } = this.props
		return (
			<textarea
				ref={input => (this.textInput = input)}
				onChange={this._onChange}
				onKeyPress={this._handleKeyPress.bind(this)}
				value={value}
				rows={rows || 2}
				disabled={!enabled}
			/>
		)
	}

	render(): JSX.Element {
		const { enabled = true, error, type, style, label } = this.props
		const { capsLockMessage } = this.state

		let className = 'input'

		// Allow non-boolean "enabled" values
		const _enabled = enabled !== false
		if (!_enabled) className += ' input--disabled'

		if (error === true) className += ' input--error'

		const inputElem = type === 'textarea' ? this._renderTextArea(_enabled) : this._renderSimpleInput(_enabled)

		return (
			<div className={className} style={style}>
				{label}
				{inputElem}
				{type === 'password' && (
					<span className="input__eye" onClick={this._showPassword}>
						<EyeSvg />
					</span>
				)}
				{capsLockMessage && <span className="input__caps-msg">{capsLockMessage}</span>}
			</div>
		)
	}
}

export default Input
