import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import md5 from 'md5'

import getChatParticipantLanguage from '../util/getChatParticipantLanguage'
import JitsiClient from '../util/JitsiClient'
import MessageElem from './MessageElem'
import Icon from '../elements/Icon'

const GroupIcon = ({ colour }) => <Icon name="users" color={colour} />
const TutorIcon = () => <Icon name="user" />

type ChatMessageProps = {
	message: JitsiChatMessage
	client: JitsiClient
	language: string
	colour: string
	translations: StateTree['translations']
	participants: StateTree['participants']
}

const ChatMessage: FunctionComponent<ChatMessageProps> = props => {
	const { message, client, language, translations, participants, colour } = props

	let from = ''
	let _colour = null

	if (client.participantId === message.participantId) {
		from = client.displayName
		_colour = colour
	} else {
		const participant = client.remoteParticipants.find(p => p.participantId === message.participantId)
		if (participant) {
			from = participant.displayName
		}
		// Get participant colour
		const id = participant?.properties?.participantId
		if (id) {
			_colour = participants.find(p => p.id === id)?.colour
		}
	}

	const icon = _colour ? <GroupIcon colour={_colour} /> : <TutorIcon />

	const { timestamp, message: text, privateMsg, to } = message
	let translation = null
	const senderLanguage = getChatParticipantLanguage(message, participants, client.remoteParticipants)
	if (senderLanguage !== language) {
		const hash = md5(JSON.stringify({ text, fromLang: senderLanguage, toLang: language }))
		translation = translations[hash]
	}

	return (
		<MessageElem
			key={timestamp}
			to={to}
			from={from}
			icon={icon}
			message={text}
			privateMsg={privateMsg}
			translation={translation}
			timestamp={String(timestamp)}
		/>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
type ChatMessagePropsFromState = Pick<ChatMessageProps, 'participants' | 'colour' | 'language' | 'translations'>
const mapStateToProps = (state: StateTree): ChatMessagePropsFromState => ({
	participants: state.participants || [],
	colour: state?.group?.colour,
	language: state?.group?.language || 'EN',
	translations: state?.translations || {},
})
const actions = {}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Omit<ChatMessageProps, keyof (PropsFromState & ReduxActions)>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(ChatMessage)
