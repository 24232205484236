import { connect } from 'react-redux'
import React from 'react'

import config from '../../config'

import loggedInAsParticipantSelector from '../selectors/loggedInAsParticipant'
import inCallSelector from '../selectors/inCall'

import teamNameFromColour from '../util/teamNameFromColour'

import VideoCallWaitMessage from '../components/VideoCallWaitMessage'
import MediaCacheComponent from '../components/MediaCacheComponent'
import InitialInformation from '../components/InitialInformation'
import GroupMessagesPanel from '../components/GroupMessagesPanel'
import DecisionLogEntry from '../components/DecisionLogEntry'
import SyncManagerGroup from '../components/SyncManagerGroup'
import TeamBreakoutRoom from '../components/TeamBreakoutRoom'
import DecisionMaker from '../components/DecisionMaker'
import GroupSidebar from '../components/GroupSidebar'
import VideoCall from '../components/VideoCall'
import MediaFeed from '../components/MediaFeed'
import MainCall from '../components/MainCall'

import BubbleHeading from '../elements/BubbleHeading'
import StatusBar from '../elements/StatusBar'
import WaitingRoomModal from '../components/WaitingRoomModal'

// =================================================================================================
// Inner components

// prettier-ignore
const Main = ({ children, onScroll = null }) => <div className="group-view__main" onScroll={onScroll}>{children}</div>
const MainInner = ({ children }) => <div className="group-view__main-inner">{children}</div>
const Root = ({ children }) => <div className="group-view">{children}</div>
const Top = ({ children }) => <div className="group-view__top">{children}</div>
const Left = ({ children }) => <div className="group-view__left">{children}</div>
const Middle = ({ children }) => <div className="group-view__middle">{children}</div>
const DecisionSection = ({ children }) => <div className="group-view__decision-section">{children}</div>

// =================================================================================================
type GroupView = {
	viewingPreviousSession: boolean
	loggedInAsParticipant: boolean
	participantDomain: string
	breakoutRoomsId: string
	serverStatus: string
	displayName: string
	mainCallId: string
	groupId: string
	inCall: boolean
}

function GroupView(props) {
	const { groupId, loggedInAsParticipant, viewingPreviousSession } = props
	const { participantDomain, serverStatus, breakoutRoomsId, inCall, mainCallId, displayName } = props

	const { MEDIA_FEED, INITIAL_UPDATE } = config.strings
	const { VIDEO__NEW_COMPONENTS } = config

	const serverOnline = serverStatus === 'online'

	const showSideCall = !inCall && breakoutRoomsId && participantDomain && loggedInAsParticipant && serverOnline

	let middle = null
	if (inCall) {
		if (VIDEO__NEW_COMPONENTS) {
			middle = <VideoCall callId={mainCallId} domain={participantDomain} displayName={displayName} />
		} else {
			middle = <MainCall callId={mainCallId} domain={participantDomain} displayName={displayName} />
		}
	} else {
		middle = (
			<Middle>
				<GroupSidebar />
				<Left>
					<BubbleHeading>{MEDIA_FEED}</BubbleHeading>
					<MediaFeed groupId={groupId} />
					<GroupMessagesPanel />
				</Left>
				<Main>
					<MainInner>
						<BubbleHeading>{INITIAL_UPDATE}</BubbleHeading>
						<InitialInformation />
						<DecisionSection>
							{!viewingPreviousSession && <DecisionMaker />}
							<DecisionLogEntry />
						</DecisionSection>
					</MainInner>
				</Main>
			</Middle>
		)
	}

	return (
		<>
			<MediaCacheComponent />
			<SyncManagerGroup />
			<Root>
				<Top>
					<StatusBar />
				</Top>
				{middle}
				<VideoCallWaitMessage />
				<WaitingRoomModal />
			</Root>
			{showSideCall && <TeamBreakoutRoom />}
		</>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = state => {
	const groupId = state.group.id
	const { viewingPreviousSession } = state
	const loggedInAsParticipant = loggedInAsParticipantSelector(state)
	const displayName = state.group.name || teamNameFromColour(state.group.colour)

	const videoconf = state.videoconf || {}
	const { breakoutRoomsId, mainCallId, participantDomain, status } = videoconf

	return {
		inCall: inCallSelector(state),
		viewingPreviousSession,
		loggedInAsParticipant,
		serverStatus: status,
		participantDomain,
		breakoutRoomsId,
		displayName,
		mainCallId,
		groupId,
	}
}
const actions = {}

export default connect(mapStateToProps, actions)(GroupView)
