import React from 'react'
import ConfirmationHelper from '../util/ConfirmationHelper'
import { sessionIsActive, sessionSecondsLeft } from '../util/SessionHelpers'
import config from '../../config'

const WARNING_TIME_SECS = 300

type CountdownProps = {
	session: Session
}

type CountdownState = {
	secs?: number
	time: string
}

export default class Countdown extends React.Component<CountdownProps, CountdownState> {
	interval: NodeJS.Timeout
	lastSecs: number

	constructor(props: CountdownProps) {
		super(props)
		this.state = { time: '00:00:00' }
	}

	componentDidMount(): void {
		this.interval = setInterval(() => {
			const { session } = this.props
			const secsLeft = sessionSecondsLeft(session)
			const timeLeft = this.getTimeLeft(secsLeft)
			this.setState({ time: timeLeft, secs: secsLeft })
		}, 1000)
	}

	componentDidUpdate(): void {
		const { WARNING_TIME_MSG } = config.strings
		const { secs } = this.state
		// Display warning if we reach the "warning time" (5 minutes remaining, at time of writing)
		if (secs !== this.lastSecs && secs === WARNING_TIME_SECS) {
			ConfirmationHelper.warning(WARNING_TIME_MSG)
		}
		this.lastSecs = secs
	}

	componentWillUnmount(): void {
		clearInterval(this.interval)
	}

	getTimeLeft(secsLeft: number): string {
		// Calculate remaining time
		const hours = Math.floor(secsLeft / 3600) % 24 || 0
		const minutes = Math.floor(secsLeft / 60) % 60 || 0
		const seconds = Math.floor(secsLeft % 60) || 0

		// Return time as HH:MM:SS
		return [hours, minutes, seconds].map(v => String(v).padStart(2, '0')).join(':')
	}

	render(): JSX.Element {
		let className = 'countdown'

		const { session } = this.props
		const { time, secs } = this.state

		if (!sessionIsActive(session)) {
			className += ' countdown--zero'
		} else if (session.paused) {
			className += ' countdown--paused'
		} else if (secs >= 295 && secs <= 300) {
			className += ' countdown--nearzero'
		} else if (secs >= 55 && secs <= 60) {
			className += ' countdown--nearzero'
		} else if (!secs) {
			className += ' countdown--zero'
		}

		return <div className={className}>{time}</div>
	}
}
