import { Dispatch } from 'redux'

import ConfirmationHelper from '../util/ConfirmationHelper'
import SessionHelpers from '../util/SessionHelpers'
import downloadBlob from '../util/downloadBlob'
import OnlineComms from '../core/OnlineComms'
import types from './types'
import getTime from '../util/getTime'
import action from '../util/action'
import config from '../../config'
import groupActions from './groups'

const updateBuffer = {}
const updateRowBuffer = {}
let holdoffReleases = false

const _getSessionDetails = (_case, sessionId) => {
	// Get existing session details
	const sessions = _case.sessions || []
	const session = { ...sessions.find(s => s.id === sessionId) }
	session.schedule = (session.schedule || []).map(o => ({ ...o }))
	return session
}

const actions = {
	/* */
	addNewSession: (caseId: Case['id']) => (dispatch: Dispatch): void => {
		const now = getTime()
		const id = now.toString()

		const newSession: Session = {
			id,
			key: id,
			initialUpdate: '',
			duration: 0, // in minutes,
			schedule: [],
			timestamp: now,
			caseId,
		}

		OnlineComms.addNewSession(caseId, newSession).then(_ => {
			dispatch(action(types.SESSIONS_ADD_NEW_SUCCESS, newSession))
			dispatch(action(types.SESSION_SELECT, newSession.id))
		})
	},

	updateSession: sessionDeets => (dispatch: Dispatch, getState: GetState): void => {
		// Optimistically update the server details in state
		dispatch(action(types.SESSIONS_UPDATE_SUCCESS, sessionDeets))

		const state = getState()
		const caseId = state.openCase.key
		const sessionId = sessionDeets.key || sessionDeets.id
		const sessionWithoutSchedule = { ...sessionDeets, schedule: null }

		// Update session on server. Buffer this so we don't flood the server
		const update = _ => OnlineComms.updateSession(caseId, sessionId, sessionWithoutSchedule)
		clearTimeout(updateBuffer[sessionId])
		updateBuffer[sessionId] = setTimeout(update, 1000)
	},

	updateReleaseScheduleRow: row => (dispatch: Dispatch, getState: GetState): void => {
		const state = getState()
		const sessionId = row.sessionId || row.parent.split('_')[1]
		const openCase = state.openCase || {}
		const caseId = openCase.key
		if (!caseId) return

		// Dispatch an update of the session to be recorded in state
		const session = _getSessionDetails(openCase, sessionId)
		const rowIndex = session.schedule.findIndex(r => r.id === row.id)
		if (rowIndex >= 0) {
			session.schedule[rowIndex] = row
			dispatch(action(types.SESSIONS_UPDATE_SUCCESS, session))
		}

		// Update release row on server. We buffer this so as to not flood the server
		// when someone is typing in an update
		const updateOnServer = _ => OnlineComms.updateReleaseRow(caseId, sessionId, row.id, row)
		const compoundKey = [caseId, sessionId, row.id].join()
		clearTimeout(updateRowBuffer[compoundKey])
		updateRowBuffer[compoundKey] = setTimeout(updateOnServer, 1000)
	},

	deleteSession: (id: string) => (dispatch: Dispatch, getState: GetState): void => {
		const state = getState()
		const caseId = state.openCase.key
		OnlineComms.deleteSession(caseId, id).then(_ => dispatch(action(types.SESSIONS_DELETE, { timestamp: id })))
	},

	deletePreviousSession: (prevSessionId: string) => (dispatch: Dispatch, getState: GetState): void => {
		const state = getState()
		const caseId = state.openCase.key
		ConfirmationHelper.confirm(config.strings.CONFIRM_DELETE_PREV_SESSION, _ => {
			dispatch(action(types.SESSIONS_PREV_DELETED, prevSessionId))
			OnlineComms.deletePreviousSession(caseId, prevSessionId)
		})
	},

	startSession: (sessionId: string) => (dispatch: Dispatch, getState: GetState): void => {
		const state = getState()
		const currSess = state.currentSession
		const startTime = getTime()

		if (currSess && SessionHelpers.sessionIsActive(currSess)) return

		const caseId = state.openCase.id
		OnlineComms.startSession(caseId, sessionId).then(response => {
			const { id, message } = response
			if (id) dispatch(action(types.SESSIONS_START, { id, startTime, sessionId }))
			if (message) ConfirmationHelper.alert('Error', message)
		})
	},

	endSession: (id: string) => (dispatch: Dispatch, getState: GetState): void => {
		const state = getState()
		// FYI: The server doesn't even need the current case ID. It just
		// gets the current session itself (based on facilitator ID) and
		// ends it.
		const caseId = state.openCase.id
		OnlineComms.endSession(caseId, id).then(_ => dispatch(action(types.SESSIONS_END)))
	},

	addReleaseRow: (sessionId: string, index: number) => (dispatch: Dispatch, getState: GetState): void => {
		const state: StateTree = getState() || {}
		const timestamp = getTime().toString()
		const row = {
			release: 'manual',
			type: 'media',
			id: timestamp,
			timestamp,
			sessionId,
			index,
		}

		// Be optimistic
		const payload = { sessionId, row }
		dispatch(action(types.SESSIONS_ADD_RELEASE_ROW_SUCCESS, payload))

		const caseId = state.openCase.key || state.currentSession.parent
		OnlineComms.addReleaseRow(caseId, sessionId, row)
	},

	removeReleaseRow: (sessionId: string, rowId: string) => (dispatch: Dispatch, getState: GetState): void => {
		const state = getState()
		const caseId = state.openCase.id
		OnlineComms.removeReleaseRow(caseId, sessionId, rowId)
		// Dispatch:Dispatch without waiting from communication with server to complete
		dispatch(action(types.SESSIONS_REMOVE_RELEASE_ROW, { sessionId, rowId }))
	},

	releaseNow: (rowId: string) => async (dispatch: Dispatch): Promise<void> => {
		try {
			holdoffReleases = true
			dispatch(action(types.SESSION_RELEASE_NOW, { rowId, timeNow: getTime() }))
			await OnlineComms.releaseToAll(rowId)
		} catch (err) {
			console.error(err)
		}
		holdoffReleases = false
	},

	releaseToGroup: (groupId: string, rowId: string) => (dispatch: Dispatch): void => {
		ConfirmationHelper.confirm(config.strings.CONFIRM_RELEASE_TO_GROUP, () => {
			try {
				holdoffReleases = true
				OnlineComms.releaseToGroup(groupId, rowId)
				const payload = { groupId, rowId, timestamp: getTime() }
				dispatch(action(types.SESSION_RELEASE_TO_GROUP, payload))
			} catch (err) {
				console.error(err)
			}
			holdoffReleases = false
		})
	},

	pauseSession: () => (dispatch: Dispatch): void => {
		OnlineComms.pauseCurrentSession()
		dispatch(action(types.SESSIONS_PAUSE, {}))
	},

	resumeSession: () => (dispatch: Dispatch): void => {
		OnlineComms.resumeCurrentSession()
		dispatch(action(types.SESSIONS_RESUME, {}))
	},

	getCurrentSession: () => (dispatch: Dispatch): void => {
		OnlineComms.getCurrentSession().then(sess => {
			if (sess) dispatch(action(types.SESSIONS_GOT_CURRENT_SESSION, sess))
		})
	},

	getOpeningsForGroup: (sessionId: string, groupId: string) => (dispatch: Dispatch): void => {
		if (!sessionId || !groupId) return
		OnlineComms.getOpenings(groupId, sessionId).then(data => {
			// Include the groupId and sessionId in the objects we're storing
			const _data = (data || [])
				.map(d => ({ ...d, groupId, sessionId }))
				.map(opening => {
					// If record key contains '_', it is scheduleRowId_participantId
					if (opening.key.includes('_')) {
						const split = opening.key.split('_')
						return { ...opening, key: split[0], participantId: split[1] }
					}
					return opening
				})
			dispatch(action(types.SESSIONS_GOT_OPENINGS, { data: _data }))
		})
	},

	getReleasesForSession: (sessionId: string) => (dispatch: Dispatch): void => {
		if (!sessionId) return
		OnlineComms.getReleasesForSession(sessionId).then(data => {
			if (holdoffReleases) return
			// Include the sessionId in the objects we're storing
			const _data = (data || []).map(d => ({ ...d, sessionId }))
			dispatch(action(types.SESSIONS_GOT_RELEASES, { sessionId, data: _data }))
		})
	},

	// ===============================================================================================

	getPreviousSessions: () => (dispatch: Dispatch, getState: GetState): void => {
		const state = getState()
		const openCase = state.openCase || (state.groupData || {}).currentCase || {}
		const caseId = openCase.id

		dispatch(action(types.SESSIONS_PREV_LOADING_LIST))
		OnlineComms.getPreviousSessions(caseId)
			.then(sessions => {
				dispatch(action(types.SESSIONS_PREV_RETRIEVED, sessions))
			})
			.catch(err => {
				console.error('Error fetching previous sessions: ', err)
				dispatch(action(types.SESSIONS_PREV_LOADING_LIST_FAILED))
			})
	},

	loadPreviousSession: (prevSessionId: string) => (dispatch: Dispatch, getState: GetState): void => {
		const state = getState()
		dispatch(action(types.SESSIONS_PREV_LOADING))

		const caseId = state?.openCase?.id || state?.groupData?.currentCase?.id

		OnlineComms.getPreviousSession(caseId, prevSessionId).then(session => {
			dispatch(action(types.SESSIONS_PREV_LOADED, session))
			// Fetch any translations if necessary
			groupActions.getDecisionLogTranslationsForGroup()(dispatch, getState)
		})
	},

	loadPreviousSessionForFacilitator: (prevSessionId: string) => (dispatch: Dispatch, getState: GetState): void => {
		dispatch(action(types.SESSIONS_PREV_LOADING))
		const state = getState()
		const groupData = state.groupData || {}
		const openCase = state.openCase || groupData.currentCase || {}
		const { id } = openCase as SimpleObject
		OnlineComms.getPreviousSession(id, prevSessionId).then(session => {
			dispatch(action(types.SESSIONS_PREV_LOADED, session))
		})
	},

	returnToCurrentSession: () => (dispatch: Dispatch): void => {
		dispatch(action(types.SESSIONS_RETURN_TO_CURRENT))
	},

	exportSession: (filename: string, prevSessionId: string, translate?: boolean) => (
		dispatch: Dispatch,
		getState: GetState
	): void => {
		const state = getState()
		const groupData = state.groupData || {}
		const openCase = state.openCase || groupData.currentCase || {}
		const { id } = openCase as SimpleObject
		// const caseId = (state.openCase || {}).timestamp || ((state.groupData || {}).currentCase || {}).timestamp

		OnlineComms.getPreviousSessionDocument(id, prevSessionId, translate).then(blob => {
			downloadBlob(blob)
		})
	},

	selectSession: (sessionId: string) => (dispatch: Dispatch): void => {
		dispatch(action(types.SESSION_SELECT, sessionId))
	},

	resendPhoneMessage: (scheduleRowId: string) => async (dispatch: Dispatch): Promise<void> => {
		holdoffReleases = true
		dispatch(action(types.PHONE_MESSAGE_RESENT, { scheduleRowId }))
		await OnlineComms.resendPhoneMessage(scheduleRowId)
		holdoffReleases = false
	},
}

export default actions
