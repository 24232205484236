function getChatParticipantLanguage(
	chatMessage: JitsiChatMessage,
	participants: Participant[],
	remoteParticipants: JitsiParticipantDetails[]
): string {
	let language = 'EN'

	// Get the view360 participant ID for the participant who sent this message
	// NOTE: This is different from the Jitsi participant ID, so we need to look them up in the remote participants list
	const remoteParticipant = remoteParticipants.find(p => p.participantId === chatMessage.participantId)
	if (!remoteParticipant) return language

	const participantId = remoteParticipant?.properties?.participantId
	if (participantId) {
		// Get the participant details
		const participant = participants.find(p => p.id === participantId)
		if (!participant) return language
		// Check their language. If it is the same as ours then no translation needed
		language = participant.language || 'EN'
	}
	return language
}

export default getChatParticipantLanguage
