export default {
	/* Team view */
	OK: '好',
	CANCEL: '取消',
	TUTOR: '服务商',
	CURRENT_SESSION: '当前会议',
	TEAM: '小组',
	SIGNOUT: '登出',
	INITIAL_UPDATE: '会议信息',
	ADVICE: '决定',
	RATIONALE: '基本原理',
	MEDIA_FEED: '媒体来源',
	MESSAGES: '信息',
	PREVSESSIONS: '之前会议',
	RETURN_TO_CURRENT: '返回当前会议',
	SESSION_IS_PAUSED: '会议当前已暂停',
	ATTRIB_CURR_AUTHOR: '决策者',
	SAVE: '保存',
	CHANGE: '更改',
	NO_AUTHOR: '未设置',
	NO_INITIAL_INFO: '请等待下一个会议开始',
	DECISION_MAKER_SELECT: '请选择决策者',
	SESSION_COMPLETE: '之前会议已结束',
	OPENED_AT: '打开于',
	SEND_MESSAGE_PLACEHOLDER: '留言',
	SEND: '发送',
	TO_ALL_GROUPS: '对所有组',
	SHOW_TRANSLATION: '显示译文',
	SHOW_ORIGINAL: '显示原始文件',
	CREDENTIALS_EXPIRED: '您的密码已过期。请重新登录。',
	INCOMING_MESSAGE: '传入信息',
	INCOMING_MESSAGE_TEXT: '已收到新信息',
	INCOMING_MEDIA: '传入媒体项',
	INCOMING_MEDIA_TEXT: '已收到新的媒体项',
	INCOMING_UPDATE: '传入更新',
	INCOMING_UPDATE_TEXT: '已收到更新',
	CONFIRM_LOGOUT: '您确定要注销吗？',
	MUTED_MESSAGE: '您的麦克风已关闭',
	NEWSTYPE_UPDATE: '更新',
	WARNING_TIME_MSG: '剩下5分钟',
}
