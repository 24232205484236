import WebSocket from 'isomorphic-ws'
import io from 'socket.io-client'

import * as Sentry from '@sentry/browser'

import config from '../../config'

const { SOCKET_SERVER_URL } = config

// =================================================================================================
// This promise will be used to delay any messages from being sent until socket has connected
// eslint-disable-next-line @typescript-eslint/no-empty-function
let connectResolve: (value?: unknown) => void = () => {}
let connectPromise = new Promise(resolve => {
	connectResolve = resolve
})

type Listener = {
	messageType: string
	callback: (payload: string) => void
}
type LoginInfo = {
	type: string
	data: SimpleObject
}

// =================================================================================================
class SocketComms {
	reconnectTimeout = null
	websocket: WebSocket = null
	messageListeners: Listener[] = []
	isOpen = false
	lastLogin: LoginInfo = null

	constructor() {
		setInterval(this.ping.bind(this), 30000)
	}

	connect() {
		if (!SOCKET_SERVER_URL) return
		try {
			// If socket server uses http scheme, it is probably a socket-io server, so connect with socket-io client
			if (SOCKET_SERVER_URL.substr(0, 4) === 'http') {
				this.websocket = io(SOCKET_SERVER_URL)
				this.websocket.on('connect', this.onConnect.bind(this))
				this.websocket.on('reconnect', this.onConnect.bind(this))
				this.websocket.on('disconnect', this.onDisconnect.bind(this))
				// Add any socket listeners that we have lined up for this socket connection
				this.messageListeners.forEach(listener => {
					this.websocket.on(listener.messageType, listener.callback)
				})
			} else {
				this.websocket = new WebSocket(SOCKET_SERVER_URL)
				this.websocket.onopen = this.onConnect.bind(this)
				this.websocket.onmessage = this.onMessage.bind(this)
				this.websocket.onclose = this.onDisconnect.bind(this)
				this.websocket.onerror = e => {
					Sentry.captureException(e)
					console.error('Websocket error:', e)
					if (!this.isOpen) {
						this.attemptDelayedReconnect()
					}
				}
			}
		} catch (err) {
			console.error(err)
			this.attemptDelayedReconnect()
		}
	}

	attemptDelayedReconnect() {
		if (!this.reconnectTimeout) {
			this.reconnectTimeout = setTimeout(() => {
				this.reconnectTimeout = null
				this.attemptReconnect()
			}, 2000)
		}
	}

	on(messageType, callback) {
		if (!SOCKET_SERVER_URL) return
		this.messageListeners.push({ messageType, callback })
		// Directly add the event handler if we are using socket-io
		// This is because we can't attach a general "onMessage" handler for
		// socket io, so we have to add an event handler for any particular event types
		if (SOCKET_SERVER_URL.substr(0, 4) === 'http' && this.websocket) {
			this.websocket.on(messageType, callback)
		}
	}

	onConnect() {
		console.log('Websocket connected')
		this.isOpen = true
		// Resolve this promise so that any actions that were waiting for a connection can now be executed
		connectResolve()

		// Resend the last login request to register ourselves with the socket server
		if (this.lastLogin) {
			this.send(this.lastLogin.type, this.lastLogin.data)
		}
	}

	onDisconnect() {
		if (this.isOpen) {
			this.isOpen = false
			connectPromise = new Promise(resolve => {
				connectResolve = resolve
			})
			console.log('Websocket disconnected')
			this.attemptReconnect()
		}
	}

	attemptReconnect() {
		if (this.websocket && this.websocket.removeAllListeners) this.websocket.removeAllListeners()
		console.log('Attempting reconnection...')
		this.connect()
	}

	onMessage(data) {
		const message = JSON.parse(data.data)
		if (message.type !== 'pong') console.log('Websocket message received: ', message.type, message.data)
		const payload = message.data
		const typeListener = this.messageListeners.find(listener => listener.messageType === message.type)
		if (typeListener && typeof typeListener.callback === 'function') {
			typeListener.callback(payload)
		}
	}

	// ===============================================================================================
	// Sends a message on the current websocket connection
	send(action: string, data: SimpleObject = {}) {
		connectPromise.then(() => {
			// If we are using the socket-io client, use '.emit(type, data)'
			if (SOCKET_SERVER_URL.substr(0, 4) === 'http') {
				if (action !== 'ping') console.log('Sending to websocket:', { action, msg: data })
				this.websocket.emit(action, data)
			}
			// If we are using a standard websocket implementation (e.g. AWS) use .send(string)
			else {
				const msg = JSON.stringify(data)
				const payload = JSON.stringify({ action, msg })
				// Check if socket is still currently connected. If not, resubmit this request.
				if (this.websocket.readyState !== 1) {
					setTimeout(() => this.send(action, data), 1)
					return
				}
				if (action !== 'ping') console.log('Sending to websocket:', { action, msg: data })
				this.websocket.send(payload)
			}
		})
	}

	// ===============================================================================================

	facilitatorlogin(facilitatorId: string) {
		this.lastLogin = { type: 'facilitatorlogin', data: { facilitatorId } }
		this.connect()
	}

	observerlogin(facilitatorId: string, clientId: string, name: string) {
		this.lastLogin = { type: 'observerlogin', data: { facilitatorId, clientId, name } }
		this.connect()
	}

	groupLogin(facilitatorId: string, groupId: string, colour: string) {
		this.lastLogin = { type: 'grouplogin', data: { facilitatorId, groupId, colour } }
		this.connect()
	}

	participantLogin(facilitatorId: string, participantId: string, name: string, colour: string) {
		this.lastLogin = { type: 'participantlogin', data: { facilitatorId, participantId, name, colour } }
		this.connect()
	}

	// ===============================================================================================
	// General socket event emitters

	ping() {
		if (this.websocket?.readyState === 1) this.send('ping')
	}

	logout() {
		this.lastLogin = null
		this.send('logout')
	}

	startMainCall(facilitatorId: string, callId: string, modId: string, mainCallWarningEnabled: boolean) {
		this.send('startmaincall', { facilitatorId, callId, modId, mainCallWarningEnabled })
	}

	endMainCall(facilitatorId: string) {
		this.send('endmaincall', { facilitatorId })
	}

	activateBreakoutRooms(facilitatorId: string, callId: string) {
		this.send('activatebreakoutrooms', { facilitatorId, callId })
	}

	deactivateBreakoutRooms(facilitatorId: string) {
		this.send('deactivatebreakoutrooms', { facilitatorId })
	}

	activateObserverBreakoutRoom(facilitatorId: string, callId: string) {
		this.send('activateobserverbreakoutroom', { facilitatorId, callId })
	}

	deactivateObserverBreakoutRoom(facilitatorId: string, callId: string) {
		this.send('deactivateobserverbreakoutroom', { facilitatorId, callId })
	}

	interpreterChangeChannel(facilitatorId: string, clientId: string, onMainChannel: boolean) {
		this.send('interpreterchangechannel', { facilitatorId, clientId, onMainChannel })
	}
}

// Export as Singleton
export default new SocketComms()
