/* eslint-disable react/jsx-props-no-spreading */

import React, { FunctionComponent, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Location } from 'history'

import history from '../core/history'

import packageJson from '../../../package.json'

import Actions from '../actions'
import config from '../../config'

import LoginParticipant from '../components/LoginParticipant'
import LoginParticipantPhone from './LoginParticipantPhone'
import LoginGroup from '../components/LoginGroup'
import LoginTutor from '../components/LoginTutor'
import LoginMain from '../components/LoginMain'
import LoginLink from '../components/LoginLink'
import Transition from '../elements/Transition'
import Preloader from '../elements/Preloader'

// =====================================================================================================================
// Transition wrappers

type TransitionProps = {
	in: boolean
}
const TransitionInFromRight: FunctionComponent<TransitionProps> = props => (
	<Transition className="login__slideinfromright" {...props} />
)
const TransitionInFromLeft: FunctionComponent<TransitionProps> = props => (
	<Transition className="login__slideinfromleft" {...props} />
)
const TransitionFadeIn: FunctionComponent<TransitionProps> = props => (
	<Transition className="login__fadeIn" {...props} />
)
// =====================================================================================================================

type LoginProps = {
	loggingIn?: boolean
	promptParticipantPhone?: boolean
	phoneNumber?: string
	navigatingTo?: string
	location?: Location
}

const Login: FunctionComponent<LoginProps> = props => {
	const { loggingIn, location } = props
	const { VERSION } = config.strings

	// // State to determine which login form to display
	// const [view, setView] = useState('main')

	// console.log('location', location)

	// useEffect(() => {
	// 	console.log('navigatingTo', navigatingTo)
	// 	if (navigatingTo) setView(navigatingTo.replace('login-', ''))
	// 	if (navigatingTo === 'login') setView('main')
	// }, [navigatingTo])

	const routeMap = {
		PARTICIPANT: '/login/participant',
		OBSERVER: '/login/observer',
		GROUP: '/login/group',
		TUTOR: '/login/tutor',
		PHONE: '/login/phone',
		MAIN: '/login',
	}

	return (
		<div className="login">
			<div className="login__inner">
				{/* Angled white section */}
				<svg className="login__white-bit" preserveAspectRatio="none" viewBox="0 0 1000 1000">
					<g>
						<path fill="#FFFFFF" stroke="none" d="M 500 0 L 0 1000 1000 1000 1000 0 500 0 Z" />
					</g>
				</svg>
				<div className="login__left">
					<div className="login__main-logo" />
				</div>
				<div className="login__right">
					<h1>Welcome</h1>
					<h2>Please log in below</h2>
					<div className="login__content">
						<TransitionInFromRight in={location.pathname === routeMap.TUTOR}>
							<LoginTutor onClickBack={() => history.push('/login')} />
						</TransitionInFromRight>
						<TransitionInFromLeft in={location.pathname === '/login' || location.pathname === '/'}>
							<LoginMain
								onClickGroup={() => history.push(routeMap.GROUP)}
								onClickTutor={() => history.push(routeMap.TUTOR)}
								onClickParticipant={() => history.push(routeMap.PARTICIPANT)}
								onClickLinkedAccess={() => history.push(routeMap.OBSERVER)}
							/>
						</TransitionInFromLeft>
						<TransitionInFromRight in={location.pathname === routeMap.GROUP}>
							<LoginGroup onClickBack={() => history.push('/login')} />
						</TransitionInFromRight>
						<TransitionInFromRight in={location.pathname === routeMap.OBSERVER}>
							<LoginLink onClickBack={() => history.push('/login')} />
						</TransitionInFromRight>
						<TransitionInFromRight in={location.pathname === routeMap.PARTICIPANT}>
							<LoginParticipant onClickBack={() => history.push('/login')} />
						</TransitionInFromRight>
						<TransitionInFromRight in={location.pathname === routeMap.PHONE}>
							<LoginParticipantPhone onClickBack={() => history.push(routeMap.PARTICIPANT)} />
						</TransitionInFromRight>
						<TransitionFadeIn in={loggingIn}>
							<div className="login__overlay">
								<Preloader />
							</div>
						</TransitionFadeIn>
					</div>
				</div>
				<span className="login__version">
					{VERSION} {packageJson.version}
				</span>
			</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<LoginProps> => ({
	loggingIn: Boolean(state?.group?.loggingIn || state?.tutor?.loggingIn || state?.linkedAccess?.connecting),
	promptParticipantPhone: state.settings.promptParticipantPhone,
	phoneNumber: state.group.phoneNumber,
	navigatingTo: state.navigatingTo,
})
const actions = {
	loginAsTutor: Actions.login.loginAsTutor,
	clearErrors: Actions.misc.clearErrors,
}
export default connect(mapStateToProps, actions)(Login)
