/* eslint-disable no-nested-ternary */
import { connect } from 'react-redux'
import React from 'react'

import BubbleHeading from '../elements/BubbleHeading'
import Participant from '../components/Participant'
import Actions from '../actions'
import config from '../../config'
import loggedInAsObserverSelector from '../selectors/loggedInAsObserver'

const { NO_RECORDS, PARTICIPANTS } = config.strings

class TutorParticipants extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	onDropdownChange(participant, e) {
		const participantId = participant.id || participant.participantId
		const { updateParticipantColour } = this.props
		const colour = e.currentTarget.value
		const obj = {}
		obj[participantId] = colour
		this.setState(obj)
		updateParticipantColour(participantId, colour)
	}

	render() {
		const className = 'tutor-participants'
		const { participants, loggedInAsObserver } = this.props

		const participantsArray = [...(participants || [])]

		// Sort by colour and then name
		const sortByColour = (a, b) => (a.colour === b.colour ? 0 : a.colour > b.colour ? 1 : -1)
		const sortByName = (a, b) => (a.name === b.name ? 0 : a.name > b.name ? 1 : -1)
		participantsArray.sort((a, b) => sortByColour(a, b) || sortByName(a, b))

		let content
		if (participantsArray.length) {
			content = participantsArray.map(p => (
				<Participant
					key={p.id}
					participant={p}
					onChange={e => this.onDropdownChange(p, e)}
					allowChange={!loggedInAsObserver}
				/>
			))
		} else {
			content = <div className="none-found">{NO_RECORDS}</div>
		}

		return (
			<div className={className}>
				<div className={`${className}__top`}>
					<BubbleHeading>{PARTICIPANTS}</BubbleHeading>
				</div>
				<div className={`${className}__content`}>{content}</div>
			</div>
		)
	}
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = state => {
	const loggedInAsObserver = loggedInAsObserverSelector(state)
	return {
		participants: state.participants || [],
		loggedInAsObserver,
	}
}
const actions = {
	updateParticipantColour: Actions.participants.updateParticipantColour,
}
export default connect(mapStateToProps, actions)(TutorParticipants)
