/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import React, { FunctionComponent, useState } from 'react'

import getTextDirection from '../util/getTextDirection'

import config from '../../config'

type DecisionLogRowProps = {
	advice: string
	rationale: string
	author: string
	time: string
	translatedRationale?: string
	translatedAdvice?: string
	translatedAuthor?: string
}

const DecisionLogRow: FunctionComponent<DecisionLogRowProps> = props => {
	const { advice, rationale, author, time, translatedAdvice, translatedRationale, translatedAuthor } = props
	const { SHOW_ORIGINAL, SHOW_TRANSLATION } = config.strings
	const hasTranslation =
		(translatedAdvice && translatedAdvice !== advice) ||
		(translatedRationale && translatedRationale !== rationale) ||
		(translatedAuthor && translatedAuthor !== author)

	const [showTranslationIfAvailable, setShowTranslation] = useState(true)
	const showTranslation = showTranslationIfAvailable && hasTranslation

	if (!advice.trim() && !rationale.trim()) {
		return null
	}

	// Display time of log for reference. NOTE: This will display
	// the time in the facilitators local timezone, which may differ from
	// the time that the user see's on their screen for that log.
	// See the previousSessionDocument export on server for how to convert
	// it to be in the timezone of the user (using clientTimezoneOffset
	// that is stored with the log)
	const timeFormatted = time ? new Date(time).toTimeString().substr(0, 8) : ''

	let linkClass = 'decision-log__translate'
	if (showTranslation) {
		linkClass += ' decision-log__translate--active'
	}
	const label = showTranslation ? SHOW_ORIGINAL : SHOW_TRANSLATION

	const translateLink = (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div className={linkClass} onClick={_ => setShowTranslation(!showTranslation)}>
			{label}
		</div>
	)

	const _advice = showTranslation ? translatedAdvice : advice
	const _rationale = showTranslation ? translatedRationale : rationale
	const _author = showTranslation ? translatedAuthor : author

	return (
		<>
			<div className="decision-log__advice">
				<div className="decision-log__box" style={{ direction: getTextDirection(_advice) }}>
					{_advice}
				</div>
			</div>
			<div className="decision-log__rationale">
				<div className="decision-log__box" style={{ direction: getTextDirection(_rationale) }}>
					{_rationale}
				</div>
			</div>
			<div className="decision-log__metadata">
				<div className="decision-log__auth-and-time">
					{_author} - {timeFormatted}
				</div>
				{hasTranslation && translateLink}
			</div>
		</>
	)
}

export default DecisionLogRow
