import { connect } from 'react-redux'
import React from 'react'

import Actions from '../actions'

import SocketComms from '../core/SocketComms'

type SocketHandlerProps = {
	chatOpened: (details: SimpleObject) => void
	chatClosed: (details: SimpleObject) => void
	mainCallEnded: (details: SimpleObject) => void
	groupConnected: (details: SimpleObject) => void
	phoneLogUpdate: (details: SimpleObject) => void
	mainCallStarted: (details: SimpleObject) => void
	groupDisconnected: (details: SimpleObject) => void
	breakoutRoomsActivated: (details: SimpleObject) => void
	participantColourChange: (details: SimpleObject) => void
	breakoutRoomsDeactivated: (details: SimpleObject) => void
	interpreterChangeChannel: (details: SimpleObject) => void
	observerBreakoutRoomActivated: (details: SimpleObject) => void
	observerBreakoutRoomDeactivated: (details: SimpleObject) => void
}
class SocketHandler extends React.Component<SocketHandlerProps> {
	componentDidMount() {
		const {
			chatOpened,
			chatClosed,
			mainCallEnded,
			groupConnected,
			phoneLogUpdate,
			mainCallStarted,
			groupDisconnected,
			breakoutRoomsActivated,
			participantColourChange,
			breakoutRoomsDeactivated,
			observerBreakoutRoomActivated,
			observerBreakoutRoomDeactivated,
			interpreterChangeChannel,
		} = this.props

		SocketComms.on('participantlogin', (details: SimpleObject) => {
			groupConnected(details)
		})
		SocketComms.on('participantlogout', (details: SimpleObject) => {
			groupDisconnected(details)
		})
		SocketComms.on('maincallended', (details: SimpleObject) => {
			mainCallEnded(details)
		})
		SocketComms.on('maincallstarted', (details: SimpleObject) => {
			mainCallStarted(details)
		})
		SocketComms.on('breakoutroomsactivated', (details: SimpleObject) => {
			breakoutRoomsActivated(details)
		})
		SocketComms.on('breakoutroomsdeactivated', (details: SimpleObject) => {
			breakoutRoomsDeactivated(details)
		})
		SocketComms.on('breakoutroomsdeactivated', (details: SimpleObject) => {
			breakoutRoomsDeactivated(details)
		})
		SocketComms.on('observerbreakoutroomactivated', (details: SimpleObject) => {
			observerBreakoutRoomActivated(details)
		})
		SocketComms.on('observerbreakoutroomdeactivated', (details: SimpleObject) => {
			observerBreakoutRoomDeactivated(details)
		})
		SocketComms.on('chatopened', (details: SimpleObject) => {
			chatOpened(details)
		})
		SocketComms.on('chatclosed', (details: SimpleObject) => {
			chatClosed(details)
		})
		SocketComms.on('participantcolourchange', (details: SimpleObject) => {
			participantColourChange(details)
		})
		SocketComms.on('interpreterchangechannel', (details: SimpleObject) => {
			interpreterChangeChannel(details)
		})
		SocketComms.on('phonelogupdate', (details: SimpleObject) => {
			phoneLogUpdate(details)
		})
	}

	render() {
		return null
	}
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = () => ({})
const actions: Partial<SocketHandlerProps> = {
	chatOpened: Actions.socket.chatOpened,
	chatClosed: Actions.socket.chatClosed,
	mainCallEnded: Actions.socket.mainCallEnded,
	phoneLogUpdate: Actions.socket.phoneLogUpdate,
	groupConnected: Actions.socket.groupConnected,
	mainCallStarted: Actions.socket.mainCallStarted,
	groupDisconnected: Actions.socket.groupDisconnected,
	breakoutRoomsActivated: Actions.socket.breakoutRoomsActivated,
	participantColourChange: Actions.socket.participantColourChange,
	breakoutRoomsDeactivated: Actions.socket.breakoutRoomsDeactivated,
	observerBreakoutRoomActivated: Actions.socket.observerBreakoutRoomActivated,
	observerBreakoutRoomDeactivated: Actions.socket.observerBreakoutRoomDeactivated,
	interpreterChangeChannel: Actions.socket.interpreterChangeChannel,
}
export default connect(mapStateToProps, actions)(SocketHandler)
