/* eslint-disable no-param-reassign */
import produce from 'immer'

import getId from '../util/getId'

function CASES_ADD_NEW_FAIL(state: StateTree, action: ReduxAction): StateTree {
	const error = action.payload as string
	state.errors = [error]
	state.savingCase = false
	return state
}

function CASES_ADD_NEW_SUCCESS(state: StateTree, action: ReduxAction): StateTree {
	state.caseList.push(action.payload)
	state.savingCase = false
	return state
}

function CASES_ADDING_NEW(state: StateTree): StateTree {
	state.savingCase = true
	return state
}

function CASES_LOAD_CASELIST(state: StateTree, action: ReduxAction): StateTree {
	state.caseList = action.payload
	state.loadingCases = false
	return state
}

function CASES_START_LOAD_CASELIST(state: StateTree): StateTree {
	state.loadingCases = true
	return state
}

function CASES_DELETE_CASE(state: StateTree, action: ReduxAction): StateTree {
	const caseId = action.payload.toString()
	const index = state.caseList.findIndex(c => getId(c).toString() === caseId)
	state.caseList.splice(index, 1)

	if (state.openCase && getId(state.openCase) === caseId) {
		state.openCase = { data: {}, filename: '' }
		state.prevSessions = []
	}
	return state
}

function CASES_LOAD_CASE(state: StateTree, action: ReduxAction): StateTree {
	state.openCase = action.payload
	state.prevSessions = []
	return state
}

function CASES_UPDATING_CASE(state: StateTree): StateTree {
	state.savingCase = true
	return state
}

function CASES_UPDATED_CASE(state: StateTree, action: ReduxAction): StateTree {
	const { payload = {} } = action
	const caseId = payload.id
	const index = state.caseList.findIndex(c => c.id === caseId)
	if (index >= 0) state.caseList[index] = { ...state.caseList[index], ...payload }
	state.savingCase = false
	return state
}

// =================================================================================================

const reducers = {
	CASES_ADD_NEW_FAIL,
	CASES_ADD_NEW_SUCCESS,
	CASES_LOAD_CASELIST,
	CASES_START_LOAD_CASELIST,
	CASES_DELETE_CASE,
	CASES_LOAD_CASE,
	CASES_UPDATING_CASE,
	CASES_UPDATED_CASE,
	CASES_ADDING_NEW,
}

// =================================================================================================

function handler(state: StateTree, action: ReduxAction): StateTree {
	const _action: ReduxAction = action || { type: '', payload: {} }
	const { type } = _action

	// If we have a reducer that matches the action type, apply it
	if (reducers[type]) {
		return produce(state, _newState => reducers[type](_newState, _action))
	}

	return state
}

export default handler
