/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import config from '../../config'
import Button from '../elements/Button'
import IconButton from '../elements/IconButton'

type PreviousSessionProps = {
	selected?: boolean
	previousSession: PreviousSession
	allowDelete?: boolean
	showExportOptions?: boolean
	clickSession?: (session: SimpleObject) => unknown
	deleteSession?: (session: SimpleObject) => unknown
	exportOriginalSession?: (session: SimpleObject) => unknown
	clickShowExportOptions?: (session: SimpleObject) => unknown
	exportTranslatedSession?: (session: SimpleObject) => unknown
}

const PreviousSession: React.FC<PreviousSessionProps> = (props: PreviousSessionProps) => {
	// function PreviousSession(props: PreviousSessionProps): ReactElement<PreviousSessionProps> {
	const { DOWNLOAD_VERBATIM, DOWNLOAD_TRANSLATED, SESSION } = config.strings

	const {
		selected = false,
		previousSession = {} as PreviousSession,
		allowDelete = true,
		clickSession,
		deleteSession,
		exportOriginalSession,
		exportTranslatedSession,
		showExportOptions = false,
		clickShowExportOptions,
	} = props

	// const [showExportOptions, setShowExportOptions] = useState(false)

	let className = 'prev-session'
	if (selected) {
		className += ' prev-session--selected'
	}

	let buttons
	if (showExportOptions) {
		buttons = (
			<>
				<Button onClick={_ => exportOriginalSession(previousSession)} text={DOWNLOAD_VERBATIM} />
				<Button onClick={_ => exportTranslatedSession(previousSession)} text={DOWNLOAD_TRANSLATED} />
			</>
		)
	} else {
		buttons = (
			<>
				<IconButton iconName="download" onClick={_ => clickShowExportOptions(previousSession)} />
				{allowDelete && <IconButton iconName="trash-alt" onClick={_ => deleteSession(previousSession)} />}
			</>
		)
	}

	return (
		<div className={className} onClick={_ => clickSession(previousSession)}>
			<div className="left">
				<h2>
					{SESSION} {previousSession.index || 0}
				</h2>
				<span className="prev-session__time">
					{new Date(parseInt(previousSession.timestamp, 10)).toString().substr(0, 21)}
				</span>
			</div>
			<div className="right">{buttons}</div>
		</div>
	)
}

export default PreviousSession
