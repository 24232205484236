import OnlineComms from '../core/OnlineComms'
import history from '../core/history'
import action from '../util/action'
import types from './types'

// An ActionCreator is any function that (optionally) accepts some parameters and returns a function
// that can be called by Redux. This would be a function that takes a Dispatch, GetState objects and
// returns nothing (or a Promise for void if it's an async function that returns nothing).
// Technically, this is a "Thunk" type of Action Creator.
// prettier-ignore
type ActionCreator<T1 = null, T2 = null, T3 = null> = 
	(arg1?: T1, arg2?: T2, arg3?: T3) => (dispatch: Dispatch, getState: GetState) => void | Promise<void>

// =================================================================================================

const clearErrors: ActionCreator = () => dispatch => {
	dispatch(action(types.CLEAR_ERRORS))
}

const initialiseApp: ActionCreator = () => dispatch => {
	let lang = localStorage.getItem('language')
	// Store language if we didn't find one
	if (!lang) {
		lang = 'EN'
		localStorage.setItem('language', lang)
	}
	dispatch(action(types.GROUPS_CHANGED_LANGUAGE, lang))

	let groupId = localStorage.getItem('groupid')
	// Generate if not found
	groupId = groupId || String(Math.floor(Math.random() * 1000000000))
	localStorage.setItem('groupid', groupId)
	dispatch(action(types.GROUP_GENERATED_ID, groupId))
}

const navigateTo: ActionCreator<string> = (location: string) => dispatch => {
	const locationsMap = {
		'group-view': '/participant',
		'tutor-view': '/facilitator',
		'tutor-media': '/facilitator/media',
		'tutor-cases': '/facilitator/cases',
		'tutor-groups': '/facilitator/groups',
		'tutor-settings': '/facilitator/settings',
		'tutor-sessions': '/facilitator/sessions',
		'tutor-messages': '/facilitator/messages',
		'tutor-videoconf': '/facilitator/videoconf',
		'tutor-passcodes': '/facilitator/passcodes',
		'tutor-prevsessions': '/facilitator/prevsessions',
		'tutor-participants': '/facilitator/participants',
		'tutor-linkedaccesspasswords': '/facilitator/observers',
		'login-participantphone': '/login/phone',
		login: '/login',
		'': '/',
	}
	dispatch(action(types.NAVIGATING_TO, location))
	const newLocation = locationsMap[location] || '/'
	history.push(newLocation)
}

const displayModalPopup: ActionCreator<string, SimpleObject> = (location, parms = {}) => dispatch => {
	dispatch(action(types.NAVIGATING_TO_MODAL, { location, parms }))
}

const getIpAddress: ActionCreator = () => async dispatch => {
	try {
		const resp = (await OnlineComms.getIpAddress()) as { ipAddress: string }
		dispatch(action(types.GOT_IP_ADDRESS, resp.ipAddress))
	} catch (err) {
		console.error('Error fetching server IP', err)
	}
}

const getLicence: ActionCreator = () => async dispatch => {
	const licence = await OnlineComms.getLicence()
	if (licence) dispatch(action(types.TUTOR_GOT_LICENCE, licence))
}

const actions = { clearErrors, initialiseApp, navigateTo, displayModalPopup, getLicence, getIpAddress }

export default actions
