// =================================================================================================
// Components > Language Selector
// =================================================================================================

import React, { FunctionComponent } from 'react'
import Select from 'react-select'
import { SelectComponents } from 'react-select/src/components'
import { GroupTypeBase } from 'react-select/src/types'

import languages from '../../config/languages.json'
import googleLanguages from '../../config/languagesSupportedByGoogle.json'
// https://cloud.google.com/translate/docs/languages

// Going to extraordinary lengths to get the type of a CustomOption element to be used in a React Select dropdown list
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OptionType = { value: string; label: any }
type CustomOptionType = SelectComponents<OptionType, false, GroupTypeBase<OptionType>>['Option']

const CustomOption: CustomOptionType = props => {
	const { innerProps, isDisabled, label, isFocused, data } = props
	const { value } = data
	// Get the native spelling of the language name from our languages file
	const langObj = languages[value] || {}

	let className = 'language-selector__option'
	if (isFocused) {
		className += ' language-selector__option--focused'
	}

	return !isDisabled ? (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<div className={className} {...innerProps}>
			<span>{label}</span>
			<span>{(langObj.native || label).split('/')[0]}</span>
		</div>
	) : null
}

type LanguageSelectorProps = {
	onChange: (value: string) => void
	value?: string
}

const LanguageSelector: FunctionComponent<LanguageSelectorProps> = props => {
	const { onChange, value = 'EN' } = props

	const langCodes = Object.keys(googleLanguages).filter(code => Boolean(languages[code]))

	const options = langCodes.map(code => ({ value: code, label: googleLanguages[code] }))

	return (
		<div className="language-selector input">
			<Select
				defaultValue={options.find(o => o.value === value.toLowerCase())}
				onChange={o => onChange((o.value || '').toUpperCase())}
				classNamePrefix="language-selector"
				options={options}
				components={{ Option: CustomOption }}
			/>
		</div>
	)
}

export default LanguageSelector
