function recentMessageFromTutor(conversation: Message[], serverTimeDiff = 0): boolean {
	const time = new Date().getTime()
	const diff = serverTimeDiff || 0
	const now = time + diff

	// Check if there is a recent message from tutor
	// const groupData = state.groupData || {}
	const twentySecsAgo = now - 20 * 1000
	const convo = conversation || []
	const messagesFromTutor = convo.filter(m => m.tutor)
	const recentMessage = messagesFromTutor.find(m => parseInt(m.timestamp || 0) > twentySecsAgo)
	return !!recentMessage
}

export default recentMessageFromTutor
