/* eslint-disable jsx-a11y/media-has-caption */

import React, { FunctionComponent, useEffect, useRef } from 'react'
import { endBroadcast, startBroadcast } from '../core/VideoImageCache'

type VideoElementProps = {
	track: JitsiTrack
	uploadToImageCache: boolean
	participantId: string
	facilitatorId: string
}

const VideoElement: FunctionComponent<VideoElementProps> = props => {
	const elem = useRef<HTMLVideoElement>(null)
	const { track, uploadToImageCache, participantId, facilitatorId } = props

	useEffect(() => {
		track.attach(elem.current)
		return () => {
			elem.current.srcObject = null
			elem.current.removeAttribute('src')
		}
	}, [track])

	// Upload camera image to video image cache service if required
	useEffect(() => {
		if (!uploadToImageCache || !participantId || !facilitatorId || !elem.current) return
		const ref = startBroadcast(participantId, facilitatorId, elem.current)
		return () => endBroadcast(ref)
	}, [uploadToImageCache, participantId, facilitatorId])

	return <video ref={elem} autoPlay />
}

export default VideoElement
