export default {
	/* Team view */
	OK: 'Добре',
	CANCEL: 'Отказ',
	TUTOR: 'Фасилитатор',
	CURRENT_SESSION: 'Текуща сесия',
	TEAM: 'Екип',
	SIGNOUT: 'Излез от профила си',
	INITIAL_UPDATE: 'Информация за сесията',
	ADVICE: 'Решение',
	RATIONALE: 'Обосновка',
	MEDIA_FEED: 'Информация от медиите',
	MESSAGES: 'Съобщения',
	PREVSESSIONS: 'Предходни сесии',
	RETURN_TO_CURRENT: 'Върнете се към текущата сесия',
	SESSION_IS_PAUSED: 'В момента сесията е в ПАУЗА',
	ATTRIB_CURR_AUTHOR: 'Лице, вземащо решенията',
	SAVE: 'Запази',
	CHANGE: 'Промени',
	NO_AUTHOR: 'Не е зададен',
	NO_INITIAL_INFO: 'Моля, изчакайте да започне следващата сесия',
	DECISION_MAKER_SELECT: 'Моля, изберете лице, което ще взема решенията',
	SESSION_COMPLETE: 'Предходната сесия приключи',
	OPENED_AT: 'Отвори при',
	SEND_MESSAGE_PLACEHOLDER: 'Напиши съобщение',
	SEND: 'Изпрати',
	TO_ALL_GROUPS: 'Към всички групи',
	SHOW_TRANSLATION: 'Покажи превода',
	SHOW_ORIGINAL: 'Покажи оригинала',
	CREDENTIALS_EXPIRED: 'Вашата парола е изтекла. Моля, да влезете отново',
	INCOMING_MESSAGE: 'Входящо съобщение',
	INCOMING_MESSAGE_TEXT: 'Получено е ново съобщение',
	INCOMING_MEDIA: 'Входящо медийно съобщение',
	INCOMING_MEDIA_TEXT: 'Получено е ново медийно съобщение',
	INCOMING_UPDATE: 'Входяща  актуализация',
	INCOMING_UPDATE_TEXT: 'Получена е актуализация',
	CONFIRM_LOGOUT: 'Наистина ли искате да излезете?',
	MUTED_MESSAGE: 'Вашият микрофон е изключен',
	NEWSTYPE_UPDATE: 'Актуализацията е получена',
	WARNING_TIME_MSG: 'Остават 5 минути',
}
