import React from 'react'

// This was a saviour when pdfjs-dist stopped working after switching to the parcel builder:
import { Document, Page } from 'react-pdf/dist/entry.parcel'
// import Slider from '../elements/Slider'

type PDFViewerProps = {
	src: string
}
type PDFViewerState = {
	numPages: number
}
export default class PDFViewer extends React.Component<PDFViewerProps, PDFViewerState> {
	constructor(props: PDFViewerProps) {
		super(props)
		this.state = { numPages: null }
		this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this)
	}

	onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
		this.setState({ numPages })
	}

	render(): JSX.Element {
		const { src } = this.props
		const { numPages } = this.state

		const viewportWidth = Math.min(document.body.clientWidth - 400, 1200)

		return (
			<div className="pdf-viewer">
				<Document file={src} onLoadSuccess={this.onDocumentLoadSuccess}>
					{/* For 1 to numPages... create a Page element */}
					{Array.from(new Array(numPages), (el, index) => (
						<Page key={`page_${index + 1}`} pageNumber={index + 1} width={viewportWidth} />
					))}
				</Document>
			</div>
		)
	}
}
