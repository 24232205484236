/* eslint-disable react/jsx-props-no-spreading */

import React, { FunctionComponent } from 'react'
import { CSSTransition } from 'react-transition-group'

const Transition: FunctionComponent<{ className: string }> = props => {
	const { className, children, ...otherProps } = props
	return (
		<CSSTransition classNames={className} timeout={300} unmountOnExit {...otherProps}>
			<div>{children}</div>
		</CSSTransition>
	)
}
export default Transition
