import { connect } from 'react-redux'
import React, { FunctionComponent } from 'react'

import InputFieldManager from './InputFieldManager'
import LanguageSelector from '../elements/LanguageSelector'
import Button from '../elements/Button'
import Input from '../elements/Input'
import Icon from '../elements/Icon'
import Error from '../elements/Error'

import Actions from '../actions'
import config from '../../config'

type LoginGroupProps = {
	errors?: string[]
	language?: string
	clearErrors?: () => void
	onClickBack?: () => void
	loginAsGroup?: (passcode: string) => void
	switchGroupLanguage?: (lang: string) => void
}

const LoginGroup: FunctionComponent<LoginGroupProps> = props => {
	const { switchGroupLanguage, language, onClickBack, loginAsGroup, errors, clearErrors } = props
	const { PASSCODE, BACK, OK } = config.strings

	const onOK = () => {
		clearErrors()
		loginAsGroup(InputFieldManager.getValue('password'))
	}

	return (
		<div className="login-group">
			<div className="login-group__main">
				<div className="login__input-row">
					<Icon name="key" />
					<Input name="password" focus onEnter={onOK} placeholder={PASSCODE} />
				</div>
				<div className="login__input-row">
					<Icon name="globe" />
					<LanguageSelector onChange={value => switchGroupLanguage(value)} value={language} />
				</div>
			</div>
			<div className="login__error-space">
				{errors.map(error => (
					<Error key={error}>{error}</Error>
				))}
			</div>
			<div className="login__otherbuttons">
				<Button text={OK} onClick={onOK} />
				<Button level="secondary" text={BACK} onClick={onClickBack} />
			</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	const group = state.group || ({} as GroupDetails)
	return {
		language: group.language || 'EN',
		errors: state.errors || [],
	}
}
const actions = {
	switchGroupLanguage: Actions.groups.switchLanguage,
	loginAsGroup: Actions.login.loginAsGroup,
	clearErrors: Actions.misc.clearErrors,
}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Omit<LoginGroupProps, keyof (PropsFromState & ReduxActions)>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(LoginGroup)
