import React from 'react'
import { connect } from 'react-redux'

import InputFieldManager from './InputFieldManager'
import Button from '../elements/Button'
import Input from '../elements/Input'
import Icon from '../elements/Icon'

import Actions from '../actions'
import config from '../../config'

type LoginTutorProps = {
	error?: string
	loginAsTutor: (username: string, password: string) => Promise<any>
	onClickBack: () => void
}

function LoginTutor(props: LoginTutorProps) {
	const { error, onClickBack, loginAsTutor } = props
	const { EMAIL, PASSWORD, OK, BACK } = config.strings

	const onClickOK = () => {
		const username = InputFieldManager.getValue('username')
		const password = InputFieldManager.getValue('password')
		loginAsTutor(username, password)
	}

	return (
		<div className="login__tutor">
			<div className="login__main">
				<div className="login__input-row">
					<Icon name="at" />
					<Input name="username" type="username" onEnter={onClickOK} focus="true" placeholder={EMAIL} />
				</div>
				<div className="login__input-row">
					<Icon name="key" />
					<Input name="password" type="password" onEnter={onClickOK} placeholder={PASSWORD} />
				</div>
			</div>

			<div className="login__error-space">{error && <div className="error">{error}</div>}</div>

			<div className="login__otherbuttons">
				<Button text={OK} onClick={onClickOK} />
				<Button level="secondary" text={BACK} onClick={onClickBack} />
			</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = state => ({
	error: state.tutor.loginError,
})
const actions = {
	loginAsTutor: Actions.login.loginAsTutor,
}
export default connect(mapStateToProps, actions)(LoginTutor)
