/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

import SessionHelpers from '../util/SessionHelpers'

function checkHideWaitingRoom(state: StateTree, action: ReduxAction) {
	const { settings = {}, videoconf = {}, welcomeMessageDisplayed = false, navigatingTo, navigatingToModal } = state
	const { waitingRoomEnabled } = settings
	const waitingRoomMessage = (settings.waitingRoomMessage || '').trim()
	const currentSession = state?.groupData?.currentSession

	const { mainCallStarted, mainCallEnded } = videoconf
	const mainCallActive = mainCallStarted && mainCallEnded && mainCallStarted > mainCallEnded

	// eslint-disable-next-line import/no-named-as-default-member
	const sessionIsActive = SessionHelpers.sessionIsActive(currentSession)

	// Check if current state implies that we should display the welcome message
	const showWelcomeMessage =
		waitingRoomEnabled && waitingRoomMessage && !sessionIsActive && !mainCallActive && navigatingTo === 'group-view'

	// If we should not show it, then ensure it is hidden
	if (!showWelcomeMessage && state.showWelcomeMessage) {
		return { ...state, showWelcomeMessage: false }
	}
	return state
}

function SHOW_WAITING_ROOM_MESSAGE(state: StateTree, action: ReduxAction) {
	return { ...state, showWelcomeMessage: true, welcomeMessageDisplayed: true }
}

function CLEAR_ERRORS(state: StateTree, action: ReduxAction) {
	return {
		...state,
		errors: null,
		tutor: { ...state.tutor, loginError: null },
		group: { ...state.group, loginError: null },
	}
}

//==================================================================================================

const reducers = {
	SHOW_WAITING_ROOM_MESSAGE,
	CLEAR_ERRORS,
}

// =================================================================================================

function handler(state: StateTree, action: ReduxAction): StateTree {
	let newState = state
	if (reducers[action.type]) {
		newState = reducers[action.type](state, action)
	}
	// Always perform check for waiting room
	newState = checkHideWaitingRoom(newState, action)
	return newState
}

export default handler
