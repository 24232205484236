import linkedAccessReducer from './linkedAccess'
import participantsReducer from './participants'
import passwordsReducer from './passwords'
import messagesReducer from './messages'
import navigateReducer from './navigate'
import sessionReducer from './sessions'
import groupsReducer from './groups'
import casesReducer from './cases'
import mediaReducer from './media'
import tutorReducer from './tutor'
import loginReducer from './login'
import socketReducer from './socket'
import settingsReducer from './settings'
import videoconfReducer from './videoconf'
import translationsReducer from './translate'
import miscReducer from './misc'
import chatReducer from './chat'

const delegator: Reducer = (state, action) => {
	let newState = state
	newState = linkedAccessReducer(newState, action)
	newState = participantsReducer(newState, action)
	newState = passwordsReducer(newState, action)
	newState = videoconfReducer(newState, action)
	newState = messagesReducer(newState, action)
	newState = navigateReducer(newState, action)
	newState = sessionReducer(newState, action)
	newState = groupsReducer(newState, action)
	newState = casesReducer(newState, action)
	newState = mediaReducer(newState, action)
	newState = tutorReducer(newState, action)
	newState = loginReducer(newState, action)
	newState = socketReducer(newState, action)
	newState = settingsReducer(newState, action)
	newState = translationsReducer(newState, action)
	newState = miscReducer(newState, action)
	newState = chatReducer(newState, action)

	if (!newState) {
		console.error(`Error: No state after web reducer: ${action.type}`)
	}

	if (window) window.state = newState

	return newState
}

export default delegator
