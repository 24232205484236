import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import StatusBarMessage from '../components/StatusBarMessage'
import Countdown from './Countdown'

import config from '../../config'

type StatusBarProps = {
	currentCase: Case
	currentSession: CurrentSession | PreviousSession
}

const StatusBar: FunctionComponent<StatusBarProps> = props => {
	// eslint-disable-next-line react/destructuring-assignment
	const { currentCase, currentSession } = props
	const sessionIndex = currentSession.index

	return (
		<div className="group-status-bar">
			<div className="group-status-bar__logo" />
			<div className="case-title">{currentCase.title || 'View 360 Global'}</div>
			{sessionIndex && <div className="label">{config.strings.CURRENT_SESSION}</div>}
			{sessionIndex && <div className="value">{sessionIndex}</div>}
			<div style={{ flexGrow: 1 }}>
				<StatusBarMessage />
			</div>
			<Countdown session={currentSession} />
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree) => {
	const groupData = state.groupData || {}
	const currentCase = state.openCase || groupData.currentCase || {}
	const currentSession = state.currentSession || groupData.currentSession || {}
	return { currentCase, currentSession }
}
const actions = {}
export default connect(mapStateToProps, actions)(StatusBar)
