/* eslint-disable react/jsx-props-no-spreading */
import React, { FunctionComponent } from 'react'
import getTextDirection from '../util/getTextDirection'

type TextareaProps = {
	onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
	className?: string
	placeholder?: string
	value?: string
}

const Textarea: FunctionComponent<TextareaProps> = props => {
	const { value, onChange, className = '', children, ...otherProps } = props
	return (
		<div className={`textarea ${className}`}>
			<div className="textarea__resizer">{`${value}\n`}</div>
			<textarea onChange={onChange} value={value} style={{ direction: getTextDirection(value) }} {...otherProps}>
				{children}
			</textarea>
		</div>
	)
}

export default Textarea
