/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FunctionComponent, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import config from '../../config'

import Actions from '../actions'
import calculateTranslationHash from '../util/calculateTranslationHash'

import getTextDirection from '../util/getTextDirection'

type WaitingRoomModalProps = {
	message?: string
	showWelcomeMessage?: boolean
	facilitatorLanguage?: string
	language?: string
	translate?: (text: string, toLang: string, fromLang: string) => void
	translations?: StateTree['translations']
}

const WaitingRoomModal: FunctionComponent<WaitingRoomModalProps> = props => {
	const { message, showWelcomeMessage } = props
	const { language, facilitatorLanguage, translate, translations } = props
	const show = message && showWelcomeMessage

	const { SHOW_TRANSLATION, SHOW_ORIGINAL } = config.strings

	const [showOriginal, setShowOriginal] = useState(false)

	useEffect(() => {
		if (show) {
			document.body.classList.add('waiting-room-active')
		} else {
			document.body.classList.remove('waiting-room-active')
		}
	}, [show])

	const translationHash = calculateTranslationHash(message, facilitatorLanguage, language)
	useEffect(() => {
		if (!translations[translationHash]) translate(message, language, facilitatorLanguage)
	}, [translationHash])

	if (!show) return null

	// eslint-disable-next-line react/no-array-index-key
	const translation = translations[translationHash]
	console.log('translation', translation)
	const text = showOriginal ? message : translation || message

	// eslint-disable-next-line react/no-array-index-key
	const paragraphs = text.split('\n').map((str, i) => <p key={i}>{str}</p>)

	return (
		<div className="waiting-room-modal">
			<div className="waiting-room-modal__main" role="dialog">
				<div className="waiting-room-modal__header">
					<div className="waiting-room-modal__logo" />
				</div>
				<div className="waiting-room-modal__content" style={{ direction: getTextDirection(text) }}>
					{paragraphs}
					{translation && (
						<div className="message__translate" onClick={() => setShowOriginal(!showOriginal)} role="button">
							{showOriginal ? SHOW_TRANSLATION : SHOW_ORIGINAL}
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

// =================================================================================================
const mapStateToProps = (state: StateTree) => ({
	message: (state.settings || {}).waitingRoomMessage,
	showWelcomeMessage: state?.showWelcomeMessage,
	language: state?.group?.language || 'EN',
	translations: state?.translations || {},
	facilitatorLanguage: 'EN',
})
const actions = {
	displayModalPopup: Actions.misc.displayModalPopup,
	translate: Actions.translate.translate,
}
export default connect(mapStateToProps, actions)(WaitingRoomModal)
