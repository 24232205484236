import React, { FunctionComponent } from 'react'
import { connect } from 'react-redux'

import recentMessageFromTutor from '../util/recentMessageFromTutor'
import BubbleHeading from '../elements/BubbleHeading'
import MessagesPanel from './MessagesPanel'
import config from '../../config'

type GroupMessagesPanelProps = {
	groupId: string
	serverTimeDiff?: number
	conversation: Message[]
	currentSession: CurrentSession
	viewingPreviousSession?: boolean
	previousSession?: PreviousSession
}

const GroupMessagesPanel: FunctionComponent<GroupMessagesPanelProps> = props => {
	const { groupId, serverTimeDiff, conversation, viewingPreviousSession, previousSession, currentSession } = props
	const { MESSAGES } = config.strings

	const sortAscending = (a, b) => a.timestamp - b.timestamp

	const session =
		(viewingPreviousSession ? previousSession : currentSession) || ({} as CurrentSession | PreviousSession)

	let allGroupMessages = session.allGroupMessages || []

	if (viewingPreviousSession && previousSession) {
		const conversations = previousSession.conversations || {}
		allGroupMessages = conversations.all || []
	}

	const msgsToAllGrps = allGroupMessages.map(o => ({ ...o, tutor: true, allGroups: true }))

	const fullConversation = (conversation || []).concat(msgsToAllGrps).sort(sortAscending)

	const isRecentMessage = recentMessageFromTutor(fullConversation, serverTimeDiff)

	return (
		<>
			<BubbleHeading alert={isRecentMessage}>{MESSAGES}</BubbleHeading>
			<MessagesPanel alert={isRecentMessage} toTutor groupId={groupId} data={fullConversation} />
		</>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = state => {
	const groupId = state.group.id
	const groupData = state.groupData || {}
	let conversation = groupData.conversation || []

	const { viewingPreviousSession } = state
	const previousSession = state.previousSession || {}

	// If viewing previous session, get conversation from there
	if (viewingPreviousSession) {
		const allConversations = previousSession.conversations || {}
		conversation = allConversations[groupId] || []
	}

	return {
		groupId,
		serverTimeDiff: state.serverTimeDiff || 0,
		conversation,
		viewingPreviousSession,
		currentSession: groupData.currentSession || {},
		previousSession,
	}
}
const actions = {}
export default connect(mapStateToProps, actions)(GroupMessagesPanel)
