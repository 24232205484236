import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import React from 'react'

import App from './web/pages/App'

import store from './web/store/store'
import config from './config'

import packageJson from '../package.json'

const isLocalhost = window.location.hostname === 'localhost'
const { SENTRY_ID, SENTRY_ENV } = config

// Initialise Sentry
if (SENTRY_ID && !isLocalhost) {
	Sentry.init({
		dsn: SENTRY_ID,
		environment: SENTRY_ENV || 'dev',
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 1.0,
		release: `${packageJson.name}@${packageJson.version}`,
	})
	Sentry.captureMessage('Initialising view360-web')
}

if (!('ontouchstart' in document)) {
	document.body.classList.add('no-touch')
}

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
)
