type ParticipantsToDisplayParameters = {
	remoteParticipants: Array<JitsiParticipantDetails>
	localParticipant: JitsiParticipantDetails
	interpreters: Array<InterpreterDetails>
	selectedInterpreterId: string
	loggedInAsParticipant: boolean
	loggedInAsFacilitator: boolean
	loggedInAsObserver: boolean
	loggedInAsInterpreter: boolean
	interpreterChannel: string
	muteMainAudio?: boolean
}

type ParticipantsToDisplayResult = {
	participant: JitsiParticipantDetails
	subheading?: string
	infoMessage?: string
	mute?: boolean
	volume?: number
}

interface ICalculateVideoCallParticipantsToDisplay {
	(params: ParticipantsToDisplayParameters): Array<ParticipantsToDisplayResult>
}

const LOW_VOLUME_LEVEL = 0.1

const calculateVideoCallParticipantsToDisplay: ICalculateVideoCallParticipantsToDisplay = params => {
	const { remoteParticipants, localParticipant, interpreters, selectedInterpreterId, muteMainAudio } = params
	const { loggedInAsFacilitator, loggedInAsParticipant, loggedInAsObserver } = params
	const { loggedInAsInterpreter, interpreterChannel } = params
	const results: Array<ParticipantsToDisplayResult> = []

	const selectedInterpreterParticipantDetails =
		selectedInterpreterId && remoteParticipants.find(p => p?.properties?.clientId === selectedInterpreterId)

	const localUserHasSelectedInterpreter = Boolean(selectedInterpreterParticipantDetails)
	const localSelectedInterpreterId = localUserHasSelectedInterpreter && selectedInterpreterId

	remoteParticipants.forEach(participant => {
		let mute = false
		let subheading = ''
		let infoMessage = ''
		let volume = 1

		const pClientId = participant?.properties?.clientId
		const localClientId = localParticipant?.properties?.clientId
		const interpreterDetails = interpreters.find(i => i.clientId === pClientId)

		const pIsObserver = Boolean(pClientId)
		const pIsInterpreter = Boolean(pIsObserver && interpreterDetails)
		const pIsFacilitator = participant?.properties?.isFacilitator === 'true'
		const pIsParticipant = Boolean(participant?.properties?.participantId)
		const pSelectedInterpreterId = participant?.properties?.selectedInterpreter

		// Get details for the participants selected interpreter (if they have one and they are still in the call)
		const pInterpreterDetails =
			pSelectedInterpreterId &&
			(remoteParticipants.find(p => p?.properties?.clientId === pSelectedInterpreterId) ||
				localClientId === pSelectedInterpreterId) &&
			interpreters.find(i => i.clientId === pSelectedInterpreterId)

		// If this participant is an interpreter, indicate this next to their display name
		if (pIsInterpreter) {
			const { channel, onMainChannel } = interpreterDetails

			// Hide interpreters from main view for participants
			if (loggedInAsParticipant && !onMainChannel) {
				return null
			}
			// Hide interpreter if that interpreter has been selected/is already being shown
			if (!onMainChannel && pClientId === selectedInterpreterId && selectedInterpreterParticipantDetails) {
				return null
			}
			if (loggedInAsInterpreter && !onMainChannel) {
				return null
			}
			if (!onMainChannel) {
				infoMessage = `Broadcasting on ${channel} channel`
				mute = true
			}
		} else if (pInterpreterDetails) {
			let showChannelInfoMessage = false

			// If I am an observer/interpreter and this participant is on a different channel to mine...
			if ((loggedInAsObserver || loggedInAsInterpreter) && pSelectedInterpreterId !== localClientId) {
				showChannelInfoMessage = true
			}
			if (loggedInAsFacilitator) {
				showChannelInfoMessage = true
			}
			if (loggedInAsParticipant && pSelectedInterpreterId !== localSelectedInterpreterId) {
				showChannelInfoMessage = true
			}
			if (showChannelInfoMessage) {
				infoMessage = `Listening on ${pInterpreterDetails.channel} channel`
				volume = 0
			}
		}

		// If we are an interpreter and the participant is listening on the main channel, display a message
		if (loggedInAsInterpreter && interpreterChannel && !pSelectedInterpreterId && !pIsFacilitator) {
			infoMessage = `Listening on Main channel`
		}

		// If the local user is a participant listening to an interpreter, then lower the volume of the facilitator
		if (
			loggedInAsParticipant &&
			localUserHasSelectedInterpreter &&
			(pIsFacilitator || (pIsObserver && !pIsInterpreter))
		) {
			volume = muteMainAudio ? 0 : LOW_VOLUME_LEVEL
		}
		// If the local user is a participant listening to an interpreter, also lower the volume of other participants
		// who are not on the same channel as us
		if (
			loggedInAsParticipant &&
			localUserHasSelectedInterpreter &&
			pIsParticipant &&
			pSelectedInterpreterId !== localSelectedInterpreterId
		) {
			volume = muteMainAudio ? 0 : LOW_VOLUME_LEVEL
		}

		// Sort out subheading
		if (!pIsInterpreter && pIsObserver) {
			subheading = `Observer`
		}
		if (pIsFacilitator && participant.displayName !== 'Facilitator') {
			subheading = 'Facilitator'
		}
		if (pIsInterpreter) {
			const { channel } = interpreterDetails
			subheading = `Interpreter - ${channel}`
		}

		results.push({ participant, infoMessage, subheading, mute, volume })
	})

	return results
}

export default calculateVideoCallParticipantsToDisplay
