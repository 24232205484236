/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { connect } from 'react-redux'
import React from 'react'

import ContentLoadingWrapper from '../components/ContentLoadingWrapper'
import PreviousSession from '../components/PreviousSession'
import BubbleHeading from '../elements/BubbleHeading'
import config from '../../config'
import Actions from '../actions'

class TutorPrevSessions extends React.PureComponent {
	constructor(props) {
		super(props)
		this.state = { selectedForExport: null }
	}

	componentDidMount() {
		const { getPreviousSessions } = this.props
		getPreviousSessions()
	}

	_renderSession(session) {
		const { previousSession, loadPreviousSession, exportSession, linkedAccess, deletePreviousSession } = this.props
		const { selectedForExport } = this.state

		const onClick = s => {
			loadPreviousSession(s.id)
			this.setState({ selectedForExport: null })
		}
		const exportOriginal = s => {
			exportSession(s.filename, s.id, false)
			this.setState({ selectedForExport: null })
		}
		const exportTranslated = s => {
			exportSession(s.filename, s.id, true)
			this.setState({ selectedForExport: null })
		}
		const deleteSession = s => {
			deletePreviousSession(s.id)
		}
		const onClickExport = s => {
			this.setState({ selectedForExport: s.id })
		}

		const selected = session.id === previousSession.id
		return (
			<PreviousSession
				key={session.id}
				selected={selected}
				clickSession={onClick}
				previousSession={session}
				allowDelete={!linkedAccess}
				deleteSession={deleteSession}
				clickShowExportOptions={onClickExport}
				exportOriginalSession={exportOriginal}
				exportTranslatedSession={exportTranslated}
				showExportOptions={selectedForExport === session.id}
			/>
		)
	}

	render() {
		const { prevSessions, loadingPreviousSessions } = this.props

		const content = prevSessions.map(this._renderSession.bind(this))

		return (
			<div className="tutor-prev-sessions">
				<div className="tutor-prev-sessions__top">
					<BubbleHeading>{config.strings.PREVSESSIONS}</BubbleHeading>
				</div>
				<div className="tutor-prev-sessions__content">
					<ContentLoadingWrapper loading={loadingPreviousSessions}>{content}</ContentLoadingWrapper>
				</div>
			</div>
		)
	}
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = state => ({
	linkedAccess: state.linkedAccess && state.linkedAccess.connected,
	loadingPreviousSessions: state.loadingPreviousSessions || false,
	previousSession: state.previousSession || {},
	prevSessions: state.prevSessions || [],
})
const actions = {
	exportSession: Actions.sessions.exportSession,
	getPreviousSessions: Actions.sessions.getPreviousSessions,
	deletePreviousSession: Actions.sessions.deletePreviousSession,
	loadPreviousSession: Actions.sessions.loadPreviousSessionForFacilitator,
}
export default connect(mapStateToProps, actions)(TutorPrevSessions)
