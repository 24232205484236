/* eslint-disable @typescript-eslint/no-unused-vars */
import { v4 as uuid } from 'uuid'
import md5 from 'md5'

import facilitatorIdSelector from '../selectors/facilitatorId'
import OnlineComms from '../core/OnlineComms'
import SocketComms from '../core/SocketComms'
import action from '../util/action'
import types from './types'

import loggedInAsParticipantSelector from '../selectors/loggedInAsParticipant'
import loggedInAsObserverSelector from '../selectors/loggedInAsObserver'
import loggedInAsFacilitatorSelector from '../selectors/loggedInAsFacilitator'

const {
	VIDEOCONF_INTERPRETER_CHANGED_CHANNEL,
	VIDEOCONF_CHANGED_INTERPRETER,
	VIDEOCONF_GOT_SERVER_REGIONS,
	VIDEOCONF_GOT_SERVER_STATUS,
	VIDEOCONF_CHANGED_AUDIO_OUT,
	VIDEOCONF_SERVER_STARTING,
	VIDEOCONF_SERVER_STOPPING,
	VIDEOCONF_JOINED_BREAKOUT,
	VIDEOCONF_CHANGED_CAMERA,
	VIDEOCONF_SELECT_REGION,
	VIDEOCONF_LEFT_BREAKOUT,
	VIDEOCONF_CHAT_TOGGLED,
	VIDEOCONF_CHANGED_MIC,
	SOCKET_OBSERVER_BREAKOUT_DEACTIVATED,
	SOCKET_OBSERVER_BREAKOUT_ACTIVATED,
	SOCKET_BREAKOUT_ROOMS_DEACTIVATED,
	SOCKET_BREAKOUT_ROOMS_ACTIVATED,
	SOCKET_MAIN_CALL_STARTED,
	SOCKET_MAIN_CALL_ENDED,
	TEXT_TRANSLATED,
} = types

let holdoff = false
let holdoffTimeout = null

function pauseServerStatusUpdates(ms = 3000) {
	holdoff = true
	clearTimeout(holdoffTimeout)
	holdoffTimeout = setTimeout(() => (holdoff = false), ms)
}

const isolateFromUpdates = callback => async (...args) => {
	pauseServerStatusUpdates()
	let error = null
	let result = null
	try {
		result = await callback(...args)
	} catch (err) {
		error = err
	}
	pauseServerStatusUpdates()

	if (error) throw error
	return result
}

// =================================================================================================

const getServerStatus: ActionCreator = () => async dispatch => {
	if (holdoff) return
	const status = await OnlineComms.getServerStatus()
	if (holdoff) return
	if (status) dispatch(action(VIDEOCONF_GOT_SERVER_STATUS, status))
}

const getServerRegions: ActionCreator = () => async dispatch => {
	const regions = await OnlineComms.getServerRegions()
	if (regions) dispatch(action(VIDEOCONF_GOT_SERVER_REGIONS, regions))
}

const startServer: ActionCreator = region =>
	isolateFromUpdates(async dispatch => {
		if (!region) return
		dispatch(action(VIDEOCONF_SERVER_STARTING))
		await OnlineComms.startServer(region).catch(err => console.error(err))
	})

const stopServer: ActionCreator = () =>
	isolateFromUpdates(async (dispatch, getState) => {
		dispatch(action(VIDEOCONF_SERVER_STOPPING))
		actions.deactivateBreakoutRooms()(dispatch, getState)
		actions.deactivateObserverBreakoutRoom()(dispatch, getState)
		await OnlineComms.stopServer().catch(err => console.error(err))
	})

const updateSelectedRegion: ActionCreator = region => dispatch => {
	dispatch(action(VIDEOCONF_SELECT_REGION, region))
}

const joinBreakoutRoom: ActionCreator = groupId =>
	isolateFromUpdates((dispatch, getState) => {
		if (!groupId) return
		dispatch(action(VIDEOCONF_JOINED_BREAKOUT, groupId))
	})

const leaveBreakoutRoom: ActionCreator = () =>
	isolateFromUpdates((dispatch, getState) => {
		dispatch(action(VIDEOCONF_LEFT_BREAKOUT))
	})

// eslint-disable-next-line @typescript-eslint/no-inferrable-types
const startMainCall: ActionCreator<boolean> = (mainCallWarningEnabled = false) => (dispatch, getState) => {
	const facilitatorId = facilitatorIdSelector(getState())
	console.log('startMainCall', facilitatorId)
	if (!facilitatorId) return

	// Pause server status updates until the video call component has started, a conference is
	// initialised and we've sent a notification message out to the participants
	pauseServerStatusUpdates(10000)

	// Generate a unique ID for this conference
	const mainCallId = uuid()
	const payload = { mainCallId, mainCallWarningEnabled, mainCallStarted: null }
	dispatch(action(SOCKET_MAIN_CALL_STARTED, payload))
}

const startMainCallWithWarning: ActionCreator = () => (dispatch, getState) => {
	startMainCall(true)(dispatch, getState)
}

const notifyMainCall: ActionCreator = (callId, modId) =>
	isolateFromUpdates(async (dispatch, getState) => {
		const state = getState()
		const { mainCallWarningEnabled } = state.videoconf
		await OnlineComms.startMainCall(callId, modId, mainCallWarningEnabled)
	})

const endMainCall: ActionCreator = () =>
	isolateFromUpdates(async (dispatch, getState) => {
		dispatch(action(SOCKET_MAIN_CALL_ENDED, {}))
		await OnlineComms.endMainCall()
	})

const activateBreakoutRooms: ActionCreator = () =>
	isolateFromUpdates((dispatch, getState) => {
		const facilitatorId = facilitatorIdSelector(getState())
		// Call ID = MD5 of facilitator ID + date/time (hh:mm) (+ group ID)
		const callId = md5(facilitatorId + new Date().toISOString().substr(0, 16))
		dispatch(action(SOCKET_BREAKOUT_ROOMS_ACTIVATED, { callId }))
		SocketComms.activateBreakoutRooms(facilitatorId, callId)
	})

const deactivateBreakoutRooms: ActionCreator = () =>
	isolateFromUpdates((dispatch, getState) => {
		const facilitatorId = facilitatorIdSelector(getState())
		dispatch(action(SOCKET_BREAKOUT_ROOMS_DEACTIVATED, {}))
		SocketComms.deactivateBreakoutRooms(facilitatorId)
	})

const activateObserverBreakoutRoom: ActionCreator = () =>
	isolateFromUpdates((dispatch, getState) => {
		const facilitatorId = facilitatorIdSelector(getState())
		const callId = md5(facilitatorId + new Date().toISOString().substr(0, 16))
		dispatch(action(SOCKET_OBSERVER_BREAKOUT_ACTIVATED, { callId }))
		SocketComms.activateObserverBreakoutRoom(facilitatorId, callId)
	})

const deactivateObserverBreakoutRoom: ActionCreator = () =>
	isolateFromUpdates((dispatch, getState) => {
		const facilitatorId = facilitatorIdSelector(getState())
		dispatch(action(SOCKET_OBSERVER_BREAKOUT_DEACTIVATED, {}))
		SocketComms.deactivateObserverBreakoutRoom(facilitatorId)
	})

const selectCamera: ActionCreator = cameraId => dispatch => {
	dispatch(action(VIDEOCONF_CHANGED_CAMERA, cameraId))
}

const selectInterpreter: ActionCreator = interpreterId => dispatch => {
	dispatch(action(VIDEOCONF_CHANGED_INTERPRETER, interpreterId))
}

const selectMic: ActionCreator = micId => dispatch => {
	dispatch(action(VIDEOCONF_CHANGED_MIC, micId))
}

const selectAudioOut: ActionCreator = (micId: string) => dispatch => {
	dispatch(action(VIDEOCONF_CHANGED_AUDIO_OUT, micId))
}

const interpreterChangeChannel: ActionCreator = (onMainChannel: boolean) => async (dispatch, getState) => {
	const state = getState()
	const facilitatorId = facilitatorIdSelector(state)
	const { clientId } = state
	dispatch(action(VIDEOCONF_INTERPRETER_CHANGED_CHANNEL, { clientId, onMainChannel }))
	await SocketComms.interpreterChangeChannel(facilitatorId, clientId, onMainChannel)
}

const toggleChat: ActionCreator = (enabled: boolean) => async dispatch => {
	dispatch(action(VIDEOCONF_CHAT_TOGGLED, enabled))
}

const checkChatMessagesForTranslation: ActionCreator<JitsiChatMessage[], JitsiParticipantDetails[]> = (
	chat: JitsiChatMessage[],
	remoteParticipants: JitsiParticipantDetails[]
) => async (dispatch, getState) => {
	if (!chat || !chat.length) return

	console.log('checkChatMessagesForTranslation')

	const state = getState()
	const { participants } = state

	const loggedInAsParticipant = loggedInAsParticipantSelector(state)

	let ourLanguage = 'EN'
	if (loggedInAsParticipant && state?.group?.language) ourLanguage = state.group.language

	for (let i = 0; i < chat.length; i++) {
		// Get the chat message text
		const chatMessage = chat[i]
		console.log('chatMessage', chatMessage)
		const text = chatMessage.message
		if (!text) continue

		// Get the view360 participant ID for the participant who sent this message
		// NOTE: This is different from the Jitsi participant ID, so we need to look them up in the remote participants list
		const remoteParticipant = remoteParticipants.find(p => p.participantId === chatMessage.participantId)
		console.log('remoteParticipant', remoteParticipant)
		if (!remoteParticipant) continue

		let language = 'EN'
		const participantId = remoteParticipant?.properties?.participantId
		if (participantId) {
			// Get the participant details
			const participant = participants.find(p => p.id === participantId)
			console.log('participant', participant)
			if (!participant) continue
			// Check their language. If it is the same as ours then no translation needed
			language = participant.language || 'EN'
		}

		if (language === ourLanguage) continue

		// Now call the API to translate this message and store the result
		const translationResult = await OnlineComms.translate(language, ourLanguage, [text])
		const payload = { fromLang: language, toLang: ourLanguage, text, translation: translationResult[0] }
		dispatch(action(TEXT_TRANSLATED, payload))
	}
}

const actions = {
	checkChatMessagesForTranslation,
	deactivateObserverBreakoutRoom,
	activateObserverBreakoutRoom,
	interpreterChangeChannel,
	startMainCallWithWarning,
	deactivateBreakoutRooms,
	activateBreakoutRooms,
	updateSelectedRegion,
	leaveBreakoutRoom,
	selectInterpreter,
	getServerRegions,
	joinBreakoutRoom,
	getServerStatus,
	notifyMainCall,
	selectAudioOut,
	startMainCall,
	selectCamera,
	startServer,
	endMainCall,
	stopServer,
	toggleChat,
	selectMic,
}

export default actions
