/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { ReactElement } from 'react'
import { connect } from 'react-redux'
import Actions from '../actions'
import Button from '../elements/Button'

type SelectInterpreterModalProps = typeof actions & ReturnType<typeof mapStateToProps>

function SelectInterpreterModal(props: SelectInterpreterModalProps): ReactElement<SelectInterpreterModalProps> {
	const { displayModalPopup, interpreters, selectInterpreter } = props

	const onSelectInterpreter = (id: string) => {
		selectInterpreter(id)
		displayModalPopup('')
	}

	return (
		<div className="select-interpreter-modal">
			<div className="select-interpreter-modal__main" role="dialog">
				<div className="select-interpreter-modal__content">
					{interpreters.map(interpreter => (
						<Button text={interpreter.channel} onClick={() => onSelectInterpreter(interpreter.clientId)} />
					))}
					<Button text="No interpreter" onClick={() => onSelectInterpreter(null)} secondary />
				</div>
			</div>
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): { interpreters: VideoConfDetails['interpreters'] } => ({
	interpreters: state.videoconf.interpreters,
})
const actions = {
	displayModalPopup: Actions.misc.displayModalPopup,
	selectInterpreter: Actions.videoconf.selectInterpreter,
}
export default connect(mapStateToProps, actions)(SelectInterpreterModal)
