export default {
	/* Team view */
	OK: 'OK',
	CANCEL: 'Cancel',
	TUTOR: 'Facilitator',
	CURRENT_SESSION: 'Current session:',
	TEAM: 'Team',
	SIGNOUT: 'Log out',
	INITIAL_UPDATE: 'Session information',
	ADVICE: 'Decision',
	RATIONALE: 'Rationale',
	MEDIA_FEED: 'Media feed',
	MESSAGES: 'Messages',
	PREVSESSIONS: 'Previous sessions',
	RETURN_TO_CURRENT: 'Return to current session',
	SESSION_IS_PAUSED: 'Session is currently PAUSED',
	ATTRIB_CURR_AUTHOR: 'Decision maker',
	SAVE: 'Save',
	EDIT: 'Edit',
	CHANGE: 'Change',
	NO_AUTHOR: 'Not set',
	NO_INITIAL_INFO: 'Please wait for the next session to begin',
	DECISION_MAKER_SELECT: 'Please select a decision maker...',
	SESSION_COMPLETE: 'The previous session has finished',
	OPENED_AT: 'Opened at',
	SEND_MESSAGE_PLACEHOLDER: 'Write a message',
	SEND: 'Send',
	TO_ALL_GROUPS: 'to all teams',
	SHOW_TRANSLATION: 'Show translation',
	SHOW_ORIGINAL: 'Show original',
	CREDENTIALS_EXPIRED: 'Your credentials have expired. Please log in again.',
	INCOMING_MESSAGE: 'Incoming message',
	INCOMING_MESSAGE_TEXT: 'A new message has been received',
	INCOMING_MEDIA: 'Incoming media item',
	INCOMING_MEDIA_TEXT: 'A new media item has been received',
	INCOMING_UPDATE: 'Incoming update',
	INCOMING_UPDATE_TEXT: 'An update has been received',
	CONFIRM_LOGOUT: 'Are you sure you wish to logout?',
	MUTED_MESSAGE: 'You are currently muted',
	NEWSTYPE_UPDATE: 'Update received',
	WARNING_TIME_MSG: 'There are 5 minutes remaining',
	VIEWING_PREVIOUS: (index: string | number, date: string): string => `Viewing session ${index} from ${date}`,

	/* Facilitator view */
	CASES: 'Cases',
	SESSIONS: 'Sessions',
	VIDEOCONF: 'Video conferencing',
	PASSCODES: 'Team passcodes',
	CASE_LIST: 'Case list',
	ADD_CASE: 'Add new',
	PARTICIPANTS: 'Participants',

	LINKEDACCESS_CLOUDVERSION: 'Observer access',
	LINKEDACCESS: 'Observer access',
	LINKEDACCESSPASSWORDS: 'Observer passcodes',
	SUPERVISOR: 'Observer',
	CONNECT: 'Connect',
	DISCONNECT: 'Disconnect',

	OFFLINE: 'offline',
	ONLINE: 'online',

	OBSERVER_CHAT: 'Observers',

	NO_RECORDS: 'No records',

	/* Video conferencing */
	REGION: 'Region',
	SELECT_REGION: 'Select region for server...',
	NONE_SELECTED: 'None selected',
	START_SERVER: 'Start server',
	STOP_SERVER: 'Stop server',
	START_CALL: 'Start call',
	START_CALL_WITH_WARNING: 'Start call with warning',
	START_CALL_TITLE: 'Start call with all participants',
	START_CALL_MESSAGE:
		'Click "Start call" to start a full-screen video conference call with all teams/participants. Use the "Start call with warning" option to display a warning and countdown to all participants, prior to bringing them into the call. This warning can be configured in Settings.',
	SERVER_STATUS_MESSAGE_ONLINE:
		'The video conferencing server is online in the selected region. It will be put into standby when all facilitators and participants are logged out for longer than two hours.',
	SERVER_STATUS_MESSAGE_STARTING: 'The video conferencing server is starting. Please wait.',
	SERVER_STATUS_MESSAGE_STOPPING: 'The video conferencing server is going offline. Please wait.',
	SERVER_STATUS_MESSAGE_UNAVAILABLE:
		'Video conferencing functionality is unavailable for your organisation. Please contact support to arrange an upgrade to allow you to use the integrated View360 Global video conferencing functionality.',
	SERVER_STATUS_MESSAGE_OFFLINE:
		'Your video conferencing server is currently offline. Click "Start Server" to initialise the server in the selected region. This will take less than 30 seconds. The server will go on standby if all facilitators and participants are logged out for over two hours.',
	BREAKOUT_STATUS_MESSAGE_ENABLED:
		'Breakout rooms are active for all participants. Breakout rooms allow all members of a team to communicate during a session via our video conferencing services.',
	BREAKOUT_STATUS_MESSAGE_DISABLED:
		'Breakout rooms are currently disabled. Click "Enable" to enable automatic breakout rooms for all participants. Breakout rooms allow all members of a team to communicate during a session via our video conferencing services.',

	POPUP_TITLE_NEWCASE: 'Add new case',
	POPUP_TITLE_MEDIAUPLOAD: 'Upload media',
	POPUP_TITLE_CONFIGUREVIDEO: 'Configure audio/video',
	POPUP_TITLE_EDITCASE: 'Edit case details',
	POPUP_TITLE_GROUPRELEASE: 'Release to teams',
	POPUP_TITLE_MEDIAOPENINGS: 'Opened by participants',
	POPUP_TITLE_SELECTINTERPRETER: 'Select interpreter channel',
	POPUP_TITLE_MEDIAFORPHONE: 'You have a new message',
	POPUP_TITLE_EDITMEDIA: 'Edit media details',

	CASE_TITLE: 'Title',
	CASE_DESCRIPTION: 'Description:',
	CASE_CANNOT_BE_CHANGED: 'Case cannot be changed whilst a session is in progress',
	SESSION: 'Session',
	ADD_SESSION: 'Add session',
	SEND_TO_ALL_PLACEHOLDER: 'Send message to all teams',
	NEVER: 'Never',
	UNKNOWN: 'Unknown',
	LAST_UPDATED: 'Last updated',
	SESSION_TIMEFRAME: 'minutes',
	DURATION: 'Duration',
	SESSION_TRANSLATE_INFO: 'Translate session information to participants',
	SESSION_DURATION_MAX_WARNING: (max: string | number): string => `Max. duration: ${max} minutes`,
	SESSION_DURATION_MIN_WARNING: (min: string | number): string => `Min. duration: ${min} minutes`,
	PARTICIPANT: 'Participant',
	GROUP: 'Team',
	RUN: 'Run',
	DELETE: 'Delete',
	STOP: 'Stop',
	PAUSE: 'Pause',
	RESUME: 'Resume',
	NO_SESSIONS: 'There are currently no sessions for this case',
	NO_OPEN_CASE: 'Please open a case before adding any sessions or media.',
	ADD_MEDIA: 'Add media',
	NO_MEDIA: 'There is currently no media for this case',
	RELEASE_SCHEDULE: 'Release schedule',
	RELEASE_NOW: 'Release now',
	RELEASE_TO_GROUPS: 'Release to teams',
	RELEASE: 'Release',
	MEDIA: 'Supporting media',
	MEDIA_ITEM: 'Media item',
	MEDIA_FILES: 'Media items',
	MEDIA_ITEM_NAME: 'Name',
	MEDIA_PHONE_ENABLED: 'Can be sent to phone',
	UPDATE: 'Update',
	UPDATE_TEXT_PLACEHOLDER: 'Enter update text here',
	ADD_RELEASE_ROW: 'Add release row',
	MANUALLY: 'Manually',
	AUTOMATIC: 'Automatically',
	REMOVE: 'Remove',
	UPLOADED: 'Uploaded: ',
	SESSION_IS_ACTIVE: 'Session is active',
	OPEN: 'Select',
	SELECT: 'Select...',
	ACTIVE: 'Selected',
	IP_ADDRESS: 'IP address:',
	EXPIRES: 'Expires',
	AVAILABLE: 'Available',
	ALLOCATED_TO: 'Allocated to',
	CANNOT_EDIT_PREV_SESSIONS:
		'You are currently viewing a previous session. To edit session templates, please return to current session.',
	VIEWING_REMOTE: 'Currently viewing remote sessions',

	SUBSCRIPTION_NOTICE: [
		'Your organisation has ',
		' sessions remaining in your subscription. Subscription expires: ',
		'Please contact support if you require more sessions.',
	],

	PASSCODE_DELETED_TITLE: 'Deleted',
	PASSCODE_DELETED_MSG: 'Passcode deleted',

	PASSCODE_COPIED_TITLE: 'Copied',
	PASSCODE_COPIED_MSG: 'Passcode copied to your clipboard',

	MEDIA_DELETED_TITLE: 'Deleted',
	MEDIA_DELETED_MSG: 'Media item deleted',

	CONFIRM_RELEASE_TO_GROUP: 'Are you sure you wish to release to this team?',
	CONFIRM_DELETE_SESSION: 'Are you sure you wish to delete this session?',
	CONFIRM_BEGIN_SESSION: 'Are you sure you wish to begin this session?',
	CONFIRM_END_SESSION: 'Are you sure you wish to end this session?',
	CONFIRM_BEGIN_SESSION_MESSAGE: (num: string | number): string =>
		`If the session runs for longer than 5 minutes, then it will be deducted from you licence quota. You currently have ${num} sessions remaining in your current subscription plan.`,
	CONFIRM_RELEASE_NOW: 'Are you sure you wish to release this to all teams now?',
	CONFIRM_DELETE_CASE: 'Are you sure you wish to delete this case?',
	CONFIRM_DELETE_ROW: 'Are you sure you wish to remove this row?',
	CONFIRM_DELETE_MEDIA: 'Are you sure you wish to remove this media item?',
	CONFIRM_DELETE_PASSWORD: 'Are you sure you wish to delete this passcode?',
	CONFIRM_DELETE_PREV_SESSION: 'Are you sure you wish to delete this session?',
	PAUSE_CONFIRM: 'Are you sure you wish to pause this session?',
	RESUME_CONFIRM: 'Are you sure you wish to resume the session?',

	// Messages to teams
	CONTENT_FORMAT_NOT_RECOGNISED: 'The format of this file is not recognised by the application',

	GROUPS: 'Teams',
	NO_GROUPS: 'No Teams',
	ALL_GROUPS: 'All Teams',

	GROUP_PASSWORDS: 'Team passcodes',
	GENERATE_NEW_PASSWORD: 'Generate new passcode',

	DOWNLOAD: 'Download',
	DOWNLOAD_VERBATIM: 'Download verbatim document',
	DOWNLOAD_TRANSLATED: 'Download translated document',

	ERROR_PASSWORD_INCORRECT: 'Username or password is incorrect',
	ERROR_COULD_NOT_CONNECT: 'Could not connect to the specified address',
	ERROR_NO_PASSCODE: 'Please enter a name and passcode',
	ERROR_TITLE_BLANK: 'Case title cannot be blank',
	ERROR_DESCRIPTION_BLANK: 'Description cannot be blank',
	ERROR_TITLE_ALREADY_EXISTS: 'A case with this title already exists',

	/* Login screen */
	LOGIN: 'Log in',
	PASSCODE: 'Passcode',
	EMAIL: 'Email address',
	PASSWORD: 'Password',
	VERSION: 'version',
	NAME: 'Name',
	BACK: 'Back',

	SETTINGS: 'Settings',
	SETTINGS_WAITING_ROOM: 'Waiting room',
	SETTINGS_WAITING_ROOM_MSG: 'Waiting room message',
	SETTINGS_WAITING_ROOM_INFO:
		'The following message will be displayed to teams and participants at login when no current session is in progress.',

	VIDEOCONF_JOIN_BREAKOUT_MSG: `You can join a breakout room to chat with participants in a team. The different between this option and a 'call with a team' is that the call will not be full screen for the participants. Instead, you	will be included in their sidebar.`,
	VIDEOCONF_SELECT_TEAM: 'Select team...',
	VIDEOCONF_CHECKING: 'Checking',
	VIDEOCONF_LEAVE: 'Leave',
	VIDEOCONF_JOIN: 'Join',
	VIDEOCONF_STATUS: 'Server status',
	VIDEOCONF_OBSERVER_ROOM: 'Observer breakout room',
	VIDEOCONF_OBSERVER_ROOM_MSG: `The observer breakout room allows the facilitator and observers to communicate between themselves.`,

	CAMERA: 'Camera',
	MICROPHONE: 'Microphone',
	AUDIO_OUTPUT_DEVICE: 'Audio output device',
	SAME_AS_SYSTEM: 'Same as System',
	INTERPRETER: 'Interpreter',
	CHANNEL_NAME: 'Channel name',
}
