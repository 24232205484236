const list = [
	{ value: 'document', icon: 'file-alt', label: 'Document', color: '#660066' },
	{ value: 'video', icon: 'video', label: 'Video', color: '#FFAD33' },
	{ value: 'map', icon: 'map-marker-alt', label: 'Map', color: '#66CC66' },
	{ value: 'phone', icon: 'phone', label: 'Phone', color: '#6633FF' },
	{ value: 'audio', icon: 'volume-up', label: 'Audio', color: '#CC0066' },
	{ value: 'image', icon: 'camera', label: 'Image', color: '#FF6600' },
	{ value: 'mail', icon: 'envelope', label: 'Mail', color: '#009BA4' },
	{ value: 'newspaper', icon: 'newspaper', label: 'News', color: '#cc3399' },
	{ value: 'web', icon: 'globe', label: 'Web', color: '#35398e' },
	{ value: 'facebook', icon: 'facebook-f', label: 'Facebook', color: '#1877F2' },
	{ value: 'instagram', icon: 'instagram', label: 'Instagram', color: '#8134af' },
	{ value: 'whatsapp', icon: 'whatsapp', label: 'WhatsApp', color: '#25d366' },
	{ value: 'skype', icon: 'skype', label: 'Skype', color: '#02AFF0' },
	{ value: 'linkedin', icon: 'linkedin', label: 'LinkedIn', color: '#007BB5' },
	{ value: 'twitter', icon: 'twitter', label: 'Twitter', color: '#1DA1F2' },
	{ value: 'viber', icon: 'viber', label: 'Viber', color: '#AE56A4' },
	{ value: 'youtube', icon: 'youtube', label: 'YouTube', color: '#FF0000' },
]

export default list
