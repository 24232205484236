import { connect } from 'react-redux'
import React from 'react'
import md5 from 'md5'

import config from '../../config'

import teamNameFromColour from '../util/teamNameFromColour'

import SideCallNew from './SideCallNew'
import SideCall from './SideCall'

const { VIDEO__NEW_COMPONENTS } = config

type TeamBreakoutRoomProps = {
	domain: string
	instanceId: string
	facilitatorId: string
	groupId: string
	displayName: string
}

// For a team breakout room, we open up the SideCall component and use a call ID which is:
// md5(facilitatorId + server instance ID + group ID)
function TeamBreakoutRoom(props: TeamBreakoutRoomProps) {
	const { domain, instanceId, facilitatorId, groupId, displayName } = props
	const callId = md5(facilitatorId + instanceId + groupId)
	return VIDEO__NEW_COMPONENTS ? (
		<SideCallNew callId={callId} domain={domain} displayName={displayName} />
	) : (
		<SideCall callId={callId} domain={domain} displayName={displayName} />
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): TeamBreakoutRoomProps => {
	const group = state.group ?? nullobject
	const videoconf = state.videoconf ?? nullobject

	return {
		domain: videoconf.participantDomain ?? videoconf.domain,
		displayName: group.name ?? teamNameFromColour(group.colour),
		facilitatorId: group.facilitatorId,
		instanceId: videoconf.instanceId,
		groupId: group.id,
	}
}
const actions = {}
export default connect(mapStateToProps, actions)(TeamBreakoutRoom)
