import React, { FunctionComponent } from 'react'

import convertColour from '../util/convertColour'
import Icon from './Icon'

type TeamBadgeProps = {
	colour: string
	label: string
}

const TeamBadge: FunctionComponent<TeamBadgeProps> = ({ label, colour }) => (
	<div className="team-badge">
		<div className="team-badge__circle" style={{ backgroundColor: convertColour(colour) }}>
			<Icon name="users" />
		</div>
		<span className="team-badge__label">{label}</span>
	</div>
)

export default TeamBadge
