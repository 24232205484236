/* eslint-disable no-param-reassign */

import produce from 'immer'

export function SETTINGS_UPDATED(state: StateTree, action: ReduxAction): StateTree {
	const settings = action.payload
	state.settings = { ...state.settings, ...settings }
	return state
}

export function SETTINGS_LOADED(state: StateTree, action: ReduxAction): StateTree {
	state.settings = action.payload
	return state
}

//==================================================================================================
const reducers = {
	SETTINGS_UPDATED,
	SETTINGS_LOADED,
}
//==================================================================================================

function handler(state: StateTree, action: ReduxAction): StateTree {
	let newState = state

	// Default state
	if (!state.settings) {
		newState = { ...state, settings: {} }
	}

	const _action: ReduxAction = action || { type: '', payload: {} }
	const { type } = _action

	// If we have a reducer that matches the action type, apply it
	if (!reducers[type]) return newState

	return produce(newState, _newState => reducers[type](_newState, _action))
}

export default handler
