import Swal from 'sweetalert2'

import config from '../../config'

class ConfirmationHelper {
	confirm(msg: string, callback?: () => void, text: string = null): Promise<void> {
		const { OK, CANCEL } = config.strings
		return new Promise<void>(resolve => {
			Swal.fire({
				title: msg,
				text,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: OK,
				cancelButtonText: CANCEL,
				buttonsStyling: false,
				customClass: {
					title: 'alert__title',
					confirmButton: 'alert__button',
					cancelButton: 'alert__button alert__button--cancel',
				},
			}).then(result => {
				if (result.value) {
					resolve()
					if (callback) callback()
				}
			})
		})
	}

	toast(title: string, msg: string): Promise<void> {
		return new Promise<void>(() => {
			Swal.fire({
				title,
				text: msg,
				icon: 'info',
				toast: true,
				timer: 10000,
				showConfirmButton: false,
				showClass: {
					popup: 'alert__show',
				},
				hideClass: {
					popup: 'alert__hide',
				},
				position: 'top',
				customClass: {
					content: 'alert__toast-content',
					title: 'alert__toast-title',
					icon: 'alert__toast-icon',
					popup: 'alert__toast',
				},
			})
		})
	}

	alert(title: string, msg?: string, okButton = 'OK', cancelButton = 'Cancel', callback?: () => void): Promise<void> {
		return new Promise<void>(resolve => {
			Swal.fire({
				title,
				text: msg,
				icon: 'info',
				showCancelButton: Boolean(cancelButton),
				confirmButtonText: okButton,
				cancelButtonText: cancelButton,
				buttonsStyling: false,
				customClass: {
					title: 'alert__title',
					confirmButton: 'alert__button',
					cancelButton: 'alert__button alert__button--cancel',
				},
			}).then(result => {
				if (result.value) {
					resolve()
					if (callback) callback()
				}
			})
		})
	}

	warning(title: string, msg: string, okButton = 'OK', cancelButton: string, callback: () => void): Promise<void> {
		return new Promise<void>(resolve => {
			Swal.fire({
				title,
				text: msg,
				icon: 'warning',
				showCancelButton: Boolean(cancelButton),
				confirmButtonText: okButton,
				cancelButtonText: cancelButton,
				buttonsStyling: false,
				customClass: {
					title: 'alert__title',
					confirmButton: 'alert__button',
					cancelButton: 'alert__button alert__button--cancel',
				},
			}).then(result => {
				if (result.value) {
					resolve()
					if (callback) callback()
				}
			})
		})
	}

	successMessage(
		title: string,
		msg: string,
		okButton = 'OK',
		cancelButton?: string,
		callback?: () => void
	): Promise<void> {
		return new Promise<void>(resolve => {
			Swal.fire({
				title,
				text: msg,
				icon: 'info',
				showCancelButton: Boolean(cancelButton),
				confirmButtonText: okButton,
				cancelButtonText: cancelButton,
				buttonsStyling: false,
				toast: true,
				timer: 3000,
				position: 'bottom',
				customClass: {
					title: 'alert__title',
					confirmButton: 'alert__button',
					cancelButton: 'alert__button alert__button--cancel',
				},
				showClass: {
					popup: 'alert__show-bottom',
				},
				hideClass: {
					popup: 'alert__hide-bottom',
				},
			}).then(result => {
				if (result.value) {
					resolve()
					if (callback) callback()
				}
			})
		})
	}
}
const singleton = new ConfirmationHelper()
export default singleton
