/* eslint-disable react/prefer-stateless-function */
// =============================================================================
// Components > Group Messages Panel
// =============================================================================

import { connect } from 'react-redux'
import React from 'react'

import Actions from '../actions'
import config from '../../config'
import IconButton from '../elements/IconButton'

class PreviousSessionMessage extends React.Component {
	render() {
		const { previousSession, returnToCurrentSession } = this.props as any

		if (!previousSession) return null

		const date = new Date(previousSession.startTime)

		// Workaround for date typings not including dateStyle/timeStyle in the format options
		const dateOpts = { dateStyle: 'long' } as Intl.DateTimeFormatOptions
		const dateStr: string = date.toLocaleString('default', dateOpts)
		const timeOpts = { timeStyle: 'short' } as Intl.DateTimeFormatOptions
		const timeStr: string = date.toLocaleString('default', timeOpts)

		const formattedDate = `${dateStr} - ${timeStr}`

		const msg = config.strings.VIEWING_PREVIOUS(previousSession.index, formattedDate)
		return (
			<div className="previous-session-message" title={msg}>
				<span>{msg}</span>
				<IconButton iconName="close" onClick={returnToCurrentSession} />
			</div>
		)
	}
}

// Redux wiring
// =============================================================================
// Map global state to internal component properties
const mapStateToProps = state => {
	const groupData = state.groupData || {}
	const currentSession = state.currentSession || groupData.currentSession || {}
	return {
		currentSession,
		viewingPreviousSession: state.viewingPreviousSession,
		previousSession: state.previousSession,
	}
}
// Map actions to component properties by just passing an object of those actions to connect()
const actions = {
	returnToCurrentSession: Actions.sessions.returnToCurrentSession,
}
export default connect(mapStateToProps, actions)(PreviousSessionMessage)
