import OnlineComms from '../core/OnlineComms'
import action from '../util/action'
import types from './types'

const { SETTINGS_UPDATED, SETTINGS_LOADED } = types

let holdoff = false
let debuff = null
const updateSettings = (settings: SimpleObject) => async (dispatch: Dispatch, getState: GetState): Promise<void> => {
	holdoff = true
	dispatch(action(SETTINGS_UPDATED, settings))

	// Send settings to server after waiting 2 seconds for idle
	const state = getState()
	clearTimeout(debuff)
	debuff = setTimeout(async () => {
		await OnlineComms.updateSettings(state.settings || {}).catch(err => console.error(err))
		holdoff = false
	}, 2000)
}

const fetchSettings = () => async (dispatch: Dispatch): Promise<SimpleObject> => {
	if (holdoff) return
	const settings = await OnlineComms.getSettings()
	if (holdoff) return
	dispatch(action(SETTINGS_LOADED, settings))
	return settings
}

const actions = {
	updateSettings,
	fetchSettings,
}

export default actions
