/* eslint-disable react/destructuring-assignment */
// =============================================================================
// Components > DecisionLogEntry
// =============================================================================

import { connect } from 'react-redux'
import React from 'react'
import md5 from 'md5'

import config from '../../config'
import Actions from '../actions'

import { sessionIsActive } from '../util/SessionHelpers'
import DecisionLogEntryRow from '../elements/DecisionLogEntryRow'
import BubbleHeading from '../elements/BubbleHeading'
import getTime from '../util/getTime'

type DecisionLogEntryProps = {
	currAuthor: string
	group: PropType<StateTree, 'group'>
	languages: string[]
	participants: string[]
	participantId: string
	viewingPreviousSession: boolean
	currentSession: CurrentSession
	authors: string[]
	currAuthorId: string
	decisionLogTranslations: StateTree['decisionLogTranslations']

	advice: string[]
	rationale: string[]
	times: number[]

	updateDecisionLog: (
		advice: string[],
		rationale: string[],
		authors: string[],
		times: number[],
		participants: string[],
		languages: string[]
	) => void
}

type DecisionLogEntryState = {
	advice: string[]
	rationale: string[]
	authors?: string[]
	times?: number[]
	participants?: string[]
}

class DecisionLogEntry extends React.Component<DecisionLogEntryProps, DecisionLogEntryState> {
	constructor(props) {
		super(props)

		const { advice, rationale } = props
		this.state = {
			advice: advice && advice.map ? advice : [advice],
			rationale: rationale && rationale.map ? rationale : [rationale],
		}
	}

	// eslint-disable-next-line react/no-deprecated
	componentWillReceiveProps(newProps) {
		const { group = {} } = newProps
		const { advice, rationale, authors, times, participants } = group

		this.setState({
			advice: advice || [],
			rationale: rationale || [],
			authors: authors || [],
			times: times || [],
			participants: participants || [],
		})
	}

	_onChangeLogEntry(adviceOrRationale, index, value = '') {
		const { advice, rationale } = this.state
		const { currAuthor, group } = this.props

		const newValArray = [...this.state[adviceOrRationale]]
		newValArray[index] = value

		const authors = [...this.props.authors]
		authors[index] = currAuthor

		const times = [...this.props.times]
		if (!rationale[index] && !advice[index]) {
			times[index] = getTime()
		}

		const participants = [...this.props.participants]
		participants[index] = group.participantId

		const languages = [...(this.props.languages || [])]
		languages[index] = group.language || 'EN'

		const newState = {}
		newState[adviceOrRationale] = newValArray
		this.setState(newState)
		this._onChange({ ...this.state, ...newState, authors, times, participants, languages })
	}

	_onChange(details) {
		const { updateDecisionLog } = this.props

		const { advice, rationale, authors, times, participants, languages } = details

		// Even-up arrays and use blank strings if necessary
		for (let i = 0; i < Math.max(advice.length, rationale.length); i++) {
			advice[i] = advice[i] || ''
			rationale[i] = rationale[i] || ''
		}

		// Delete blank rows
		for (let i = advice.length - 1; i >= 0; i--) {
			if (!advice[i] && !rationale[i]) {
				advice.splice(i, 1)
				rationale.splice(i, 1)
				authors.splice(i, 1)
				times.splice(i, 1)
				languages.splice(i, 1)
			}
		}

		updateDecisionLog(advice, rationale, authors, times, participants, languages)
	}

	render() {
		const { props } = this
		const { currentSession, viewingPreviousSession, participantId, currAuthorId, currAuthor } = props
		const { participants, decisionLogTranslations, languages, authors, times } = props
		const { ADVICE, RATIONALE } = config.strings

		const currentSessionIsActive = sessionIsActive(currentSession) && !currentSession.paused
		const viewingActiveSession = !viewingPreviousSession && currentSessionIsActive

		const advice = viewingPreviousSession ? props.advice || [] : this.state.advice
		const rationale = viewingPreviousSession ? props.rationale || [] : this.state.rationale

		if (!viewingActiveSession && Math.max(advice.length, rationale.length) === 0) {
			return <div />
		}

		const isCurrentAuthor = (!participantId && currAuthor) || (participantId && participantId === currAuthorId)

		const trimLower = str => (str || '').trim().toLowerCase()

		const getTranslation = (text, lang) => {
			const key = md5(lang + text)
			const transObj = decisionLogTranslations.find(t => t.key === key) || {}
			return transObj.text || ''
		}

		// Map the advice and rationale to rows of text areas. We loop through the advice/rationale
		// rows backwards so that we can exclude any empty rows at the bottom
		const rows = []
		let skipping = true

		for (let i = Math.max(advice.length, rationale.length) - 1; i >= 0; i--) {
			if (skipping && !rationale[i] && !advice[i]) continue

			skipping = false
			// Add row to top of list
			const logMatchesParticipantId = participantId && participants[i] === participantId
			const logMatchesCurrentAuthorName = !participantId && trimLower(currAuthor) === trimLower(authors[i])
			const enabled =
				viewingActiveSession && isCurrentAuthor && (logMatchesParticipantId || logMatchesCurrentAuthorName)

			const row = (
				<DecisionLogEntryRow
					key={i}
					enabled={enabled}
					advice={advice[i]}
					rationale={rationale[i]}
					author={authors[i]}
					time={times[i]}
					translatedAdvice={getTranslation(advice[i], languages[i])}
					translatedRationale={getTranslation(rationale[i], languages[i])}
					onChangeAdvice={e => this._onChangeLogEntry('advice', i, e.target.value)}
					onChangeRationale={e => this._onChangeLogEntry('rationale', i, e.target.value)}
				/>
			)
			rows.unshift(row)
		}

		// Add empty row if in active session
		if (viewingActiveSession && isCurrentAuthor) {
			const i = Math.max(advice.length, rationale.length)
			rows.push(
				<DecisionLogEntryRow
					key={i}
					enabled
					author={currAuthor}
					onChangeAdvice={e => this._onChangeLogEntry('advice', i, e.target.value)}
					onChangeRationale={e => this._onChangeLogEntry('rationale', i, e.target.value)}
				/>
			)
		}

		if (!viewingActiveSession && !rows.length) {
			return <div />
		}

		return (
			<div className="decision-log">
				<div className="decision-log__header">
					<BubbleHeading>{ADVICE}</BubbleHeading>
					<BubbleHeading>{RATIONALE}</BubbleHeading>
				</div>
				<div className="decision-log__scroller">
					<div className="decision-log__inner">{rows}</div>
				</div>
			</div>
		)
	}
}

// Redux wiring
// =============================================================================
// Map global state to internal component properties
const mapStateToProps = (state: StateTree): Partial<DecisionLogEntryProps> => {
	const { viewingPreviousSession, decisionLogTranslations = [] } = state
	const { participantId = '', id = '' } = state.group || ({} as GroupDetails)
	const groupData = state.groupData || ({} as GroupData)

	const previousSession = (viewingPreviousSession && state.previousSession) || ({} as PreviousSession)
	const prevSessGroups = previousSession.groups || []
	const groupInPrevSession = prevSessGroups.find(g => g.id === id)

	const group = groupInPrevSession || state.group || ({} as GroupDetails)

	return {
		participants: group.participants || [],
		currentSession: groupData.currentSession || ({} as CurrentSession),
		currAuthorId: group.currAuthorId || '',
		currAuthor: group.currAuthor || '',
		languages: group.languages || [],
		rationale: group.rationale || [],
		authors: group.authors || [],
		advice: group.advice || [],
		times: group.times || [],
		decisionLogTranslations,
		viewingPreviousSession,
		participantId,
		group,
	}
}
// Map actions to component properties by just passing an object of those actions to connect()
const actions = {
	updateDecisionLog: Actions.groups.updateDecisionLog,
}
export default connect(mapStateToProps, actions)(DecisionLogEntry)
