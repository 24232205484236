import { connect } from 'react-redux'
import React from 'react'

import Actions from '../actions'

import BubbleHeading from '../elements/BubbleHeading'
import MessagesPanel from '../components/MessagesPanel'
import DecisionLog from '../components/DecisionLog'
import GroupsList from '../components/GroupsList'
import MediaFeed from '../components/MediaFeed'
import config from '../../config'

import releasesSelector from '../selectors/releases'
import sessionSelector from '../selectors/session'

type TutorGroupsProps = {
	groups: GroupDetails[]
	allGroupMessages: Message[]
	viewingPreviousSession: boolean
	decisionLog: StateTree['decisionLog']
	session: CurrentSession | PreviousSession
	conversations: CurrentSession['conversations']
	translatedDecisionLog: StateTree['decisionLog']
	getMediaItems: () => void
	markConversationAsRead: (groupId: string) => void
	getOpenings: (sessionId: string, groupId: string) => void
	getDecisionLog: (groupId: string, sessionId: string) => void
}

type TutorGroupsState = {
	selectedGroupColour?: string
}

class TutorGroups extends React.Component<TutorGroupsProps, TutorGroupsState> {
	interval: number
	selectedGroup: GroupDetails
	groupColour: string

	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount() {
		const { getMediaItems } = this.props
		getMediaItems()
		this._refresh()
		this.interval = window.setInterval(() => this._refresh(), 3000)
	}

	componentWillUnmount() {
		clearInterval(this.interval)
	}

	_refresh() {
		const { viewingPreviousSession } = this.props
		// Don't carry on getting anything else if it's a previous session
		if (viewingPreviousSession) return

		this._getOpenings()
		this._getDecisionLog()
	}

	_getDecisionLog() {
		const { session, getDecisionLog } = this.props
		const sessionId = session?.id
		const selectedGroupId = this?.selectedGroup?.id
		if (sessionId && selectedGroupId) {
			getDecisionLog(selectedGroupId, sessionId)
		}
	}

	_getOpenings() {
		const { session, getOpenings } = this.props
		const sessionId = session?.id
		const selectedGroupId = this?.selectedGroup?.id
		if (sessionId && selectedGroupId) {
			getOpenings(sessionId, selectedGroupId)
		}
	}

	_onClickGroup(groupColour) {
		// Leave now if nothing has changed
		const { selectedGroupColour } = this.state
		if (selectedGroupColour === groupColour) return

		const { groups, markConversationAsRead } = this.props
		const selectedGroup = groups.find(g => g.colour === groupColour) || ({} as GroupDetails)
		this.selectedGroup = selectedGroup

		if (!selectedGroup) return

		this.setState({ selectedGroupColour: groupColour })

		this._getDecisionLog()
		this._getOpenings()

		// Mark messages as read
		const selectedGroupId = selectedGroup.id
		markConversationAsRead(selectedGroupId)
	}

	_onClickAllGroups() {
		// Leave now if nothing has changed
		const { selectedGroupColour } = this.state
		if (selectedGroupColour === 'all-groups') return

		this.setState({ selectedGroupColour: 'all-groups' })
		this.selectedGroup = null
	}

	_mergeConversations(conversations) {
		// Sort in ascending order of timestamp
		const sortAscending = (a, b) => a.timestamp - b.timestamp

		const { groups } = this.props
		let merged = []
		Object.keys(conversations).forEach(c => {
			const selectedGroup = groups.find(g => g.id.toString() === c.toString()) || ({} as GroupDetails)

			const selectedGroupColour = selectedGroup.colour
			merged = merged.concat(conversations[c].map(o => ({ ...o, groupColour: selectedGroupColour })))
		})
		return merged.sort(sortAscending)
	}

	_insertMessagesToAllGroups(conversation, allGroupMessages) {
		const sortAscending = (a, b) => a.timestamp - b.timestamp

		const msgsToAllGrps = (allGroupMessages || []).map(o => ({
			...o,
			tutor: true,
			allGroups: true,
		}))
		return (conversation || []).concat(msgsToAllGrps).sort(sortAscending)
	}

	render() {
		const { props } = this
		let { decisionLog, translatedDecisionLog } = props

		// Firstly, get a list of the groups which have unread messages
		const unreadGroups = []
		Object.keys(props.conversations).forEach(c => {
			if (props.conversations[c].find(m => m.unread)) {
				unreadGroups.push(c)
			}
		})

		let selectedGroupId
		const { selectedGroupColour } = this.state

		// If displaying messages from all groups, merge all conversations into one list
		let conversation = []
		if (selectedGroupColour === 'all-groups') {
			conversation = this._mergeConversations(props.conversations)
			selectedGroupId = 'all-groups'
		} else if (selectedGroupColour) {
			const grp = props.groups.find(g => g.colour === selectedGroupColour) || ({} as GroupDetails)
			selectedGroupId = grp.id || grp.key
			conversation = props.conversations[selectedGroupId]
		}

		// Always merge in the messages that were sent to all groups
		conversation = this._insertMessagesToAllGroups(conversation, props.allGroupMessages)

		// Check that the decision log in state is for the selected group
		if (decisionLog.groupId !== selectedGroupId) {
			decisionLog = {} as StateTree['decisionLog']
		}
		if (translatedDecisionLog.groupId !== selectedGroupId) {
			translatedDecisionLog = {} as StateTree['decisionLog']
		}

		return (
			<div className="tutor-groups">
				<div className="tutor-groups__col">
					<BubbleHeading>{config.strings.GROUPS}</BubbleHeading>
					<GroupsList
						onClickGroup={colour => this._onClickGroup(colour)}
						onClickAllGroups={() => this._onClickAllGroups()}
						selected={selectedGroupColour}
						showAllGroups
					/>
				</div>
				{selectedGroupColour !== 'all-groups' && (
					<div className="tutor-groups__col" style={{ flexGrow: 1 }}>
						<DecisionLog
							translatedDecisionLog={translatedDecisionLog}
							rationale={decisionLog.rationale}
							authors={decisionLog.authors}
							advice={decisionLog.advice}
							times={decisionLog.times}
						/>
					</div>
				)}
				{selectedGroupColour !== 'all-groups' && (
					<div className="tutor-groups__col">
						<BubbleHeading>{config.strings.MEDIA_FEED}</BubbleHeading>
						<MediaFeed groupId={selectedGroupId} />
					</div>
				)}
				<div className="tutor-groups__col">
					<BubbleHeading>{config.strings.MESSAGES}</BubbleHeading>
					<MessagesPanel data={conversation} groupId={selectedGroupId} />
				</div>
			</div>
		)
	}
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = state => {
	const { viewingPreviousSession } = state
	const session = sessionSelector(state)
	const releases = releasesSelector(state)

	return {
		session,
		releases,
		viewingPreviousSession,
		groups: (session as PreviousSession).groups || [],
		openings: state.openings || [],
		decisionLog: state.decisionLog || {},
		conversations: session.conversations || [],
		allGroupMessages: session.allGroupMessages || [],
		translatedDecisionLog: state.translatedDecisionLog || {},
	}
}
const actions = {
	markConversationAsRead: Actions.messages.markConversationAsRead,
	getConversations: Actions.messages.getAllConversations,
	getReleases: Actions.sessions.getReleasesForSession,
	getOpenings: Actions.sessions.getOpeningsForGroup,
	getDecisionLog: Actions.groups.getDecisionLog,
	getMediaItems: Actions.media.getMediaItems,
}
// prettier-ignore
export default connect(mapStateToProps, actions)(TutorGroups)
