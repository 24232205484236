export default {
	/* Team view */
	OK: 'حسنا',
	CANCEL: 'إلغاء',
	TUTOR: 'المساعد ، الميسر',
	CURRENT_SESSION: 'الجلسة الحالية',
	TEAM: 'الفريق',
	SIGNOUT: 'تسجيل خروج',
	INITIAL_UPDATE: 'معلومات الجلسة',
	ADVICE: 'القرار',
	RATIONALE: 'المنطق',
	MEDIA_FEED: 'معلومات وسائل التواصل',
	MESSAGES: 'الرسائل',
	PREVSESSIONS: 'الجلسات السابقة',
	RETURN_TO_CURRENT: 'العودة إلى الجلسة الحالية',
	SESSION_IS_PAUSED: 'الجلسة متوقفة  مؤقتاً',
	ATTRIB_CURR_AUTHOR: 'متخذ القرار',
	SAVE: 'حفظ',
	CHANGE: 'تغيير',
	NO_AUTHOR: 'غير محددة',
	NO_INITIAL_INFO: 'يرجى الإنتظار حتى تبدأ الجلسة التالية',
	DECISION_MAKER_SELECT: 'الرجاء تحديد متخذ القرار',
	SESSION_COMPLETE: 'انتهت الجلسة السابقة',
	OPENED_AT: 'افتتحت  في',
	SEND_MESSAGE_PLACEHOLDER: 'اكتب رسالة',
	SEND: 'إرسال',
	TO_ALL_GROUPS: 'لجميع الفرق',
	SHOW_TRANSLATION: 'إظهار الترجمة',
	SHOW_ORIGINAL: 'إظهار النسخة الأصلية',
	CREDENTIALS_EXPIRED: 'انتهت صلاحية رمز المرور الخاص بك . الرجاءالدخول الى الحساب من جديد',
	INCOMING_MESSAGE: 'رسالة واردة',
	INCOMING_MESSAGE_TEXT: 'تم استلام رسالة جديدة',
	INCOMING_MEDIA: 'الوسائط الواردة',
	INCOMING_MEDIA_TEXT: 'تم استلام عنصر وسائط جديد',
	INCOMING_UPDATE: 'تحديث وارد',
	INCOMING_UPDATE_TEXT: 'تم استلام تحديث',
	CONFIRM_LOGOUT: 'هل أنت متأكد أنك تريد تسجيل الخروج؟',
	MUTED_MESSAGE: 'الميكروفون مغلق',
	NEWSTYPE_UPDATE: 'تلقى تحديث',
	WARNING_TIME_MSG: 'باقي 5 دقائق',
	VIEWING_PREVIOUS: (index: string | number, date: string): string => `${date} مشاهدة الجلسة ${index} من`,
}
