import React, { FunctionComponent } from 'react'

import IconButton from './IconButton'

type SidebarButtonProps = {
	name?: string
	label?: string
	iconName?: string
	selected?: boolean
	onClick?: () => void
	showBadge?: boolean
}

const SidebarButton: FunctionComponent<SidebarButtonProps> = props => {
	const { showBadge, selected, onClick, name, iconName, label } = props

	const className = `tutor-sidebar__button ${name.toLowerCase()}`

	return (
		<div key={name} role="button" tabIndex={0} onClick={onClick} onKeyPress={onClick} className={className}>
			<div className="tutor-sidebar__label">{label}</div>
			<IconButton key={name} iconName={iconName} selected={selected} showBadge={showBadge} />
		</div>
	)
}

export default SidebarButton
