export default {
	/* Team view */
	OK: '好',
	CANCEL: '取消',
	TUTOR: '服務商',
	CURRENT_SESSION: '當前會議',
	TEAM: '小組',
	SIGNOUT: '登出',
	INITIAL_UPDATE: '會議信息',
	ADVICE: '決定',
	RATIONALE: '基本原理',
	MEDIA_FEED: '媒體來源',
	MESSAGES: '信息',
	PREVSESSIONS: '之前會議',
	RETURN_TO_CURRENT: '返回當前會議',
	SESSION_IS_PAUSED: '會議當前已暫停',
	ATTRIB_CURR_AUTHOR: '決策者',
	SAVE: '保存',
	CHANGE: '更改',
	NO_AUTHOR: '未設置',
	NO_INITIAL_INFO: '請等待下一個會議開始',
	DECISION_MAKER_SELECT: '請選擇決策者',
	SESSION_COMPLETE: '之前會議已結束',
	OPENED_AT: '打開於',
	SEND_MESSAGE_PLACEHOLDER: '留言',
	SEND: '發送',
	TO_ALL_GROUPS: '對所有組',
	SHOW_TRANSLATION: '顯示譯文',
	SHOW_ORIGINAL: '顯示原始文件',
	CREDENTIALS_EXPIRED: '您的密碼已過期。請重新登錄。',
	INCOMING_MESSAGE: '傳入信息',
	INCOMING_MESSAGE_TEXT: '收到新信息',
	INCOMING_MEDIA: '傳入媒體項',
	INCOMING_MEDIA_TEXT: '已收到新的媒體項',
	INCOMING_UPDATE: '傳入更新',
	INCOMING_UPDATE_TEXT: '已收到更新',
	CONFIRM_LOGOUT: '您確定要註銷嗎？',
	MUTED_MESSAGE: '您的麥克風已關閉',
	NEWSTYPE_UPDATE: '更新',
	WARNING_TIME_MSG: '剩下5分鐘',
}
