import md5 from 'md5'

// Singleton module that periodically sends/retrieves participant images to/from the View360 image cache
// const URL = (id: string) => `https://h2t4rgaidd.execute-api.eu-west-2.amazonaws.com/dev/${id}`
const URL = (id: string) => `https://k95cbqg8zg.execute-api.eu-west-2.amazonaws.com/dev/${id}`
const IMAGE_MAX_HEIGHT = 180
const MIME_TYPE = 'image/webp'
const POST_INTERVAL = 2000
const GET_INTERVAL = 4000

const intervalRefs = []
const fetching = {}
const posting = {}

function calculateId(participantId: string, facilitatorId: string) {
	return md5(participantId + facilitatorId)
}

export function startBroadcast(participantId: string, facilitatorId: string, videoElem: HTMLVideoElement): number {
	const intervalRef = window.setInterval(() => postImage(participantId, facilitatorId, videoElem), POST_INTERVAL)
	intervalRefs.push(intervalRef)
	postImage(participantId, facilitatorId, videoElem)
	return intervalRef
}

export function endBroadcast(ref: number): void {
	clearTimeout(ref)
	const index = intervalRefs.indexOf(ref)
	if (index >= 0) intervalRefs.splice(index, 1)
}

export function subscribe(participantId: string, facilitatorId: string, callback: (base64: string) => void): number {
	const fetch = async () => {
		if (fetching[intervalRef]) return
		fetching[intervalRef] = true
		const image = await fetchImage(participantId, facilitatorId).catch(err => {
			console.error(err)
			return ''
		})
		fetching[intervalRef] = false
		if (image && intervalRefs.indexOf(intervalRef) >= 0) callback(image)
	}
	const intervalRef = window.setInterval(fetch, GET_INTERVAL)
	intervalRefs.push(intervalRef)
	fetch()
	return intervalRef
}

export async function fetchImage(participantId: string, facilitatorId: string): Promise<string> {
	const url = URL(calculateId(participantId, facilitatorId))
	const response = await fetch(url, { method: 'get' })
	// Remove doublequotes from response
	const text = (await response.text()).split('"').join('')
	return text
}

export async function postImage(
	participantId: string,
	facilitatorId: string,
	videoElem: HTMLVideoElement
): Promise<void> {
	if (posting[participantId]) return
	posting[participantId] = true
	const url = URL(calculateId(participantId, facilitatorId))
	const data = captureImageBase64(videoElem)
	await fetch(url, { method: 'post', body: JSON.stringify({ data }) }).catch(err => console.error(err))
	posting[participantId] = false
}

function captureImageBase64(
	videoElem: HTMLVideoElement,
	maxHeight: number = IMAGE_MAX_HEIGHT,
	mimeType: string = MIME_TYPE
): string {
	try {
		if (!videoElem) return
		const canvas = document.createElement('canvas')
		let scale = 1
		if (maxHeight) scale = maxHeight / videoElem.videoHeight
		canvas.width = videoElem.videoWidth * scale
		canvas.height = videoElem.videoHeight * scale
		canvas.getContext('2d').drawImage(videoElem, 0, 0, canvas.width, canvas.height)
		const dataURL = canvas.toDataURL(mimeType)
		return dataURL.split(',')[1]
	} catch (err) {
		console.error(err)
		return ''
	}
}

export function unsubscribe(ref: number): void {
	clearTimeout(ref)
	const index = intervalRefs.indexOf(ref)
	if (index >= 0) intervalRefs.splice(index, 1)
}
