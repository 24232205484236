/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { connect } from 'react-redux'
import React from 'react'

import config from '../../config'
import Actions from '../actions'

import { sessionIsActive } from '../util/SessionHelpers'

import SubscriptionNotice from '../components/SubscriptionNotice'
import BubbleHeading from '../elements/BubbleHeading'
import Session from '../components/Session'
import Button from '../elements/Button'

class TutorSessions extends React.Component {
	constructor(props) {
		super(props)
		const { currentSession, selectSession } = props

		if (currentSession && sessionIsActive(currentSession)) {
			selectSession(currentSession.sessionId)
		}
	}

	componentDidUpdate() {
		// After user adds a new session, scroll it into view
		const { sessions } = this.props
		if (this.waitingForNewSession && sessions.length > this.waitingForNewSession) {
			const _sessions = this.element.querySelectorAll('.session')
			_sessions[_sessions.length - 1].scrollIntoView()
			this.waitingForNewSession = false
		}
	}

	// ===============================================================================================

	_onClickNewSession() {
		const { sessions, addNewSession, openCase } = this.props
		this.waitingForNewSession = sessions.length
		addNewSession(openCase.timestamp)
	}

	// ===============================================================================================

	render() {
		const { CANNOT_EDIT_PREV_SESSIONS, NO_OPEN_CASE, NO_SESSIONS, SESSIONS, ADD_SESSION } = config.strings
		const { openCase, sessions, viewingPreviousSession, selectSession, selectedSession } = this.props

		let allowCreate = true
		let content = null
		if (viewingPreviousSession) {
			allowCreate = false
			content = <div className="info">{CANNOT_EDIT_PREV_SESSIONS}</div>
		} else if (!openCase || !openCase.timestamp) {
			allowCreate = false
			content = <div className="info">{NO_OPEN_CASE}</div>
		} else if (sessions.length === 0) {
			content = <div className="info">{NO_SESSIONS}</div>
		} else {
			const sessionTabs = sessions.map((s, i) => (
				<Tab key={s.id} id={s.id} onClick={_ => selectSession(s.id)}>
					{i + 1}
				</Tab>
			))
			content = (
				<>
					<SubscriptionNotice />
					<TabsRow activeTab={selectedSession}>{sessionTabs}</TabsRow>
					<TabsContainer activeTab={selectedSession}>
						{sessions.map(s => (
							<Session key={s.id} id={s.id} data={s} />
						))}
					</TabsContainer>
				</>
			)
		}

		return (
			<div className="tutor-sessions" ref={ref => (this.element = ref)}>
				<div className="tutor-sessions__top">
					<BubbleHeading>{SESSIONS}</BubbleHeading>
					<Button text={ADD_SESSION} onClick={_ => this._onClickNewSession()} enabled={allowCreate} />
				</div>
				<div className="tutor-sessions__content">{content}</div>
			</div>
		)
	}
}

function Tab(props) {
	const { onClick, className = '', children, active } = props
	const _className = `tab ${active ? 'tab--active' : 'tab--inactive'} ${className}`
	return (
		<div className={_className} onClick={onClick}>
			<h3>{children}</h3>
		</div>
	)
}

function TabsRow(props) {
	const { children, activeTab } = props
	const _children = (children || []).map(c => {
		const { id } = c.props
		return React.cloneElement(c, { active: id === activeTab })
	})
	return <div className="tab__row">{_children}</div>
}

function TabsContainer(props) {
	const { children, activeTab } = props
	// Set class of each child according to whether tab is active or not
	// activeTab === id prop of the active child
	const _children = (children || []).map(c => {
		const { id } = c.props
		const className = id === activeTab ? 'tab__content--active' : 'tab__content--inactive'
		return React.cloneElement(c, { className: `${c.props.className || ''} ${className}` })
	})
	return <div>{_children}</div>
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = state => {
	const sessions = state.openCase ? state.openCase.sessions || [] : []

	// Select the first session in list if no selected session or it doesn't exist anymore
	let { selectedSession } = state
	if (sessions.length && (!selectedSession || !sessions.find(s => s.id === selectedSession))) {
		selectedSession = sessions[0].id
	}

	return {
		viewingPreviousSession: state.viewingPreviousSession,
		currentSession: state.currentSession || {},
		previousSession: state.previousSession,
		openCase: state.openCase,
		selectedSession,
		sessions,
	}
}
const actions = {
	displayModalPopup: Actions.misc.displayModalPopup,
	addNewSession: Actions.sessions.addNewSession,
	selectSession: Actions.sessions.selectSession,
}
export default connect(mapStateToProps, actions)(TutorSessions)
