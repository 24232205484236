import { connect } from 'react-redux'
import React from 'react'

import teamNameFromColour from '../util/teamNameFromColour'
import config from '../../config'
import Actions from '../actions'

// prettier-ignore
const STANDARD_BUTTONS = [ 
	'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen', 'fodeviceselection', 'hangup', 
	'profile', 'chat', 'recording', 'livestreaming', 'etherpad', 'sharedvideo', 'settings', 
	'raisehand', 'videoquality', 'filmstrip', 'invite', 'feedback', 'stats', 'shortcuts', 'tileview', 
	'videobackgroundblur', 'download', 'help', 'mute-everyone', 'e2ee', 'security' 
]
const OMIT_FOR_TUTOR = ['raisehand', 'videobackgroundblur', 'security', 'invite']
const TUTOR_BUTTONS = STANDARD_BUTTONS.filter(b => !OMIT_FOR_TUTOR.includes(b))
const GROUP_BUTTONS = ['chat', 'microphone', 'camera', 'profile', 'settings', 'raisehand', 'tileview']

type MainCallProps = {
	endMainCall?: () => void
	callId?: string
	modId?: string
	group?: GroupDetails
	domain?: string
	observerName?: string
	isObserver?: boolean
	isTutor?: boolean
	participantDomain?: string
	notifyMainCall?: (callId: string, modId: string) => void
}
type MainCallState = {
	muted?: boolean
}
class MainCall extends React.Component<MainCallProps, MainCallState> {
	api: { dispose: () => void }
	jitsiId: string

	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount() {
		document.body.classList.add('in-call')
		// Check when call started, how long the wait time is, and therefore whether we are ready to start the conference
		// const callStartedTimestamp =
		try {
			this.startConference()
		} catch (error) {
			console.error('Failed to start the conference', error)
		}
	}

	componentWillUnmount() {
		document.body.classList.remove('in-call')
		if (this.api) this.api.dispose()
	}

	startConference() {
		const { participantDomain, callId, modId, group, domain, observerName } = this.props
		const { isObserver, isTutor, endMainCall, notifyMainCall } = this.props
		const { TUTOR, SUPERVISOR } = config.strings

		const isGroup = Boolean(!isTutor && !isObserver)
		const _domain = isGroup ? participantDomain || domain : domain

		let displayName = TUTOR
		if (isGroup) displayName = group.name || teamNameFromColour(group.colour)
		if (isObserver) displayName = `${observerName} (${SUPERVISOR})`

		// Jitsi Meet API docs: https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-iframe
		// Config.js: https://github.com/jitsi/jitsi-meet/blob/master/config.js
		// Interface config: https://github.com/jitsi/jitsi-meet/blob/master/interface_config.js
		const configOverwrite = {
			startWithAudioMuted: !isTutor,
			channelLastN: isTutor ? 10 : 3,
			constraints: null,
		}

		if (!isTutor) {
			configOverwrite.constraints = {
				video: {
					height: {
						ideal: 240,
						min: 90,
					},
				},
			}
		}

		const options = {
			roomName: callId,
			parentNode: document.getElementById('main-call__content'),
			configOverwrite,
			interfaceConfigOverwrite: {
				TOOLBAR_TIMEOUT: 3600000,
				TOOLBAR_ALWAYS_VISIBLE: true,
				TOOLBAR_BUTTONS: isTutor ? TUTOR_BUTTONS : GROUP_BUTTONS,
				FILM_STRIP_MAX_HEIGHT: 140,
				SHOW_JITSI_WATERMARK: false,
				ENFORCE_NOTIFICATION_AUTO_DISMISS_TIMEOUT: 15000,
				LOCAL_THUMBNAIL_RATIO: 6 / 4,
				REMOTE_THUMBNAIL_RATIO: 6 / 4,
				HIDE_INVITE_MORE_HEADER: true,
				DISABLE_VIDEO_BACKGROUND: true,
			},
			userInfo: {
				displayName,
			},
		}

		const api = new window.JitsiMeetExternalAPI(_domain, options)
		if (!api) throw new Error('Failed to create JitsiMeetExternalAPI istance')
		this.api = api

		api.on('videoConferenceJoined', p => {
			console.log('videoConferenceJoined:', p)
			this.jitsiId = p.id
			// Notify participants that a call has started
			if (isTutor) notifyMainCall(p.roomName, p.id)
		})

		window.jitsiApi = api

		// If the moderator (i.e. facilitator) leaves the call, end the call
		const onParticipantLeft = participant => {
			console.log('PARTICIPANT LEFT: ', participant)
			if (modId && participant.id === modId) endMainCall()
		}

		const onParticipantJoin = participant => {
			console.log('PARTICIPANT JOIN: ', participant)
		}
		api.on('participantLeft', onParticipantLeft)
		api.on('participantJoined', onParticipantJoin)
		api.on('participantKickedOut', onParticipantLeft)

		// Dispose API when call has been ended. Notify participants if tutor.
		api.on('readyToClose', _ => {
			api.dispose()
			if (isTutor) endMainCall()
		})

		// Store muted status in state so we can display alert bar
		api.on('audioMuteStatusChanged', ({ muted }) => this.setState({ muted }))
	}

	render() {
		// const { muted, waitMessage } = this.state
		const { muted } = this.state

		const { MUTED_MESSAGE } = config.strings
		return (
			<div id="main-call">
				<div id="main-call__content" />
				{muted && <div className="main-call__mute-message">{MUTED_MESSAGE}</div>}
			</div>
		)
	}
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<MainCallProps> => {
	const videoconf = state.videoconf || {}
	return {
		isObserver: state.linkedAccess && state.linkedAccess.connected,
		observerName: state.linkedAccess && state.linkedAccess.name,
		callId: videoconf.callId || videoconf.mainCallId,
		participantDomain: videoconf.participantDomain,
		isTutor: state.tutor && state.tutor.loggedIn,
		group: state.group || ({} as GroupDetails),
		domain: videoconf.domain,
		modId: videoconf.modId,
	}
}
const actions: Partial<MainCallProps> = {
	endMainCall: Actions.videoconf.endMainCall,
	notifyMainCall: Actions.videoconf.notifyMainCall,
}
export default connect(mapStateToProps, actions)(MainCall)
