import equal from '../util/equal'

function anyMessagesUnread(conversations: Record<string, Array<{ unread?: boolean }>>) {
	const unreadConvos = Object.keys(conversations).filter(
		grpId => conversations[grpId].find(m => m.unread) && grpId !== 'all'
	)
	return unreadConvos.length > 0
}

const MESSAGES_CLEAR_RECENT_FLAG: Reducer = state => ({ ...state, recentMessage: false })

/**
 * Group received conversation from server
 * @param {Object} state Current global state tree
 * @param {Object} action Action received
 */
const MESSAGES_GOT_CONVERSATION: Reducer = (state, action) => {
	const newConvo = action.payload && action.payload.filter ? action.payload : []
	const groupData = { ...(state.groupData || {}) }

	const time = new Date().getTime()
	const diff = state.serverTimeDiff || 0
	const now = time + diff

	const FIVE_SECS_IN_MS = 1000 * 5
	groupData.conversation = (groupData.conversation || [])
		.map(m => ({ ...m }))
		.filter(m => newConvo.find(msg => msg.key === m.key) || m.timestamp > now - FIVE_SECS_IN_MS)

	// Delete any messages from here that aren't in the conversation we've received,
	// that are older than the time mentioned above
	groupData.conversation = groupData.conversation.concat(
		newConvo.filter(m => groupData.conversation.findIndex(msg => msg.key === m.key) === -1)
	)

	return { ...state, groupData }
}

const MESSAGE_SENT_TO_TUTOR: Reducer = (state, action) => {
	const conversation = state.conversation || []
	return { ...state, conversation: [...conversation, action.payload] }
}

const MESSAGE_SENT_TO_GROUP: Reducer = (state, action) => {
	const { groupId, ...message } = action.payload
	const currentSession = state.currentSession || ({} as CurrentSession)
	const conversations = currentSession.conversations || {}
	let conversation = conversations[groupId] || []
	conversation = [...conversation, { ...message, tutor: true }]
	conversations[groupId] = conversation
	return {
		...state,
		currentSession: { ...state.currentSession, conversations: { ...conversations } },
	}
}

const MESSAGE_MARK_AS_READ: Reducer = (state, action) => {
	// Get existing data
	const currentSession = state.currentSession || ({} as CurrentSession)
	const conversations = currentSession.conversations || {}
	let conversation = conversations[action.payload.groupId] || []
	conversation = conversation.map(m => {
		const msg = { ...m }
		delete msg.unread
		return msg
	})
	conversations[action.payload.groupId] = conversation
	// Check all conversations for an unread message
	const messagesUnread = anyMessagesUnread(conversations)
	return { ...state, currentSession: { ...state.currentSession, conversations }, messagesUnread }
}

const MESSAGE_SENT_TO_ALL_GROUPS: Reducer = (state, action) => {
	const currentSession = state.currentSession || ({} as CurrentSession)
	const allGroupMessages = currentSession.allGroupMessages || []
	allGroupMessages.push(action.payload)
	return { ...state, currentSession: { ...currentSession, allGroupMessages } }
}

const MESSAGES_LOADING_ALL_CONVERSATIONS: Reducer = state => ({
	...state,
	loading: { ...(state.loading || {}), allConversations: true },
})

const MESSAGES_FAILED_ALL_CONVERSATIONS: Reducer = state => ({
	...state,
	loading: { ...(state.loading || {}), allConversations: false },
})

const MESSAGES_GOT_ALL_CONVERSATIONS: Reducer = (state, action) => {
	const existingConversations = (state.currentSession || {}).conversations || {}
	const conversations = action.payload
	Object.keys(conversations).forEach(grpId => {
		const convo = conversations[grpId]
		const convoInCurrentState = existingConversations[grpId]
		if (!convoInCurrentState) {
			existingConversations[grpId] = convo.map(m => ({ ...m, unread: true }))
		} else {
			convo.forEach(msg => {
				// Add any messages that aren't already in the conversation
				if (!convoInCurrentState.find(m => equal(m, msg))) {
					// eslint-disable-next-line no-param-reassign
					msg.unread = true
					existingConversations[grpId].push(msg)
				}
			})
		}
	})

	const newState: StateTree = {
		...state,
		loading: { ...(state.loading || {}), allConversations: false },
		currentSession: {
			...(state.currentSession || ({} as CurrentSession)),
			conversations: existingConversations,
		},
		messagesUnread: anyMessagesUnread(existingConversations),
	}

	return newState
}

const MESSAGE_TRANSLATED: Reducer = (state, action) => {
	const { id, text } = action.payload

	const newState = { ...state }
	const { groupData } = state
	if (groupData) {
		const conversation = translateMessageInConversation(groupData.conversation, id, text)
		const currentSession = translateMessageInSession(groupData.currentSession, id, text)
		newState.groupData = { ...groupData, conversation, currentSession }
	} else {
		const currentSession = translateMessageInSession(state.currentSession, id, text)
		newState.currentSession = currentSession
	}
	const previousSession = translateMessageInSession(state.previousSession, id, text)
	newState.previousSession = previousSession

	return newState
}

function translateMessageInSession(session, id, text) {
	const conversations = { ...(session || {}).conversations }
	const groupIds = Object.keys(conversations)
	groupIds.forEach(gid => {
		const conversation = translateMessageInConversation(conversations[gid], id, text)
		conversations[gid] = conversation
	})
	const allGroupMessages = translateMessageInConversation((session || {}).allGroupMessages, id, text)
	return { ...session, conversations, allGroupMessages }
}

function translateMessageInConversation(prevConversation, id, text) {
	const conversation = (prevConversation || []).map(obj => ({ ...obj }))
	const msg = conversation.find(m => m.id === id || m.key === id) || {}

	msg.translated = true
	msg.translation = text
	return conversation
}

// =================================================================================================

const reducers = {
	MESSAGE_SENT_TO_GROUP,
	MESSAGE_SENT_TO_ALL_GROUPS,
	MESSAGE_SENT_TO_TUTOR,
	MESSAGE_MARK_AS_READ,
	MESSAGES_GOT_CONVERSATION,
	MESSAGES_GOT_ALL_CONVERSATIONS,
	MESSAGES_FAILED_ALL_CONVERSATIONS,
	MESSAGES_LOADING_ALL_CONVERSATIONS,
	MESSAGES_CLEAR_RECENT_FLAG,
	MESSAGE_TRANSLATED,
}

// =================================================================================================

const switchcase: Reducer = (state, action) => {
	let newState = state
	if (reducers[action.type]) {
		newState = reducers[action.type](state, action)
	}
	return newState
}

export default switchcase
