import React from 'react'
import { connect } from 'react-redux'

import config from '../../config'
import Actions from '../actions'

import loggedInAsObserverSelector from '../selectors/loggedInAsObserver'

type SyncManagerProps = {
	checkForMessagesThatNeedTranslation: () => void
	getAllConversations: () => void
	getCurrentSession: () => void
	getServerStatus: () => void
	getParticipants: () => void
	getServerTime: () => void
	loadGroupList: () => void
	getIpAddress: () => void
	getOpenCase: () => void
	getSettings: () => void
	getReleases: (sessionId: string) => void
	getLicence: () => void
	viewingPreviousSession?: boolean
	currentSession: CurrentSession
	loggedInAsObserver: boolean
}
class SyncManager extends React.Component<SyncManagerProps> {
	intervals: number[]

	constructor(props) {
		super(props)
		this.intervals = []

		// Bind our private methods so that they always have correct context
		this.getConversations = this.getConversations.bind(this)
		this.getIpAddress = this.getIpAddress.bind(this)
		this.getReleases = this.getReleases.bind(this)
		this.getGroups = this.getGroups.bind(this)
	}

	componentDidMount() {
		const {
			checkForMessagesThatNeedTranslation,
			loggedInAsObserver,
			getCurrentSession,
			getServerStatus,
			getParticipants,
			getServerTime,
			getOpenCase,
			getSettings,
			getLicence,
		} = this.props

		// Call all private methods immediately
		this.getConversations()
		this.getIpAddress()
		this.getReleases()
		this.getGroups()

		// Call other methods we've received in props
		getSettings()
		getCurrentSession()
		getParticipants()
		getServerStatus()
		getOpenCase()
		getLicence()
		getServerTime() // This one only gets called once

		// Call all of the methods at regular intervals, depending on how needy we are
		this.intervals = [
			window.setInterval(this.getReleases, loggedInAsObserver ? 3000 : 10000),
			window.setInterval(checkForMessagesThatNeedTranslation, 2000),
			window.setInterval(this.getConversations, 3000),
			window.setInterval(this.getIpAddress, 5000),
			window.setInterval(getCurrentSession, 3000),
			window.setInterval(getParticipants, 3000),
			window.setInterval(getServerStatus, 5000),
			window.setInterval(this.getGroups, 5000),
			window.setInterval(getOpenCase, 3000),
			window.setInterval(getLicence, 10000),
			window.setInterval(getSettings, 6000),
		]
	}

	componentWillUnmount() {
		// Kill all of our interval-calls
		this.intervals.forEach(i => clearInterval(i))
	}

	// =================================================================================================
	// Private methods

	getConversations() {
		const { viewingPreviousSession, getAllConversations } = this.props
		if (!viewingPreviousSession) getAllConversations()
	}

	getGroups() {
		const { viewingPreviousSession, loadGroupList } = this.props
		if (!viewingPreviousSession) loadGroupList()
	}

	getReleases() {
		const { viewingPreviousSession, currentSession, getReleases } = this.props
		if (!viewingPreviousSession && currentSession.id) getReleases(currentSession.id)
	}

	getIpAddress() {
		const { getIpAddress } = this.props
		if (config.DESKTOP) getIpAddress()
	}

	// =================================================================================================

	render() {
		return null
	}
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<SyncManagerProps> => ({
	viewingPreviousSession: state.viewingPreviousSession,
	currentSession: state.currentSession || ({} as CurrentSession),
	loggedInAsObserver: loggedInAsObserverSelector(state),
})
const actions = {
	checkForMessagesThatNeedTranslation: Actions.messages.checkForMessagesThatNeedTranslation,
	getAllConversations: Actions.messages.getAllConversations,
	getCurrentSession: Actions.sessions.getCurrentSession,
	getParticipants: Actions.participants.getParticipants,
	getReleases: Actions.sessions.getReleasesForSession,
	getServerStatus: Actions.videoconf.getServerStatus,
	getSettings: Actions.settings.fetchSettings,
	loadGroupList: Actions.groups.loadGroupList,
	getServerTime: Actions.login.getServerTime,
	getIpAddress: Actions.misc.getIpAddress,
	getOpenCase: Actions.cases.getOpenCase,
	getLicence: Actions.misc.getLicence,
}
export default connect(mapStateToProps, actions)(SyncManager)
