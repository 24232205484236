import React, { FunctionComponent } from 'react'
import parser from 'ua-parser-js'

import convertColour from '../util/convertColour'
import teamNameFromColour from '../util/teamNameFromColour'
import config from '../../config'
import Pill from '../elements/Pill'
import Icon from '../elements/Icon'

const { TEAM_COLOURS } = config

type ParticipantProps = {
	participant: Participant
	allowChange?: boolean
	onChange: React.ChangeEventHandler<HTMLSelectElement>
}

const Participant: FunctionComponent<ParticipantProps> = props => {
	const { participant, onChange, allowChange } = props
	let className = 'participant'
	if (participant.colour) className += ' participant--allocated'

	const colours = [...TEAM_COLOURS]
	colours.sort((a, b) => (a < b ? -1 : 1))
	const options = colours.map(c => (
		<option key={c} value={c}>
			{teamNameFromColour(c)}
		</option>
	))

	const { colour, sourceIp, userAgent, disconnected, connected } = participant
	const { officiallyConnected, officiallyDisconnected } = participant

	const participantId = participant.id
	const { online, phoneNumber } = participant
	const status = online ? 'online' : 'offline'
	const metadataArr = []
	if (sourceIp) metadataArr.push(sourceIp)
	if (userAgent) {
		const ua = parser(userAgent)
		metadataArr.push(`${ua.os.name} ${ua.os.version}`)
		metadataArr.push(`${ua.browser.name} ${ua.browser.version}`)
	}

	let since
	const sinceDate =
		(!online && (officiallyDisconnected || disconnected)) || (online && (officiallyConnected || connected))
	if (sinceDate) {
		const now = new Date()
		const dt = new Date(sinceDate)
		if (now.toDateString() === dt.toDateString()) {
			since = `since ${dt.toLocaleTimeString()}`
		} else {
			since = `since ${dt.toLocaleDateString()}`
		}
	}

	const metadata = metadataArr.join(' - ')

	return (
		<div key={participantId} className={className}>
			<div className="left">
				<h2>{participant.name}</h2>
				<p>
					<Icon name="desktop" />
					{metadata}
				</p>
				{phoneNumber ? (
					<p>
						<Icon name="phone" />
						{phoneNumber}
					</p>
				) : null}
			</div>
			<div className="right">
				<div className="dot" style={{ backgroundColor: convertColour(colour) }} />
				<div className="input">
					<select value={colour} onChange={onChange} disabled={allowChange === false}>
						{options}
					</select>
				</div>
				<div className="col">
					<Pill className={`tutor-videoconf__pill--${status}`}>{status}</Pill>
					<p>{since}</p>
				</div>
			</div>
		</div>
	)
}

export default Participant
