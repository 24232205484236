export default {
	/* Team view */
	OK: 'добро',
	CANCEL: 'Откажи',
	TUTOR: 'Фасилитатор',
	CURRENT_SESSION: 'Тековна сесија',
	TEAM: 'Тим',
	SIGNOUT: 'Одјавете се',
	INITIAL_UPDATE: 'Информации за сесијата',
	ADVICE: 'Одлука',
	RATIONALE: 'Образложение ',
	MEDIA_FEED: 'Медиумски извор',
	MESSAGES: 'Пораки',
	PREVSESSIONS: 'Претходни сесии',
	RETURN_TO_CURRENT: 'Враќање на претходната сесија',
	SESSION_IS_PAUSED: 'Сесијата е ПАУЗИРАНА',
	ATTRIB_CURR_AUTHOR: 'Одлучувач',
	SAVE: 'Зачувај',
	CHANGE: 'Измени',
	NO_AUTHOR: 'Не е поставено',
	NO_INITIAL_INFO: 'Ве молиме почекајте да започне наредната сесија',
	DECISION_MAKER_SELECT: 'Ве молиме изберете одлучувач',
	SESSION_COMPLETE: 'Претходната сесија е завршена',
	OPENED_AT: 'Отворено во ',
	SEND_MESSAGE_PLACEHOLDER: 'Напишете порака',
	SEND: 'Испратете',
	TO_ALL_GROUPS: 'До сите групи',
	SHOW_TRANSLATION: 'Прикажи превод',
	SHOW_ORIGINAL: 'Прикажи оригинал',
	CREDENTIALS_EXPIRED: 'Вашата лозинка е истечена. Ве молиме најавете се повторно',
	INCOMING_MESSAGE: 'Дојдовна порака',
	INCOMING_MESSAGE_TEXT: 'Пристигната е нова порака',
	INCOMING_MEDIA: 'Дојдовна медиумска ставка',
	INCOMING_MEDIA_TEXT: 'Примена е нова медиумска ставка',
	INCOMING_UPDATE: 'Дојдовно ажурирање',
	INCOMING_UPDATE_TEXT: 'Ажурирањето е примено',
	CONFIRM_LOGOUT: 'Дали сте сигурни дека сакате да заминете?',
	MUTED_MESSAGE: 'Вашиот микрофон е исклучен',
	NEWSTYPE_UPDATE: 'Ажурирањето е примено',
	WARNING_TIME_MSG: 'Остануваат уште 5 минути',
}
