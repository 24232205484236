import React from 'react'
import { connect } from 'react-redux'

import config from '../../config'
import Actions from '../actions'

import sessionSelector from '../selectors/session'
import loggedInAsParticipantSelector from '../selectors/loggedInAsParticipant'

const { GROUP_FETCH_FREQUENCY } = config

type SyncManagerGroupProps = {
	getOpenings: (sessionId: string, groupId: string) => void
	getReleasesForSession: (sessionId: string) => void
	getConversation: () => void
	fetchDecisionLog: () => void
	getServerStatus: () => void
	getParticipants: () => void
	fetchGroupData: () => void
	getServerTime: () => void
	fetchSettings: () => void
	session: CurrentSession | PreviousSession
	loggedInAsParticipant: boolean
	groupId: string
}

class SyncManagerGroup extends React.Component<SyncManagerGroupProps> {
	fetchInterval: number

	componentDidMount() {
		console.log('SyncManagerGroup - componentDidMount')
		// Fetch data on a regular basis
		this._fetchData()
		this.fetchInterval = window.setInterval(() => this._fetchData(), GROUP_FETCH_FREQUENCY)
	}

	componentWillUnmount() {
		// Kill all of our interval-calls
		window.clearInterval(this.fetchInterval)
	}

	_fetchData() {
		const { fetchGroupData, getConversation, getParticipants, fetchSettings } = this.props
		const { getServerStatus, fetchDecisionLog, getReleasesForSession, getOpenings } = this.props
		const { session, loggedInAsParticipant, groupId } = this.props

		fetchSettings()
		fetchGroupData()
		getServerStatus()
		getConversation()
		if (session) getReleasesForSession(session.id)
		if (session) getOpenings(session.id, groupId)
		if (loggedInAsParticipant) getParticipants()
		if (loggedInAsParticipant) fetchDecisionLog()
	}

	render() {
		return null
	}
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<SyncManagerGroupProps> => {
	const groupId = state.group.id
	const session = sessionSelector(state)
	const loggedInAsParticipant = loggedInAsParticipantSelector(state)

	return { loggedInAsParticipant, session, groupId }
}
// Map actions to component properties by just passing an object of those actions to connect()
const actions: Partial<SyncManagerGroupProps> = {
	getServerTime: Actions.login.getServerTime,
	fetchGroupData: Actions.groups.fetchGroupData,
	fetchSettings: Actions.settings.fetchSettings,
	fetchDecisionLog: Actions.groups.fetchDecisionLog,
	getOpenings: Actions.sessions.getOpeningsForGroup,
	getConversation: Actions.messages.getConversation,
	getServerStatus: Actions.videoconf.getServerStatus,
	getParticipants: Actions.participants.getParticipants,
	getReleasesForSession: Actions.sessions.getReleasesForSession,
}
export default connect(mapStateToProps, actions)(SyncManagerGroup)
