/* eslint-disable no-return-assign */
import React from 'react'
import { connect } from 'react-redux'

import sessionSelector from '../selectors/session'

import MessageEntry from './MessageEntry'
import MessageList from './MessageList'

import config from '../../config'

type MessagesPanelProps = {
	toTutor?: boolean
	data?: Message[]
	groupId?: string
	viewingPreviousSession?: boolean
	session?: Session
	alert?: boolean
}

type MessagePanelState = {
	pendingMessages: Message[]
}

class MessagesPanel extends React.Component<MessagesPanelProps, MessagePanelState> {
	private messageCount = 0
	private elem: HTMLDivElement

	constructor(props) {
		super(props)
		this.onSendMessage = this.onSendMessage.bind(this)
		this.state = { pendingMessages: [] }
		this.messageCount = 0
	}

	// Clear out any pending messages from state if they are in the list we are given via props.
	static getDerivedStateFromProps(newProps, state) {
		const msgs = newProps.data || []
		const pendingMessages = state.pendingMessages.filter(pm => !msgs.find(m => m.id === pm.id))
		// Update state if number of messages has changed
		if (pendingMessages.length !== state.pendingMessages.length) {
			return { ...state, pendingMessages }
		}
		return state
	}

	componentDidMount() {
		this.componentDidUpdate()
	}

	componentDidUpdate() {
		// Check if the total number of messages to display has changed
		// eslint-disable-next-line react/destructuring-assignment
		const messageCount = (this.state.pendingMessages || []).length + (this.props.data || []).length
		if (this.messageCount === messageCount) return
		this.messageCount = messageCount

		// Scroll to bottom of messages list
		Array.prototype.forEach.call(this.elem.getElementsByClassName('message-list'), elem => {
			// eslint-disable-next-line no-param-reassign
			elem.scrollTop = elem.scrollHeight
		})
	}

	onSendMessage(msg) {
		const { toTutor } = this.props
		const { pendingMessages } = this.state

		if (toTutor) {
			// Add message to pending list
			const newMessage = { ...msg, timestamp: new Date().getTime() }
			this.setState({ pendingMessages: [...pendingMessages, newMessage] })
		}
	}

	getMessagesToRender() {
		const { data, groupId, viewingPreviousSession, session } = this.props
		let msgsToRender = []
		// If we don't have a group ID, something has gone wrong, return an empty list
		if (!groupId) {
			return []
		}

		// Only display messages that belong to the current session
		let rcvdMsgs = data || []
		rcvdMsgs = rcvdMsgs.filter(msg => msg.currSessionId === session.id)

		// Get pending messages
		let { pendingMessages } = this.state
		// If viewing a previous session, don't include pending messages
		if (viewingPreviousSession) pendingMessages = []

		// Combine all messages
		msgsToRender = rcvdMsgs.concat(pendingMessages)

		// Sort by timestamp
		msgsToRender.sort((a, b) => a.timestamp - b.timestamp)

		return msgsToRender
	}

	render() {
		const { props } = this
		const { toTutor, groupId, viewingPreviousSession, session, alert } = props
		const { SEND_MESSAGE_PLACEHOLDER, SEND_TO_ALL_PLACEHOLDER } = config.strings

		const msgs = this.getMessagesToRender()

		const showMessageEntry = groupId && !viewingPreviousSession && session.id

		let placeholder = SEND_MESSAGE_PLACEHOLDER
		let showWhoMessageIsTo = false
		if (groupId === 'all-groups') {
			placeholder = SEND_TO_ALL_PLACEHOLDER
			showWhoMessageIsTo = true
		}

		const messageEntry = (
			<MessageEntry toTutor={toTutor} groupId={groupId} onSendMessage={this.onSendMessage} placeholder={placeholder} />
		)

		let className = 'messages-panel'
		if (alert) className += ' messages-panel--alert'

		return (
			<div className={className} ref={ref => (this.elem = ref)}>
				<div className="messages-panel__message-list-container">
					<MessageList showWhoMessageIsTo={showWhoMessageIsTo} data={msgs} />
				</div>
				{showMessageEntry && messageEntry}
			</div>
		)
	}
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = (state: StateTree): Partial<MessagesPanelProps> => ({
	viewingPreviousSession: state.viewingPreviousSession,
	session: sessionSelector(state),
})
const actions = {}

// Create a type "OwnProps" which only includes props that are not from Redux state/actions
type PropsFromState = ReturnType<typeof mapStateToProps>
type ReduxActions = typeof actions
type OwnProps = Pick<MessagesPanelProps, 'data' | 'groupId'>

export default connect<PropsFromState, ReduxActions, OwnProps>(mapStateToProps, actions)(MessagesPanel)
