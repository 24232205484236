import React, { FunctionComponent } from 'react'

import LoginButton from '../elements/LoginButton'
import config from '../../config'

const { strings } = config

type LoginMainProps = {
	onClickGroup: () => void
	onClickTutor: () => void
	onClickLinkedAccess: () => void
	onClickParticipant: () => void
}

const LoginMain: FunctionComponent<LoginMainProps> = props => {
	const { onClickGroup, onClickTutor, onClickLinkedAccess, onClickParticipant } = props
	const { TEAM, TUTOR, SUPERVISOR, PARTICIPANT } = strings

	return (
		<div className="login__main">
			<div className="login__buttons">
				<LoginButton iconName="user" text={PARTICIPANT} onClick={onClickParticipant} />
				<LoginButton iconName="users" text={TEAM} onClick={onClickGroup} />
				<LoginButton iconName="chalkboard-teacher" text={TUTOR} onClick={onClickTutor} />
				<LoginButton onClick={onClickLinkedAccess} iconName="user-plus" text={SUPERVISOR} />
			</div>
		</div>
	)
}

export default LoginMain
