/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import produce from 'immer'

const equal = (val1, val2) => String(val1) === String(val2)

// =================================================================================================

const SESSIONS_ADD_NEW_SUCCESS: Reducer = (state, action) => {
	const openCase = { ...state.openCase }
	openCase.sessions = [...(openCase.sessions || []), action.payload as Session]
	return { ...state, openCase }
}

const SESSIONS_START: Reducer = (state, action) => {
	const { sessionId, startTime, id } = action.payload as { sessionId: string; startTime: number; id: string }
	const existingSession = state.openCase.sessions.find(s => s.id === sessionId)
	const session: CurrentSession = { ...existingSession, startTime, id, sessionId }
	if (session.schedule) session.schedule.forEach(s => (s.released = null))
	session.caseId = state.openCase.id
	return { ...state, currentSession: session, releases: [] }
}

const SESSIONS_END: Reducer = (state, action) => ({
	...state,
	currentSession: {
		...state.currentSession,
		endTime: new Date().getTime(),
		paused: false,
	},
})

const SESSIONS_PAUSE: Reducer = (state, action) =>
	produce(state, newState => {
		const now = new Date().getTime()
		newState.currentSession.pauses = newState.currentSession.pauses || []
		newState.currentSession.pauses.push({ start: now })
		newState.currentSession.paused = true
		return newState
	})

const SESSIONS_DELETE: Reducer = (state, action) => {
	const sessions = [...state.openCase.sessions]
	const sessionId = action.payload.timestamp
	const sessionIndex = sessions.findIndex(s => equal(s.timestamp, sessionId))
	sessions.splice(sessionIndex, 1)
	// Replace this case in the cases array
	return { ...state, openCase: { ...state.openCase, sessions } }
}

const SESSIONS_UPDATE_GROUP_LOG: Reducer = (state, action) => {
	// If group of colour does not already exist, add them
	let currentSession = state.currentSession ? { ...state.currentSession } : {}
	const groups = currentSession.groups
		? [...currentSession.groups.map(g => ({ ...g }))]
		: [{ colour: action.payload.colour }]
	let group = groups.find(g => g.colour === action.payload.colour)
	if (!group) {
		group = { colour: action.payload.colour }
		groups.push(group)
	}

	group.advice = action.payload.advice
	group.rationale = action.payload.rationale
	currentSession = { ...currentSession, groups: [...groups] }
	return { ...state, currentSession }
}

const SESSIONS_PREV_RETRIEVED: Reducer = (state, action) => {
	const sessions = action.payload
	return { ...state, prevSessions: sessions, loadingPreviousSessions: false }
}
const SESSIONS_PREV_LOADING_LIST: Reducer = state => ({ ...state, loadingPreviousSessions: true })

const SESSIONS_GOT_CURRENT_SESSION: Reducer = (state, action) => {
	const oldCurrentSession = state.currentSession || ({} as CurrentSession)
	let releases = state.releases || []
	const newCurrentSession = action.payload.currentSession || {}

	// Ensure we don't replace current list of groups, conversations or releases to groups
	if (newCurrentSession.id === oldCurrentSession.id) {
		newCurrentSession.groups = oldCurrentSession.groups || []
		newCurrentSession.conversations = oldCurrentSession.conversations || []
	} else {
		releases = []
	}

	return { ...state, currentSession: newCurrentSession, releases }
}

const SESSION_CURR_AUTHOR_CHANGED: Reducer = (state, action) => {
	const { name, id } = action.payload
	return produce(state, newState => {
		newState.group.currAuthor = name
		newState.group.currAuthorId = id
		newState.groupData.currentSession.currAuthor = name
		newState.groupData.currentSession.currAuthorId = id
		return newState
	})
}

const SESSIONS_GOT_RELEASES: Reducer = (state, action) => {
	const { sessionId } = action.payload
	const releases = action.payload.data || []
	const { previousSession } = state
	// Check if session is a previous one
	if (previousSession && previousSession.id === sessionId) {
		return { ...state, previousSession: { ...previousSession, releases } }
	}
	return { ...state, releases }
}

const SESSIONS_GOT_OPENINGS: Reducer = (state, action) => ({ ...state, openings: action.payload.data || [] })

const SESSIONS_PREV_DELETED: Reducer = (state, action) => {
	const deletedId = action.payload
	const prevSessions = state.prevSessions || []
	const newPrevSessions = [...prevSessions].filter(s => s.id !== deletedId)
	return { ...state, prevSessions: newPrevSessions }
}
const SESSIONS_PREV_LOADING: Reducer = state => ({ ...state, loadingPreviousSession: true })

const SESSIONS_PREV_LOADED: Reducer = (state, action) => {
	const previousSession = action.payload
	return {
		...state,
		previousSession,
		viewingPreviousSession: true,
		loadingPreviousSession: false,
	}
}

const SESSIONS_PREV_LOADING_LIST_FAILED: Reducer = state => ({ ...state, loadingPreviousSessions: false })

const SESSIONS_RETURN_TO_CURRENT: Reducer = state => ({
	...state,
	previousSession: null,
	viewingPreviousSession: false,
})

const SESSIONS_ADD_RELEASE_ROW_SUCCESS: Reducer = (state, action) =>
	produce(state, newState => {
		const { sessionId, row } = action.payload
		const session = newState.openCase.sessions.find(s => s.id === sessionId)
		if (session) {
			session.schedule = session.schedule || []
			session.schedule.push(row)
		}
		// Add row to current session if necessary
		const currentSession = newState.currentSession || {}
		if (currentSession.sessionId === sessionId) {
			currentSession.schedule = currentSession.schedule || []
			currentSession.schedule.push(row)
		}
		return newState
	})

const SESSIONS_RESUME: Reducer = state => {
	const pauses = state.currentSession.pauses.map(p => ({ ...p }))
	pauses[pauses.length - 1].end = new Date().getTime()
	return { ...state, currentSession: { ...state.currentSession, pauses, paused: false } }
}

const SESSIONS_REMOVE_RELEASE_ROW: Reducer = (state, action) =>
	produce(state, newState => {
		const { sessionId, rowId } = action.payload
		const sessions = newState?.openCase?.sessions || []
		const session = sessions.find(s => s.id === sessionId) || {}
		const schedule = session.schedule || []
		session.schedule = schedule.filter(r => r.id !== rowId)
		return newState
	})

const SESSIONS_UPDATE_SUCCESS: Reducer = (state, action) => {
	// Find the session in the current open case
	const sessions = [...(state.openCase || {}).sessions]
	const index = sessions.findIndex(s => String(s.timestamp) === String(action.payload.timestamp))
	const sess = sessions[index]
	sessions[index] = { ...sess, ...action.payload }

	// Update details in current session
	let currentSession = { ...state.currentSession }
	const currId = state.currentSession.id
	if (state.currentSession && currId === sess.id) {
		currentSession = {
			...state.currentSession,
			...sessions[index],
			id: currId,
			timestamp: parseInt(currId) || 0,
		}
	}

	// Update open case
	const openCase = { ...state.openCase, sessions: sessions.map(s => ({ ...s })) }

	return { ...state, openCase, currentSession }
}

const SESSION_RELEASE_TO_GROUP: Reducer = (state, action) => {
	const { rowId } = action.payload
	const { groupId } = action.payload
	const { timestamp } = action.payload
	const currentSession = { ...state.currentSession }
	const sessionId = currentSession.id

	const releases = [...(state.releases || []), { sessionId, rowId, groupId, timestamp }]
	return { ...state, releases }
}

const SESSION_RELEASE_NOW: Reducer = (state, action) => {
	if (!action.payload.rowId) return

	const currentSession = state.currentSession || ({} as CurrentSession)
	const schedule = currentSession.schedule || []
	const row = schedule.find(r => String(r.timestamp) === String(action.payload.rowId))
	if (!row) return state

	// Add release to 'releases' array
	const sessionId = currentSession.id
	const rowId = row.id as string
	const timestamp = action.payload.timeNow
	const release = { sessionId, rowId, timestamp, groupId: 'ALL' }
	const releases = [...(state.releases || []), release]
	const newState = { ...state, currentSession, releases }
	return newState
}

const SESSION_SELECT: Reducer = (state, action) => ({ ...state, selectedSession: action.payload })

const PHONE_MESSAGE_RESENT: Reducer = (state, action) => {
	const { scheduleRowId } = action.payload as { scheduleRowId: string }
	const releases = [...(state.releases || []).map(r => ({ ...r }))]
	const release = releases.find(r => r.rowId === scheduleRowId)
	if (!release) return state
	release.phonelog = { ...(release.phonelog || {}) } as PhoneLog
	release.phonelog.status = 'queued'
	return { ...state, releases }
}
// =================================================================================================

const reducers = {
	SESSIONS_ADD_NEW_SUCCESS,
	SESSIONS_DELETE,
	SESSIONS_UPDATE_SUCCESS,
	SESSIONS_UPDATE_GROUP_LOG,
	SESSIONS_START,
	SESSIONS_ADD_RELEASE_ROW_SUCCESS,
	SESSIONS_REMOVE_RELEASE_ROW,
	SESSION_RELEASE_NOW,
	SESSIONS_END,
	SESSIONS_PAUSE,
	SESSIONS_RESUME,
	SESSIONS_PREV_RETRIEVED,
	SESSIONS_PREV_LOADING_LIST,
	SESSIONS_PREV_LOADING_LIST_FAILED,
	SESSIONS_PREV_LOADING,
	SESSIONS_PREV_LOADED,
	SESSIONS_RETURN_TO_CURRENT,
	SESSION_RELEASE_TO_GROUP,
	SESSIONS_GOT_CURRENT_SESSION,
	SESSIONS_PREV_DELETED,
	SESSION_CURR_AUTHOR_CHANGED,
	SESSIONS_GOT_OPENINGS,
	SESSIONS_GOT_RELEASES,
	SESSION_SELECT,
	PHONE_MESSAGE_RESENT,
}

// =================================================================================================

const switchcase: Reducer = (state, action) => {
	let newState = state
	if (reducers[action.type]) {
		newState = reducers[action.type](state, action)
	}
	return newState
}

export default switchcase
