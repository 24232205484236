import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

import JitsiClient, { JitsiClientEvents } from '../util/JitsiClient'

import MessageEntryElem from '../elements/MessageEntryElem'
import BubbleHeading from '../elements/BubbleHeading'
import ChatMessage from './ChatMessage'
import Actions from '../actions'

type ChatProps = {
	client: JitsiClient
	checkChatMessagesForTranslation?: typeof Actions.videoconf.checkChatMessagesForTranslation
}

const Chat: FunctionComponent<ChatProps> = props => {
	const { client, checkChatMessagesForTranslation } = props

	const [chat, setChat] = useState<JitsiChatMessage[]>([])
	const scroller = useRef<HTMLDivElement>()
	const [selected, setSelected] = useState<string>('')

	// Add event handlers to JitsiClient on mount
	useEffect(() => {
		if (!client) return
		setChat([...(client.chat || [])])
		const eventRef = client.on(JitsiClientEvents.MESSAGE_RECEIVED, () => {
			setChat([...(client.chat || [])])
		})
		// On dismount, remove event handlers
		return () => client.off(eventRef)
	}, [client])

	// Side-effects for when the chat is updated
	useEffect(() => {
		// Scroll to bottom of scroller
		scroller.current.scrollTop = scroller.current.scrollHeight
		console.log('chat', chat)

		checkChatMessagesForTranslation(chat, client.remoteParticipants)
	}, [chat])

	const chatElems = chat.map(msg => <ChatMessage client={client} message={msg} key={msg.timestamp} />)

	return (
		<div className="chat">
			<BubbleHeading>Chat</BubbleHeading>
			<div ref={scroller} className="chat__scroller">
				{chatElems}
			</div>
			<div className="chat__recipient-row">
				<label>To:</label>
				<div className="input">
					<select onChange={e => setSelected(e.target.value)} value={selected}>
						<option value="">Everyone</option>
						{client.remoteParticipants.map(p => (
							<option key={p.participantId} value={p.participantId}>
								{p.displayName}
							</option>
						))}
					</select>
				</div>
			</div>
			<MessageEntryElem placeholder="Message" onSubmit={message => client.sendChatMessage(message, selected)} />
		</div>
	)
}

// =================================================================================================
// Redux wiring
// =================================================================================================
const mapStateToProps = () => ({})
const actions = {
	checkChatMessagesForTranslation: Actions.videoconf.checkChatMessagesForTranslation,
}
export default connect(mapStateToProps, actions)(Chat)
